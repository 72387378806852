
define('apputils',['jquery'],function($) {
	'use strict';
	var app = {
			libs: {},
			utils: {},
			plugins: {},
			myplugins: {},
			_requires: [],
			controllers: {},
			models: {},
			views: {},
			cache: {},
			data: {}
		};
	
		app.session = {};
		app.session.srp = {
			'currentView': 'list',
			'currentSearchParameters':{
				'minPriceValue':'',
				'maxPriceValue':'',
				'minBedroomCount':'',
				'minBathroomCount':'',
				'minSqFootage':'',
				'maxSqFootage':'',
				'minYearBuilt':'',
				'maxYearBuilt':'',
				'listingType':'',
				'category':'',
				'propertyStatus':'',
				'propertySubType':'',
				'occupancyStatus':'',
				'offers':'',
				'searchBy':'',
				'sortOrder':'asc',
				'sortBy':'',
				'state':'',
				'county':'',	
				'pageNumber':1,
				'perPage':16,
				'searchParams':'',
				'maxPropertySize':'',
				'minPropertySize':'',
				'minListPrice':'',
				'maxListPrice':'',
				'boundsSearchFlag':'',
				'viewType':'',
				'city':'',
				'zip':'',
				'radiusSortRequired' : false,
				'centerPoint' : '',
				'campaignName' : ''
			},
			'ajaxRequest':false,
			'changeView': false,
			'searchTimer': false,
			'searchTimerDuration':2000,
			'doSearch': false,
			'dymRem' : false,
			'isDYM' : false,
			'refinementsSet': false
		};
		app.session._write = function(name, value, type){
			if(!_.isArray(name)){
				name = name.split('.');
		}
		var currentData = app.session;
		_.each(name, function(key, i){
			if(i === name.length - 1){
				if(type == 'write'){
					currentData[key] = value;
				}if(type == 'update'){
					currentData[key] = _.extend(currentData[key], value);
				}
			}else{
				if(_.isUndefined(currentData[key])){
					currentData[key] = {};
				}
				currentData = currentData[key];
			}
		});
		return app.session;
	};
	app.session.write = function(name, value){
		return app.session._write(name, value, 'write');
	};
	
	app.session.update = function(name, value){
		return app.session._write(name, value, 'update');
	};
	
	app.utils.truncate=function(str,limit){
		if(str.length>limit)
		{
			return str.substr(0,limit)+"...";
		}else{return str}
	}
	
	app.utils.buildMyHubzuLink = function(linkObj) { 
		var str = '', disabled = '';
		var activemenu ='',menuClassName = '';
		for(var each in linkObj) {
			if(linkObj[each].text!=undefined && linkObj[each].text!=''){
				disabled = '';
				var className = linkObj[each].link.split('?');
				className = className[0].split('/');

				if(className.length>1)
				{
					className = className[className.length-1];
					if(className.indexOf("#") > -1 ) {
						className = className.split("#")[0];
					}
				}
				else
				{
					className = '';
				}
				if(linkObj[each].menuClass!=undefined && linkObj[each].menuClass!=''){
					menuClassName = linkObj[each].menuClass;
				}else{
					menuClassName = '';
				}
				if(linkObj[each].enableFlag != "N"){
					if(linkObj[each].disabled) {
						disabled = ' class="disabled ' + className + '"';
						str += '<li' + disabled + '><a href="'+ linkObj[each].link +'" class="textFade">';
					} else {
							var splitArrVar = (linkObj[each].link).split('#');			
							if(typeof window.location.hash != 'undefined' && ($.trim((window.location.hash).replace('#','')) == splitArrVar[1])){
								className +=' menuActive';
							}
							str += '<li class="' + className + ' '+menuClassName+'"><a href="javascript:void(0)" titleText ="'+linkObj[each].text+'" hrefLink="'+ linkObj[each].link +'" class="textFade headerMenuClick" hashName="'+splitArrVar[1]+'">';
					}
					str += app.utils.shrinkText(linkObj[each].text, 26,'div');
					if(linkObj[each].subText) {
					str += app.utils.shrinkText(linkObj[each].subText, 26, 'div', null, 'font-size:10px;');
					}
					str +='</a></li>';
					str += '<li class="divider divider-' + className + ' '+menuClassName+ '"></li>';
				}
			}
		}
		
		str += '<li class="sign-out-btn-li"><a href="#" id="sign-out-btn" class="textFade">Sign Out</a></li>';
		return str;
	};
	
	app.utils.shrinkText = function(text,length,enclosingTag,styleClass,customStyle) {
		var str = '';
		if(enclosingTag) {
			str += '<' + enclosingTag;
			if(styleClass) {
				str += ' class="' + styleClass + '"';
			}
			if(text.length > length) {
				str += ' title="' + text + '"';
			}
			if(customStyle) {
				str += ' style="' + customStyle + '"';
			}
			str += '>';
		}
		if(text.length > length) {
			str += text.substring(0,length) + '...';
		} else {
			str += text;
		}
		if(enclosingTag) {
			str += '</' + enclosingTag + '>';
		}
		return str;
	};
	
	app.utils.param = function(name){
		name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
		var regexS = "[\\?&]"+name+"=([^&#]*)";
		var regex = new RegExp(regexS);
		var results = regex.exec(window.location.href);
		if(results == null){
			return false;
		}else{
			return results[1];
		}
	};
	
	app.utils.getUrlVars=function(name,url){
	    var vars = [], hash;
	    var hashes = url.slice(url.indexOf('?') + 1).split('&');
	    for(var i = 0; i < hashes.length; i++)
	    {
	        hash = hashes[i].split('=');
	        if(hash[0]==name){
	        	vars.push(hash[1]);
	        }
	        
	    }
	    return JSON.stringify(vars);
	};
	app.utils.getUrlVarsLabelsBedBath=function(name,url){
	    var vars = [], hash;
	    var hashes = url.slice(url.indexOf('?') + 1).split('&');
	    var pushValue;
	    for(var i = 0; i < hashes.length; i++)
	    {
	        hash = hashes[i].split('=');
	        if(hash[0]==name){
	        	pushValue = ($("a[value='"+hashes[i]+"']:first").parent().text());
	        	vars.push(pushValue);
	        }
	        
	    }
	    return vars.join(',');
	};
	app.utils.getQueryStringValue=function(key) {
		return encodeURI(unescape(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")));
	};
	app.utils.getUrlVarsLabels=function(name,url){
		var vars = [], hash, 
			hashes = url.slice(url.indexOf('?') + 1).split('&'),
			pushValue,
			isNewFiltersEnabled = (typeof enableNewFilters !== 'undefined' && enableNewFilters === 'YES' ) ? 'Y' : 'N' ;

		if (isNewFiltersEnabled === 'Y' ) {
			hash = app.utils.getQueryStringValue(name);
			vars = (hash) ? $('[id="' + name + "_" + hash + '"]').attr('filter-name') : ''; 
		} else {
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				if(hash[0]==name){
					pushValue = $.trim($(":checkbox[value='"+hashes[i]+"']:first").parent().text());
					vars.push(pushValue);
				}	
			}
			vars = vars.join(',');
		}
	    return vars;
	};
	app.utils.updateCSP = function(url){
		app.session.srp.currentSearchParameters = SearchJson;
		
		if(app.utils.param('priceRange' , url)){
			var priceArray=app.utils.param('priceRange' , url).split('-');
			app.session.srp.currentSearchParameters.minPriceValue  = decodeURIComponent(priceArray[0]);
			app.session.srp.currentSearchParameters.maxPriceValue  = decodeURIComponent(priceArray[1]);
		}
				
		if(app.utils.param('bed' , url)){
			app.session.srp.currentSearchParameters.minBedroomCount  = app.utils.getUrlVarsLabelsBedBath('bed' , url);
		}
		
		if(app.utils.param('bath' , url)){
			app.session.srp.currentSearchParameters.minBathroomCount  = app.utils.getUrlVarsLabelsBedBath('bath' , url);
		}
		
		if(app.utils.param('squareFeetRange' , url)){
			var squareFeetRangeArray=app.utils.param('squareFeetRange' , url).split('-');
			app.session.srp.currentSearchParameters.minSqFootage  = decodeURIComponent(squareFeetRangeArray[0]);
			app.session.srp.currentSearchParameters.maxSqFootage  = decodeURIComponent(squareFeetRangeArray[1]);
		}
		if(app.utils.param('yearBuiltRange' , url)){
			var yearBuiltRangeArray=app.utils.param('yearBuiltRange' , url).split('-');
			app.session.srp.currentSearchParameters.minYearBuilt  = decodeURIComponent(yearBuiltRangeArray[0]);
			app.session.srp.currentSearchParameters.maxYearBuilt  = decodeURIComponent(yearBuiltRangeArray[1]);
		}
			
		if(app.utils.param('listingType' , url)){
			app.session.srp.currentSearchParameters.listingType = app.utils.getUrlVarsLabels('listingType' , url);
		}

		if(app.utils.param('propertyStatus' , url)){
			app.session.srp.currentSearchParameters.propertyStatus = app.utils.getUrlVarsLabels('propertyStatus' , url);
		}
		if(app.utils.getUrlVars('propertySubType' , url)){
			app.session.srp.currentSearchParameters.propertySubType = app.utils.getUrlVarsLabels('propertySubType' , url);
		}	
		if(app.utils.param('propertyShowMoreStatus' , url)){
			app.session.srp.currentSearchParameters.propertyShowMoreStatus = app.utils.getUrlVarsLabels('propertyShowMoreStatus' , url);
		}
		if(app.utils.param('pageNumber' , url)){
			app.session.srp.currentSearchParameters.pageNumber = app.utils.param('pageNumber' , url);
		}
		if(app.utils.param('perPage' , url)){
			app.session.srp.currentSearchParameters.perPage = app.utils.param('perPage' , url);
		}
		
		if(app.utils.param('sortOrder' , url)){
			app.session.srp.currentSearchParameters.sortOrder = app.utils.param('sortOrder' , url);
		}
		if(app.utils.param('sort' , url)){
			var sortBy=decodeURIComponent(app.utils.param('sort' , url)).split(' ');
			app.session.srp.currentSearchParameters.sortBy = sortBy[0];
			app.session.srp.currentSearchParameters.sortOrder = sortBy[1];
		}
		
		if(app.utils.param('category' , url)){
			app.session.srp.currentSearchParameters.category = app.utils.getUrlVarsLabels('category' , url);
		}else if(app.utils.param('eventId' , url)){
			app.session.srp.currentSearchParameters.category = 'foreclosure';
		}
		
		if(app.utils.param('offers' , url)){
			app.session.srp.currentSearchParameters.offers = app.utils.param('offers' , url);
		}
		
		if(app.utils.param('occupancyStatus' , url)){
			app.session.srp.currentSearchParameters.occupancyStatus = app.utils.getUrlVarsLabels('occupancyStatus' , url);
		}
		
		if(app.utils.param('searchBy' , url)){
			app.session.srp.currentSearchParameters.searchBy = app.utils.param('searchBy' , url);
		}else{
			app.session.srp.currentSearchParameters.searchBy = '';
		}
		
		
		return(app.session.srp.currentSearchParameters);
		
	};
	return app;
});