
define('commonutils',['jquery', 'underscore', 'apputils', 'jqueryInewsTicker', 'GoogleAnalyticsUtils', 'jqueryCookie', 'cryptoJs', 'forgeCryptoJs'], function ($, _, app, jqueryInewsTicker, GAUtils, jqueryCookie, cryptoJs, forge) {
	'use strict';

	var Utils = {};
	var isAuto = false;
	var oldVal = '';
	var scanStatus;
	var isMobile = ($(window).width() < 768) ? true : false;
	var emailAlertFlag = true;
	var openRegisterPopupInterval;

	Utils.requestServer = function (url, parameters, callback, isAsync) {

	};

	Utils.updateAvailableFunds = function (options) {
		try {
			options = options || {};
			var userId = options.userId || '',
				source = options.source || "BuyerDashboard",
				URL = '/fc-service/api/accounts/balance/';

			if (!userId) return;

			URL += userId;

			$.ajax({
				beforeSend: function (xhr) {
					xhr.setRequestHeader("Authorization", "Bearer " + $.cookie('_fcat'));
				},
				type: "GET",
				url: URL,
				dataType: 'json',
				success: function (response) {
					response = response || {};
					if (response.status === "SUCCESS") {
						var data = response.data || {},
							formattedAmount = Number(data.totalDeposit || 0),
							formattedAvailableAmount = Number(data.availableBalance || 0);
						$(".total-funds").html("$" + formattedAmount.formatMoney());
						$(".total-available-funds").html("$" + formattedAvailableAmount.formatMoney()).attr("data-availableBalance", formattedAvailableAmount);
					} else {
						GAUtils.eventTracking.trackevent("Error", source, "file:commonutils.js|function:updateAvailableFunds|API error:" + response.status, null, 1);
					}
				},
				error: function (xhr, status, error) {
					GAUtils.eventTracking.trackevent("Error", source, "file:commonutils.js|function:updateAvailableFunds|Ajax error:" + error, null, 1);
				}
			});
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", source, "file:commonutils.js|function:updateAvailableFunds|" + error, null, 1);
		}
	}
	Utils.getLoggedinUser = function () {

		var user = window.userSession && window.userSession.user || {};
		var uEmail = user.userEmailId ? Utils.decryptSensitiveUserData(user.userEmailId, "email") : "";
		var fname = user.userFirstName ? Utils.decryptSensitiveUserData(user.userFirstName, "fname") : "";
		var lname = user.userLastName ? Utils.decryptSensitiveUserData(user.userLastName, "lname") : "";
		var phone = user.primaryPhone ? Utils.decryptSensitiveUserData(user.primaryPhone, "phone") : "";
		var add1 = user.address1 ? Utils.decryptSensitiveUserData(user.address1, "add1") : "";
		var add2 = user.address2 ? Utils.decryptSensitiveUserData(user.address2, "add2") : "";
		var uCity = user.city ? Utils.decryptSensitiveUserData(user.city, "city") : "";
		var uState = user.state ? Utils.decryptSensitiveUserData(user.state, "state") : "";
		var uZip = user.zip ? Utils.decryptSensitiveUserData(user.zip, "zip") : "";


		return {
			firstName: fname,
			lastName: lname,
			phone: phone,
			email: uEmail,
			address: (add1 || '') + (add2 || ''),
			city: (uCity || ''),
			state: (uState || ''),
			zip: (uZip || '')
		};
	}
	Utils.loadScript = function (options) {
		try {
			options = options || {};
			var source = options.source,
				type = options.type || 'text/javascript',
				callback = options.callback || null,
				charset = options.charset || null,
				script;

			// Check if script is already loaded 
			if ($('script[src="' + source + '"]').length)
				return callback();

			script = document.createElement('script');
			script.type = type;
			script.src = source;
			if (charset) script.charset = charset;

			// Append to DOM
			document.body.appendChild(script);

			// IE
			if (script.readyState) {
				script.onreadystatechange = function () {
					if (script.readyState === 'loaded') {
						if (callback) callback();
					}
				}
			} else {
				script.onload = function () {
					if (callback) callback();
				}
			}

			script.onerror = function () {
				if (callback) callback('Failed to load resource' + source);
				// TODO: Log GA
			}

		} catch (exception) {
			// TODO: log ga
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:loadScript|" + exception, null, 1);
		}
	};

	Utils.isGlobalAlertsEnabled = function (options) {
		options = options || {};
		var featureLevel = options.level || 'global',
			cmsContent = typeof saveListDrawerContent !== 'undefined' ? saveListDrawerContent : {},
			hubzuConfig = cmsContent.globalAlerts && cmsContent.globalAlerts.hubzuConfig && cmsContent.globalAlerts.hubzuConfig || {},
			globalFeatureFlag = hubzuConfig.enableGlobalAlerts || 'N',
			defaultAlertsFeatureFlag = hubzuConfig.enableDefaultAlerts || 'N',
			bidAlertsFeatureFlag = hubzuConfig.enableBidAlerts || 'N';

		if (featureLevel === 'global') {
			return globalFeatureFlag === 'Y' ? true : false;
		} else if (featureLevel === 'default') {
			return (globalFeatureFlag === 'Y' && defaultAlertsFeatureFlag === 'Y') ? true : false;
		} else if (featureLevel === 'bid') {
			return (globalFeatureFlag === 'Y' && bidAlertsFeatureFlag === 'Y') ? true : false;
		}
	};

	Utils.setPlaceholders = function () {
		var supportPlaceholder = ('placeholder' in document.createElement('input'));

		if (!supportPlaceholder) {

			$("[placeholder]").each(function () {

				if ($(this).attr("type") != 'password') {
					$(this).val($(this).attr('placeholder'));
				}
			});

			$("[placeholder]").focus(function () {

				var input = $(this);
				if (input.val() == input.attr("placeholder")) {
					input.val("");
					input.removeClass("placeholder");
				}
			}).blur(function () {
				var input = $(this);
				if ((input.val() == "" || input.val() == input.attr("placeholder")) && input.attr("type") != 'password') {
					input.addClass("placeholder");
					input.val(input.attr("placeholder"));
				}
			});

			var cUrl = window.location.href;
			if (cUrl.indexOf('/create-account') == -1 && cUrl.indexOf('/user-profile') == -1) {
				$("[placeholder]").blur();
			}



			$("[placeholder]").parents("form").submit(function () {
				$(this).find("[placeholder]").each(function () {
					var input = $(this);
					if (input.val() == input.attr("placeholder")) {
						input.val("");
					}
				})
			});
		}
	}
	/*Utils.redirecFromSaveDrawer = function(){
		window.location.href=document.referrer;
	};*/

	Utils.redirect = function (url, backUrl) {
		var thisUrl = encodeURI(contextPath + url);
		window.location.href = thisUrl;
	};

	Utils.regContinueToPreviousPage = function (acc, backUrl) {

		var redirectUrl = contextPath + '/';

		if (typeof (Storage) !== "undefined" && (backUrl == '' || backUrl == undefined)) {
			try {
				if (localStorage.nextUrl != undefined) {
					redirectUrl = localStorage.nextUrl;
				}

				/*localStorage.removeItem('nextUrl');*/
				Utils.removeStorage('registeringEmail');
				localStorage.removeItem('registeringRole');
			}
			catch (e) {

			}
		} else {
			redirectUrl = backUrl;
		}

		if (acc == 1) {
			$('#ajax-loader-mask').css('display', 'block');
			$('#ajax-loader').css('display', 'block');
		}

		window.location.href = redirectUrl;
	};

	Utils.setStorage = function (key, val) {

		if (typeof (Storage) !== "undefined") {
			try {
				localStorage.setItem(key, val);
			}
			catch (e) {
				$.cookie(key, val);
			}
		}
		else {
			$.cookie(key, val);
		}

	};

	Utils.getStorage = function (key) {

		var returnValue = '';

		if (typeof (Storage) !== "undefined") {
			try {
				returnValue = localStorage.getItem(key);
				if (returnValue == null) {
					returnValue = $.cookie(key);
				}
			}
			catch (e) {
				returnValue = $.cookie(key);
			}
		}
		else {
			returnValue = $.cookie(key);
		}

		if (returnValue == undefined || returnValue == null) {
			returnValue = '';
		}

		return returnValue;
	};

	Utils.removeStorage = function (key) {

		if (typeof (Storage) !== "undefined") {
			try {
				localStorage.removeItem(key);
				$.removeCookie(key);
			}
			catch (e) {
				$.removeCookie(key);
			}
		}
		else {
			$.removeCookie(key);
		}

	};

	Utils.setRegContinuePage = function () {

		if (curURL.indexOf('nextUrl=') > -1 && typeof (Storage) !== "undefined" && history.pushState != undefined && history.pushState) {
			var nextUrl = curURL.split('nextUrl=');
			nextUrl = nextUrl[1];
			var newUrl = curURL.replace('?nextUrl=' + nextUrl, '');
			newUrl = newUrl.replace('&nextUrl=' + nextUrl, '');
			curURL = newUrl;
			window.history.pushState(null, null, newUrl);

			nextUrl = decodeURI(Utils.replaceAll('%3f', '?', Utils.replaceAll('%255C', '/', Utils.replaceAll('%5C', '/', Utils.replaceAll('\\', '/', nextUrl)))));

			try {

				localStorage.nextUrl = nextUrl;
			}
			catch (e) {

			}

			$(window).bind('popstate',
				function (event) {
					try {
						if (event.target.window.location.href.indexOf('nextUrl=') > -1) {
							Utils.regContinueToPreviousPage();
						}
					}
					catch (e) {

					}
				});
		}

	};
	/*To check whether its an iOS device*/
	Utils.checkIfIosNavigator = function () {
		if (navigator && /iPad|iPhone|iPod/.test(navigator.userAgent) && window && !window.MSStream) {
			return true;
		}
		return false;
	};
	//redirect to registration page
	Utils.redirectToRegistration = function (url) {

		if (typeof (Storage) !== "undefined" && history.pushState != undefined && history.pushState) {
			var nextUrl = encodeURI(window.location.href);
			if (nextUrl.indexOf('create-account') > -1) {
				nextUrl = encodeURI(window.location.protocol + "//" + window.location.host + contextPath);
			}

			nextUrl = Utils.replaceAll('/', '%5C', nextUrl);

			//localStorage.nextUrl = nextUrl;
			if (url.indexOf('?') > -1) {
				url = url + '&nextUrl=' + nextUrl;
			}
			else {
				url = url + '?nextUrl=' + nextUrl;
			}

		}

		Utils.redirect(url);

	};

	Utils.replaceAll = function (find, replace, str) {

		str = str.replace(find, replace);
		if (str.indexOf(find) > -1) {
			str = Utils.replaceAll(find, replace, str);
		}

		return str;

		//return str.replace(new RegExp(find, 'g'), replace);
	}

	Utils.validateSigninData = function (options) {

		try {

			var email = options && options.email || '',
				password = options && options.password || '',
				formObject = options && options.formObject || '';

			var hasError = false;

			if (!email) {
				hasError = true;
				$(formObject + ' .login-form-error.email').text('email/screen name is required');
				$(formObject + ' .login-form-error.email').show();
			}
			if (!password) {
				hasError = true;
				$(formObject + ' .login-form-error.password').text('password is required');
				$(formObject + ' .login-form-error.password').show();

				GAUtils.eventTracking.trackevent("New-Sign In", "Sign In Click", "LoginFailed", ' | visitorId :' + $.cookie('visitorId'));
			}
			if (!hasError) {
				Utils.userLogin(options);
			} else {
				$('#gobalSignInSubmit').prop('disabled', false);
			}
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:validateSigninData|" + e, null, 1);
		}

	},
		Utils.userLogin = function (options) {

			try {

				// Start loading after specified time
				var delayTime = saveListDrawerContent.GlobalConfig.loaderDelay || 3000;
				//var showLoginStatusLoader = setTimeout(function() {
				$("#ajax-loader").show();
				$("#ajax-loader-mask").show();
				//}, delayTime);

				//$("#ajax-loader-mask").zIndex(9998);
				//$("#ajax-loader").zIndex(9999);

				$('#loginFormWarning').addClass('hide');
				$(options.formObject + ' .login-form-error').hide();
				$(".l-static-text.login-default-message").removeClass('hide');
				$('#gobalSignInSubmit').attr('disabled', 'disabled');
				var showLoaderError = false,
					locationChanged,
					scanStatus,
					locationUrl = window.location.href,
					signinUrl = (contextPath.indexOf('portal') > -1) ? contextPath + '/signIn' : contextPath + '/portal/signIn',
					username = options && options.email || '',
					password = options && options.password || '',
					formObject = options && options.formObject || '',
					callback = options && options.callback || null,
					self = this,
					viewObject = options && options.viewObject || null,
					referrer = options && options.referrer || null,
					tokenUrl = (typeof contextPath !== "undefined") ? contextPath + '/portal/lean-regn/getUserToken' : '/portal/lean-regn/getUserToken';

				// Get access token 
				Utils.getAccessToken({ viewObject: self, callback: executeTokenCallback });

				function executeTokenCallback(accessData) {
					var cryptoAlgVersion = 'V1';
					//if (isEnableNewEncryption && forge) {
					if (true && forge) {
						cryptoAlgVersion = 'V2';
						password = Utils.forgeEncryptPassword({ password: password, salt: accessData.salt });
					} else {
						password = Utils.encryptPassword({ password: password, salt: accessData.salt });
					}

					$.ajax({
						type: "POST",
						url: signinUrl,
						xhrFields: { withCredentials: true },
						data: {
							username: username,
							password: password,
							requestId: accessData.requestId,
							cryptoAlgVersion: cryptoAlgVersion,
							encAlgVersion: 'V4'
						},
						success: function (response) {
							// Clear time outs - remove showStatusLoader from window
							//clearTimeout(showLoginStatusLoader);
							//Code refactoring so that we can call any type of login and call  this  function
							Utils.processAfterSignInSucess(response, options);
						},
						error: function (xhrObject, textStatus, errorThrown) {
							$("#ajax-loader").hide();
							$("#ajax-loader-mask").hide();
							$('#gobalSignInSubmit').prop('disabled', false);
							$(formObject + ' .login-form-error.responseError').html('Internal server error, please try again later');
							$(formObject + ' .login-form-error.responseError').show();
							GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:doLogin|Ajax error|" + errorThrown, null, 1);
						}
					});

				}

			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:doLogin|" + e, null, 1);
			}
		},
		Utils.decryptSensitiveUserData = function (string, caseFlag) {
			var key = "0123456789abcdef0123456789abcdef";
			var iv = "1234567890ABCDEF";

			if(string && string !== null && string !== undefined){
				switch (caseFlag) {
					case 'email':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							var decryptedEmail = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
							return decryptedEmail;
	
						} catch (error) {
							console.error("Decryption email error:", error.message);
						}
					case 'fname':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							var decryptedFirstName = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
							return decryptedFirstName;
						} catch (error) {
							console.error("Decryption first name error:", error.message);
						}
					case 'lname':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var decryptedLastName = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
							return decryptedLastName;
	
						} catch (error) {
							console.error("Decryption LastName error:", error.message);
						}
					case 'phone':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var decryptedPrimaryPhone = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
	
							return decryptedPrimaryPhone;
	
						} catch (error) {
							console.error("Decryption phone error:", error.message);
						}
					case 'add1':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var add1 = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
	
							return add1;
	
						} catch (error) {
							console.error("Decryption add1 error:", error.message);
						}
					case 'add2':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var add2 = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
	
							return add2;
	
						} catch (error) {
							console.error("Decryption add2 error:", error.message);
						}
					case 'city':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var ucity = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
	
							return ucity;
	
						} catch (error) {
							console.error("Decryption city error:", error.message);
						}
					case 'state':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var ustate = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
	
							return ustate;
	
						} catch (error) {
							console.error("Decryption state error:", error.message);
						}
					case 'zip':
						try {
							var parsedkey = cryptoJs.enc.Latin1.parse(key);
							var parsedIV = cryptoJs.enc.Latin1.parse(iv);
							var decrypted = cryptoJs.AES.decrypt(string, parsedkey, {
								iv: parsedIV,
								mode: cryptoJs.mode.CBC,
								padding: cryptoJs.pad.ZeroPadding
							}).toString(cryptoJs.enc.Latin1);
	
							
							var uzip = decrypted.toString(cryptoJs.enc.Utf8).replace(/\u001c/g, '');
	
							return uzip;
	
						} catch (error) {
							console.error("Decryption zip error:", error.message);
						}
					default:
						return '';
				}
			}
			
		},
		Utils.processAfterSignInSucess = function (response, options, source) {
			
			try {

				console.log('Before decryption:', response)

				$('#gobalSignInSubmit').prop('disabled', false);
				var showLoaderError = false,
					locationChanged,
					scanStatus,
					locationUrl = window.location.href,
					username = options && options.email || '',
					password = options && options.password || '',
					formObject = options && options.formObject || '',
					callback = options && options.callback || null,
					self = this,
					viewObject = options && options.viewObject || null,
					referrer = options && options.referrer || null,
					options = options || {};

				response = response.data;

				// Notify Native app with message 
				if (response && typeof response.user != 'undefined' && window.notifyNativeApp) {

					setTimeout(function () {
						var userPostData = {
							"uuid": response.user.realbidUserId,
							"message": "PostMessageWebViewSignin"
						};
						window.notifyNativeApp(JSON.stringify(userPostData), '*');
					}, 1000);
				}

				if (response && response.user) {
					// Close the dialog box

					var decryptedEmail = '';
					var decryptedFirstName = '';
					var decryptedLastName = '';
					var decryptedPrimaryPhone = '';

					if (response.user.userEmailId) { // cryptoJs userEmailId
						var userEmailIdString = response.user.userEmailId ? response.user.userEmailId : "";
						decryptedEmail = Utils.decryptSensitiveUserData(userEmailIdString, "email");
					}

					if (response.user.userFirstName) { // cryptoJs userFirstName
						var userFirstNameString = response.user.userFirstName ? response.user.userFirstName : "";
						decryptedFirstName = Utils.decryptSensitiveUserData(userFirstNameString, "fname");
					}

					if (response.user.userLastName) { // cryptoJs userLastName
						var userLastNameString = response.user.userLastName ? response.user.userLastName : "";
						decryptedLastName = Utils.decryptSensitiveUserData(userLastNameString, "lname");
					}

					if (response.user.primaryPhone) { // cryptoJs userEmailId
						var primaryPhoneString = response.user.primaryPhone;
						decryptedPrimaryPhone = Utils.decryptSensitiveUserData(primaryPhoneString, "phone");
					}

					Utils.setCookieDetails('loggedInEmail', decryptedEmail);
					if (typeof enableAutoPopulateFeedback !== "undefined" && enableAutoPopulateFeedback === true && typeof webengage !== "undefined") {
						webengage.feedback.options('formData', [
							{
								'name': 'email',
								'value': decryptedEmail,
								'mode': 'readOnly'
							}, {
								'name': 'name',
								'value': decryptedFirstName + ' ' + decryptedLastName,
							}, {
								'name': 'Phone',
								'value': decryptedPrimaryPhone,
							}
						]);
						webengage.feedback.render();
					}
					window.userSession = {
						userId: response.user.realbidUserId,
						userStatus: response.user.userStatus,
						idologyStatus: response.idologyStatus || false,
						userType: response.user.userType,
						enabledFeatures: response.user.loggedInUserDetail.enabledFeatures || null,
						optInEnabled: response.OPT_IN_ENABLED || false,
						smsOptedFlag: response.SMS_OPTED_FLAG || null,
						phone: decryptedPrimaryPhone || null,
						isSmallSeller: response.user.loggedInUserDetail.isSmallSeller || null,
						smallSellerUserRole: response.user.loggedInUserDetail.smallSellerUserRole || '',
						isCfdBuyer: response.isCfdBuyer || null
					};
					/**
					 * Read accessToken and refreshToken and keep then in secure cookies 
					 * These are required to access FC micro services 
					 * accessToken, refreshToken
					 */
					if (response.jwtDetailsDto) {
						var accessToken = response.jwtDetailsDto.accessToken || '',
							refreshToken = response.jwtDetailsDto.refreshToken || '',
							oneday = new Date(Date.now() + 86400e3);

						if (accessToken)
							Utils.setSecureCookieDetails('_fcat', accessToken, oneday);

						if (refreshToken)
							Utils.setSecureCookieDetails('_fcrt', refreshToken, oneday);
					}

					var add1 = response.user.address1 ? Utils.decryptSensitiveUserData(response.user.address1, "add1") : "";
					var add2 = response.user.address2 ? Utils.decryptSensitiveUserData(response.user.address2, "add2") : "";
					var uCity = response.user.city ? Utils.decryptSensitiveUserData(response.user.city, "city") : "";
					var uState = response.user.state ? Utils.decryptSensitiveUserData(response.user.state, "state") : "";
					var uZip = response.user.zip ? Utils.decryptSensitiveUserData(response.user.zip, "zip") : "";
			
					var sName = response.user.screenName ? Utils.decryptSensitiveUserData(response.user.screenName, "screenName") : "";

					response.user.userFirstName = decryptedFirstName;
					response.user.userLastName = decryptedLastName;
					response.user.screenName = sName;
					response.user.userEmailId = decryptedEmail;
					response.user.address1 = add1;
					response.user.address2 = add2;
					response.user.city = uCity;
					response.user.state = uState;
					response.user.zip = uZip;
					response.user.primaryPhone = decryptedPrimaryPhone;

					response.user.loggedInUserDetail.address1 = add1;
					response.user.loggedInUserDetail.address2 = add2;
					response.user.loggedInUserDetail.city = uCity;

					window.userSession.user = response.user;
					mobileVerificationView.user = response.user;
					window.userSession.user.loggedIn = true;
					console.log('After decryption:', response)
					ga('set', 'dimension11', window.userSession.user.realbidUserId);
					if (typeof options !== "undefined" && typeof options.trackRegistrationEvent !== "undefined" && options.trackRegistrationEvent) {
						var userSession = window.userSession || {};
						var userId = userSession.userId || null;
						if (userId) {
							options.gaLabel += '|userId: ' + userId;
						}
						var isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false;
						if (isProxyBiddingEnabled) {
							//var userId = window.userSession && window.userSession.userId || 'NA';
							var trackGALabel = "Property Id: " + window.propertyId;

							GAUtils.eventTracking.trackevent("", "Register Completed", options.gaLabel + "|" + trackGALabel + " | Screen Name: Proxy Bidding Yes");
						} else {

							GAUtils.eventTracking.trackevent("", "Register Completed", options.gaLabel + "|Proxy Bidding: No");
						}

					}
					/**
					 * New home page callbacks - after login ceremonies 
					 * New SRP page callbacks - after login ceremonies 
					 * New PDP page callbacks - after login ceremonies 
					 */
					var pageRouter = typeof window.htimestamp === "object" &&
						(window.htimestamp.home ||
							window.htimestamp.srpPage ||
							window.htimestamp.pdpPage || '');
					if (pageRouter) {
						pageRouter.updateUserComponents({
							userEmailId: decryptedEmail,
							userFirstName: decryptedFirstName
						});
					}
					// If preferences are enabled for this USER
					if (typeof window.userSession !== "undefined" && window.userSession.enabledFeatures && window.userSession.enabledFeatures.length) {
						if (window.userSession.enabledFeatures.indexOf("BUYER_PREFERENCE") > -1)
							$(".add-preferences").removeClass('hide');
					}
					var myAccountPageArray = [];
					if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.header != 'undefined' && typeof saveListDrawerContent.header.MyHubzu != 'undefined' && typeof saveListDrawerContent.header.MyHubzu.myAccountPages != 'undefined') {
						myAccountPageArray = (saveListDrawerContent.header.MyHubzu.myAccountPages).split(',');
					}
					var isMyAccountPage = false;
					$.each(myAccountPageArray, function (index, value) {
						if ((window.location.href).indexOf(value) > -1 && !isMyAccountPage) {
							isMyAccountPage = true;
						}
					});
					if (isMobile) {

						if ((window.location.href).indexOf('navigateFrom=menu') > -1 && typeof userSession != 'undefined' && userSession.userId != null) {
							$('.myAccount').addClass('active');
							$('.mobile-back-btn').removeClass('hide').addClass('openMyAccountMenu').addClass('closeBuyerDashboard').removeClass('mobile-back-btn');
							$(".closeBuyerDashboard").unbind('click').bind('click', function () {
								$('.myAccount').removeClass('active');
								nView.openMyAccountsMenu();
							});
						} else if (isMyAccountPage) {
							$('.mobile-back-btn').removeClass('hide');
						}
					}

					if (typeof sessionView != 'undefined') {
						sessionView.render();
					}
					/**
					  * check to destroy the automatic registration popup on timeinterval
					  */
					var resetPopup = Utils.getCookieDetails('resetPopup') || '';
					if ((typeof golbalSRPView !== "undefined" || typeof golbalPDPView !== "undefined") && !isMobile) {
						if (resetPopup == '') {
							Utils.setIntervalToShowRegister();
						}
					}
					if (window.globalAlertView && Utils.isGlobalAlertsEnabled()) {
						var setDimensionParameters = { 'experimentType': 'onLogin', 'activateExperiment': false };
						Utils.setDimensionDetails(setDimensionParameters);

						//Check if firebase session is available and Signout firebase
						if (globalAlertView.submitFromAlertView && typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y') {
							var callbackOptions = {
								openPhoneVerification: true,
								fromGlobalAlert: true
							}
							$('#loginRegisterDialog .modal-content').removeClass('activeStage');
							console.log("checkMobileVerificationRequired - called 3");
							mobileVerificationView.checkMobileVerificationRequired(callbackOptions);
						} else {
							if (typeof firebase != 'undefined' && firebase && firebase.apps.length) {
								window.globalAlertView.signOutFirebase();
							}
							console.log("checkMobileVerificationRequired - else block - called 4");
							window.globalAlertView.listenStateChage({ userId: response.user.realbidUserId });
						}


					}
					if (options.callback) {
						Utils.onLoggedIn();
						viewObject.mobileVerificationEnabled = true;
						options.callback(response, viewObject);
						if (locationUrl.indexOf('/my-shopper') > 0) {
							var opt = {};
							opt.formObject = '#loginRegisterDialog';
							Utils.showSuccessRegistration(opt);

						}
						return false;
					}

					if (locationUrl.indexOf('/small-seller-landing') > -1 || locationUrl.indexOf('/sell') > -1) {
						var redirectURL = $(".sellaHomeRedirectMobile").attr("hrefurllogin");
						Utils.redirect(redirectURL);
					}
					locationChanged = false;

					var loginRegisterSource = 'Direct';
					if (response.isSocialSignIn) {
						loginRegisterSource = 'Social - Google';
					}
					if (options.funnelStart) {
						GAUtils.eventTracking.trackevent("New-Sign In", 'Sign In Click | visitorId :' + $.cookie('visitorId'), "Registration-SignIn Success");
						//	GAUtils.eventTracking.trackevent("New-Sign In", 'Sign In Click | visitorId :' + $.cookie('visitorId') , "Registration-SignIn Success | referrer: GlobalAlerts | Source: " + loginRegisterSource);
						//	GAUtils.eventTracking.trackevent("", "Registration-SignIn Success", ' | visitorId :' + $.cookie('visitorId')); 
						self.funnelStart = false;
					} else if (options.srcName) {
						GAUtils.eventTracking.trackevent("New-Sign In", 'Sign In Click | visitorId :' + $.cookie('visitorId'), "Registration-SignIn Success | referrer: " + options.srcName + " | Source: " + loginRegisterSource, ' | visitorId :' + $.cookie('visitorId'));
					} else {
						//GAUtils.eventTracking.trackevent("New-Sign In", 'Sign In Click | visitorId :' + $.cookie('visitorId'), "Registration-SignIn Success | Source: " + loginRegisterSource, ' | visitorId :' + $.cookie('visitorId'));
						GAUtils.eventTracking.trackevent("New-Sign In", 'Sign In Click | visitorId :' + $.cookie('visitorId'), "Registration-SignIn Success");
					}

					if (response.passwordExpired == 'Y' && !locationChanged) {
						Utils.redirect("/portal/lean-regn/getChangeExpiredPasswordPage");
						locationChanged = true;
					} else if (response.updatePassword == 'Y' && !locationChanged) {
						// NOTE: updatePassword come as Y only for pending user. 
						// Pending user concept is only available in old bid wizard
						Utils.redirect("/portal/lean-regn/getChangeExpiredPasswordPage");
						locationChanged = true;
						if (window.localStorage) {
							// Temp variable to update description in reset page
							localStorage.setItem('hasReset', 'Y');
						}
					}

					Utils.RecentSearch.update();
					Utils.onLoggedIn();
					if (!locationChanged) {
						$.each(Utils.Login.callbacks, function (index, callback) {
							callback();
						});
					}
					if (window.location.href.indexOf('/portal/ci/') > -1) {
						showLoaderError = true;
					}
					if (window.location.href.indexOf('/getChangeExpiredPasswordPage') > -1) {
						Utils.redirect("/");
					}
					// get idology status and display a toast message with a mask
					scanStatus = response.idology_scan_status;
					if (scanStatus != "ID_SCAN_NOT_REQUIRED") {

						$("#ajax-loader-mask").show();
						$("#scanStatusToast").removeClass('hide');

						if (scanStatus == "ID_SCAN_SUCCESS") {
							$("#scanStatusSuccess").removeClass('hide');
						} else if (scanStatus == "ID_SCAN_PENDING") {
							$("#scanStatusPending").removeClass('hide');
						} else if (scanStatus == "ID_SCAN_FAILED") {
							$("#scanStatusFail").removeClass('hide');
						}


						// hide toast message after 5 seconds and remove mask
						setTimeout(function () {
							$("#ajax-loader-mask").hide();
							$("#scanStatusToast").addClass('hide');
						}, 5000);


					}
					if (typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y' && !(globalAlertView.submitFromAlertView)) {
						var options = {};
						mobileVerificationView.checkMobileVerificationRequired(options);
						console.log("checkMobileVerificationRequired - 729 line - called 6");
					} else {
						options.locationChanged = locationChanged;
						Utils.showSuccessRegistration(options);
						console.log("checkMobileVerificationRequired - 733 - called 7");
					}


				} else if (response.error) {

					try {

						window.userSession = {
							userId: null,
							userStatus: null,
							idologyStatus: null,
							userType: null,
							enabledFeatures: null,
							optInEnabled: false,
							smsOptedFlag: null,
							phone: null,
							smallSellerUserRole: '',
							isCfdBuyer: null
						};

						GAUtils.eventTracking.trackevent("New-Sign In", "Sign In Click", response.error);

						var errorCode = response.error || 'GENERIC_ERROR',
							errorMessage = saveListDrawerContent.errorMessages.loginErrors[errorCode] || saveListDrawerContent.errorMessages.loginErrors['GENERIC_ERROR'];
						$(".login-default-message").addClass("hide");
						if (errorCode === 'USE_EMAIL_ID_FOR_LOGIN') {

							var uEmail = response.userEmailId ? response.userEmailId : "";

							$('#loginEmailAddress').val($.trim(uEmail));
							$('#loginFormWarning').removeClass('hide');
							$('#loginFormWarning').html(errorMessage);

							if ($(formObject + ' .ga-login-email')) {
								$(formObject + ' .ga-login-email').val($.trim(uEmail));
								$(formObject + ' .loginFormWarning').removeClass('hide');
								$(formObject + ' .loginFormWarning').html(errorMessage);
							}

						} else if (source && source === 'googleonetap') {
							$('#errorToastMessage #errorContent .errorMessage').html(errorMessage).focus();
							$('#errorToastMessage').fadeIn();
							$('#errorToastMessage').fadeIn('300').delay(5000).fadeOut('slow');
						} else if (self.socialSource && self.socialSource == 'apple') {
							$('#appleSocialLoginSubmit').prop('disabled', false);
							$('#errorToastMessage #errorContent .errorMessage').html(errorMessage).focus();
							$('#errorToastMessage').fadeIn();
							$('#errorToastMessage').fadeIn('300').delay(5000).fadeOut('slow');
						} else {
							// showing error message
							//$(formObject + ' .login-form-error.responseError').html(errorMessage);
							//$(formObject + ' .login-form-error.responseError').show();
							if(errorCode === 'MISSING_LASTNAME_IN_GOOGLE_ACCOUNT') {
								errorMessage  = "Please add 'Last Name' to your Google profile if you are signing in to Hubzu through Google.";
							}
							$('.login-form-error.responseError').html(errorMessage);
							$('.login-form-error.responseError').show();
							if (document.body.classList.contains('register-page-container')) {
								$('#apple-social-login-form-error').removeClass('hide');
								$('#apple-social-login-form-error').text(errorMessage);
							}
						}
					} catch (e) {
						$("#ajax-loader").hide();
						$("#ajax-loader-mask").hide();
						$('.login-form-error.responseError').html(errorMessage);
						$('.login-form-error.responseError').show();
						// $(formObject + ' .login-form-error.responseError').html(errorMessage);
						// $(formObject + ' .login-form-error.responseError').show();
						GAUtils.eventTracking.trackevent("Error:New SignIn", "file:commonutils.js,function:ajax:success", e);
					}

				} else {
					var errorMessage = saveListDrawerContent.errorMessages.loginErrors['GENERIC_ERROR'];
					$("#ajax-loader").hide();
					$("#ajax-loader-mask").hide();
					$(formObject + ' .login-form-error.responseError').html(errorMessage);
					$(formObject + ' .login-form-error.responseError').show();
				}

				// 
				if (showLoaderError === false) {
					$("#ajax-loader").hide();
					if ((scanStatus == "ID_SCAN_NOT_REQUIRED" && response.user) || response.error) {
						$("#ajax-loader-mask").hide();
					}
				}

				if (typeof golbalPDPView !== "undefined" && golbalPDPView && typeof propCategory != 'undefined' && propCategory == 'NPL') {
					golbalPDPView.renderNplLeadView();
				}
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:processAfterSignInSucess|" + e, null, 1);
			}
		},


		Utils.showSuccessRegistration = function (options) {
			var formObject = options && options.formObject || '',
				locationUrl = window.location.href,
				locationChanged = options.locationChanged || false;
			if(locationUrl.indexOf('/register-sign-in') > -1) {
				Utils.redirect('/') // redirect to home
			} else {
				$(formObject + ' .closeButton').click();
			}
			if (locationUrl.indexOf('/property/') > -1 && !locationChanged && typeof golbalPDPView != 'undefined') {
				golbalPDPView.afterLogin();
			} else if (locationUrl.indexOf('/portal') > -1 && !locationChanged && locationUrl.indexOf('/portal/bid') > -1 && locationUrl.indexOf('/portal/offer') > -1) {
				window.location.reload();
			} else if (locationUrl.indexOf('/about/') > -1) {
				globalStaticViews.afterLogin();
			}
		},
		Utils.checkAndSignIn = function (emailId, passCode, callback) {

			try {
				if (emailId != undefined && passCode != '') {
					var email = emailId;
					var pswd = passCode;
				}
				else if ($('#subscriberPasswordScreen') != undefined && $('#subscriberPasswordScreen').is(':visible')) {
					var email = $.trim($("#subscribeEmailAddress").val());
					var pswd = $("#subscribePassword").val();
				} else if ($('#loginEmailAddress') && $('#loginPassword')) {

					var email = $("#loginEmailAddress").val();
					var pswd = $("#loginPassword").val();

				} else {
					var email = $("#emailAddress").val();
					var pswd = $("#password").val();
				}
				var invalid = false;
				if (!email) {
					invalid = true;
					$('#loginModal .login-form-error.email').text('email/screen name is required');
					$('#loginModal .login-form-error.email').show();
				}
				if (!pswd) {
					invalid = true;
					if ($('#subscriberPasswordScreen') != undefined && $('#subscriberPasswordScreen').is(':visible')) {
						$('#subscriberPasswordScreen .login-form-error.password').text('password is required');
						$('#subscriberPasswordScreen .login-form-error.password').show();
					} else {
						$('#loginModal .login-form-error.password').text('password is required');
						$('#loginModal .login-form-error.password').show();
					}
					GAUtils.eventTracking.trackevent("New-Sign In", "Sign In Click", "LoginFailed", ' | visitorId :' + $.cookie('visitorId'));
				}
				if (!invalid) {
					Utils.signIn(emailId, passCode, callback);
				}
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:checkAndSignIn|" + e, null, 1);
			}
		};
	Utils.isParamExit = function (param) {
		return app.utils.param(param);
	};
	Utils.isDeletedUser = function (email) {
		try {

			var isDeleted = false,
				url = contextPath + '/portal/isDeletedUserEmailId',
				data = {};
			data.emailId = email
			$.ajax({
				type: "POST",
				url: url,
				data: data,
				async: false,
				dataType: 'json',
				success: function (response) {
					if (response.data) {
						isDeleted = true;
						return isDeleted;
					} else {
						isDeleted = false;
						return isDeleted;
					}
				}
			});
			return isDeleted;
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonUtils.js|function:isDeletedUser|" + e, null, 1);
		}
	},
		Utils.UniqueSearchId = function () {
			var totalProp = parseInt($(".totalResultCount").attr("total"));
			if (totalProp == '' || totalProp == undefined || totalProp == null || isNaN(totalProp)) {
				var uniqueSearchId = $.cookie('visitorId') + new Date().getTime();
			} else {
				var uniqueSearchId = '' + totalProp + '-' + new Date().getTime();
			}
			return (uniqueSearchId);
		};
	Utils.signIn = function (emailId, passCode, callback) {
		try {
			$("#ajax-loader").show();
			$("#ajax-loader-mask").show();
			//$("#ajax-loader-mask").zIndex(9998);
			//$("#ajax-loader").zIndex(9999);
			if (emailId != undefined && passCode != undefined) {
				email = emailId;
			}
			else if ($('#subscriberPasswordScreen') != undefined && $('#subscriberPasswordScreen').is(':visible')) {
				var email = $.trim($("#subscribeEmailAddress").val());
				$('#subscriberPasswordScreen .login-form-error').hide();
			} else {
				var email = $.trim($("#emailAddress").val());
				$('#loginModal .login-form-error').hide();
			}

			Utils.doLogin(emailId, passCode, callback);
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:signIn|" + e, null, 1);
		}
	}
	Utils.recognizePageType = function () {
		var pageType = "Home Page";
		var currentPath = window.location.pathname;
		if (currentPath.match(".*/property/.*")) {
			pageType = "PDP";

		} else if ((currentPath.match(".*/searchResult")) || (currentPath.match(".*/luxury-homes-for-sale"))) {
			pageType = "NEW-SRP";
		}
		else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/home") || currentPath == '/') {
			pageType = "HomePage";
		} else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/bid") || currentPath == '/') {
			pageType = "BidWizard";
		} else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/offer") || currentPath == '/') {
			pageType = "OfferWizard";
		} else {
			var ind = currentPath.lastIndexOf("/");
			if (ind > 0) {
				pageType = currentPath.substring(ind + 1);
			} else {
				pageType = currentPath;
			}

		}
		if (pageType == '') {
			pageType = "HomePage";
		}
		return pageType;
	}
	Utils.cleanCookie = function () {
		console.log("clean cookie.")
		var cookiesConfig = {
			marketing: [
				'AWSALBCORS',
				'AWSALB',
				'1P_JAR',
				'cto_bundle',
				'cto_bidid',
				'loggedInLastName',
				'numberOfPdpViews',
				'userVisitedOneTime',
				'numberOfCustomerViews',
				'lastActivityDateTime',
				'loggedInPhone',
				'lastVisitedUserId',
				'loggedInFirstName',
				'userId',
				'subscribeEmail',
				'loggedInEmailActive',
				'visitorId'
			],
			performance: [
				'pbjs-unifiedid',
				'_lr_retry_request',
				'_pbjs_userid_consent_data',
				'_fcrt',
				'_fcat',
				'_lr_env_src_ats',
				'lastVisited',
				'loggedInEmail',
			]
		}
		var concent = JSON.parse(Utils.getCookieDetails('cookieConcentConfig'))
		if (concent && !concent.marketing) {
			cookiesConfig.marketing.forEach(function (item) {
				//Utils.deleteCookie(item);
				console.log('cookie deleted:', item, concent)
				document.cookie = item + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			})
		}
		if (concent && !concent.performance) {
			cookiesConfig.performance.forEach(function (item) {
				//Utils.deleteCookie(item);
				console.log('cookie deleted:', item, concent)
				document.cookie = item + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			})
		}
	}
	Utils.Header = {
		initialize: function (_options) {

			window.enableAppFeatures = function (option) {

				if (typeof window.isNativeApp !== "undefined" && window.isNativeApp === 'Y') {
					isMobileApp = 'Y';
					$("webengagedata").css("display", "none");
					ga('set', 'dimension35', 'mobileApp');
					$('.app-download-container').remove();
					$(".header-section").removeClass("appDownloadOn");
					$("header").removeClass("appLoad");
				}

				if (typeof isMobileApp !== "undefined" && isMobileApp == 'N') {
					var isMobDevice = $(window).width() < 481 ? true : false,
						enabledBanner = typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.hubzuApp != 'undefined' && typeof saveListDrawerContent.hubzuApp.config != 'undefined' && saveListDrawerContent.hubzuApp.config.showAppDownload === 'Y' || false;
					if (isMobDevice && enabledBanner) {
						$('.app-download-container').show();
						$(".header-section").addClass("appDownloadOn");
						$("header").addClass("appLoad");
					}
				}

				// check and add cookie consent banner
				if (!Utils.getCookieDetails("cookieConcentAccepted")) {
					if (typeof isMobileApp !== "undefined" && isMobileApp == 'N' && typeof isAppdashboard === 'undefined') {
						$(".cookieBanner").removeClass('hide');
					}
				}

				// cookie concent
				var cookieConfig = {
					essential: true,
					performance: true,
					marketing: true,
				}

				// start timer to clean cookie in each 10 sec
				setInterval(function () {
					Utils.cleanCookie();
				}, 10000);

				// update cookie consent preference
				$(".cookieBanner .manage-cookie-button").unbind("click").click(function () {
					GAUtils.eventTracking.trackevent(null, 'User selected to manage cookies', 'visitorId: ' + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
					$(".CookieConcentPopup").modal('show');
				});
				$(".cookie-concent-button").unbind("click").click(function () {
					GAUtils.eventTracking.trackevent(null, 'Cookie consent accepted', 'visitorId: ' + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
					Utils.setCookieDetails("cookieConcentAccepted", true, 365);
					Utils.setCookieDetails("cookieConcentConfig", JSON.stringify({
						essential: true,
						performance: true,
						marketing: true,
					}), 365);
					$(".cookieBanner").addClass('hide');
					console.log('accept & dismiss')
				});
				$(".cookieBanner .closeCookieBanner").unbind("click").click(function () {
					GAUtils.eventTracking.trackevent(null, 'Main Cookie consent closed', 'visitorId: ' + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
					$(".cookieBanner").addClass('hide');
				});

				// if(hubzu2 === 'Y'){
				// 	$(".cookieBanner").addClass('hide');
				// 	$(".CookieConcentPopup").removeClass('hide');
				// }else{
				// 	$(".CookieConcentPopup").addClass('hide');
				// }





				$(".CookieConcentPopup .closeButtonPopup").unbind("click").click(function () {
					GAUtils.eventTracking.trackevent(null, 'Manage Cookie consent closed', 'visitorId: ' + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
					// Utils.setCookieDetails("cookieConcentAccepted", true, 365);
					// Utils.setCookieDetails("cookieConcentConfig", JSON.stringify({
					// 	essential: true,
					// 	performance: true,
					// 	marketing: true,
					// }), 365);
					$(".CookieConcentPopup").modal('hide');
					// $(".cookieBanner").addClass('hide');
				});
				$(".CookieConcentPopup .confirm").unbind("click").click(function () {
					GAUtils.eventTracking.trackevent(null, 'Cookie consent accepted : user selected', 'visitorId: ' + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
					Utils.setCookieDetails("cookieConcentAccepted", true, 365);
					Utils.setCookieDetails("cookieConcentConfig", JSON.stringify(cookieConfig), 365);
					$(".CookieConcentPopup").modal('hide');
					$(".cookieBanner").addClass('hide');
				});
				$(".CookieConcentPopup .reject").unbind("click").click(function () {
					GAUtils.eventTracking.trackevent(null, 'Cookie consent accepted : only essentials', 'visitorId: ' + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
					Utils.setCookieDetails("cookieConcentAccepted", true, 365);
					Utils.setCookieDetails("cookieConcentConfig", JSON.stringify({
						essential: true,
						performance: false,
						marketing: false,
					}), 365);
					$(".CookieConcentPopup").modal('hide');
					$(".cookieBanner").addClass('hide');
					console.log('reject cookie');
				});

				$(".CookieConcentPopup .performance input:checkbox").unbind("change").change(function () {
					cookieConfig.performance = $(this).is(':checked')
				});
				$(".CookieConcentPopup .marketing input:checkbox").unbind("change").change(function () {
					cookieConfig.marketing = $(this).is(':checked')
				});
			}

			var delayTime = Number(12000);

			if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.hubzuApp != 'undefined' && typeof saveListDrawerContent.hubzuApp.config != 'undefined' && typeof saveListDrawerContent.hubzuApp.config.timeDelayToShowBanner != 'undefined') {
				delayTime = Number(saveListDrawerContent.hubzuApp.config.timeDelayToShowBanner);
			}

			setTimeout(function () {
				window.enableAppFeatures();
			}, delayTime);

			if (isMobile) {
				var $el = $('#browserVersionUpdate');
				var bottom = parseInt($el.outerHeight(true));
				$("#subscribePopups.modal").css('top', bottom + 'px').css('z-index', '100000');
			}

			if ($('#loginModal')) {
				$('#ajax-loader-mask').hide();
				$('#loginModal').on('shown.bs.modal', function (e) {
					e.preventDefault();
					$('#loginModal .login-form-error').hide();
					$("#emailAddress").val('');
					$("#password").val('');
					$("#emailAddress").focus();
				});
				$('#forgotPassword').click(function (e) { e.preventDefault(); Utils.redirectToRegistration('/create-account?action=forgotPassword'); });
				$('#regenerateValEmail').click(function (e) { e.preventDefault(); Utils.redirectToRegistration('/create-account?action=validateEmail'); });
				$('#emailAddress').focus(function () { $('#loginModal .login-form-error.email').hide(); });
				$('#password').focus(function () { $('#loginModal .login-form-error.password').hide(); });
				$('#password').keypress(function (e) { if (e.keyCode === 13) { Utils.checkAndSignIn(); } });
				$('#loginModalSignIn').click(Utils.checkAndSignIn);

			};

			var popoverConfig = {
				animation: true,
				placement: 'bottom',
				trigger: 'hover',
				html: true,
				template: '<div class="popover fade bottom in dropdown-menu" style="margin: 0;top: 50px; left: -180px; display: block; font-size: 12px;width:430px;"><h3 class="popover-title" style="display: none;"></h3><div class="popover-content"></div></div>'
			};

			$('.header-dd-button').click(function () {
				$("#header-searchbox").removeClass('in');
				if (window.globalAlertView) window.globalAlertView.hideAlertContainer();
			});

			$(window).resize(function () {
				Utils.viewportChangeCall();
			});

			$(".mob-search-header").click(function () {
				$("#header-navigation").removeClass('in');
				if (window.globalAlertView) window.globalAlertView.hideAlertContainer();
			});

			$('header .dropdown-menu>li>a').bind('click touchend', function (e) {
				if (Utils.checkIfIosNavigator()) {
					var el = $(this);
					var link = el.attr('href');
					window.location = link;
				}

			});


			$(".app-download-container .close-icon").unbind("click").bind("click", function () {
				$('.app-download-container').remove();
				$(".header-section").removeClass("appDownloadOn");
				$("header").removeClass("appLoad");
			});

			if ($(window).width() < 768) {
				$('header .dropdown-menu').on('click', 'li a', function (e) {
					$("#header-navigation").removeClass('in');
					$(".dropdown-toggle").find("span.caret").removeClass('caret-up');

				});
			}
			$('#loginRegister').on('click', function (e) {
				e.preventDefault();
				Utils.redirectToRegistration('/create-account');
			});
			$('#loginModalCancel').on('click', function () {
				$('#loginModal').modal('hide');
			});

			$('#loginModalRegisterMobile,#loginModalRegister').on('click', function (e) {
				e.preventDefault();
				GAUtils.eventTracking.trackevent("New-RegisterNow", "Button Click", "Signin-RegisterNow");
				Utils.redirectToRegistration('/create-account');
			});

			$('#listaHomeloginRegister').on('click', function (e) {
				e.preventDefault();
				GAUtils.eventTracking.trackevent("list-a-home-RegisterNow", "Button Click", "list-a-home-RegisterNow");
				Utils.redirectToRegistration('/create-account');
			});

			if (!$("body").hasClass("h-home-page") && !$("body").hasClass("h-srp-page")) {
				Utils.checkIfLoggedIn(_options);
			}

			Utils.populatePluginTemplate();
		}
	};

	Utils.viewportChangeCall = function () {
		try {
			if ($(window).width() > 481) {
				$(".app-download-container").hide();
				$(".header-section").removeClass("appDownloadOn");
				$(".header-section header").removeClass("appLoad");
			}
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:viewportChangeCall|" + e, null, 1);
		}

	};

	Utils.populatePluginTemplate = function () {
		try {
			if (typeof pluginWidgetJson != 'undefined' && pluginWidgetJson != 'undefined' && pluginWidgetJson != '' && pluginWidgetJson != null) {
				$.each(pluginWidgetJson, function (key, value) {
					if (pluginWidgetJson[key].enable == 'true' && pluginWidgetJson[key].loadType == 'onDemand') {
						if (isActivatePlugin(pluginWidgetJson[key].activePages)) {
							(function () {
								var scriptTag = document.createElement("script");
								scriptTag.type = pluginWidgetJson[key].moduleScriptType;
								scriptTag.async = pluginWidgetJson[key].scriptRequestType;
								scriptTag.src = pluginWidgetJson[key].moduleScriptSrc;
								$("#pluginwidget").append(scriptTag);
							})();
						}
					}

				});
			}
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:populatePluginTemplate|" + e, null, 1);
		}

	};
	Utils.Login = { callbacks: [] };
	Utils.doLogin = function (emailId, passCode, callback) {
		try {
			if (emailId != undefined && passCode != undefined) {
				email = emailId;
				pswd = passCode;
			}
			else if ($('#subscriberPasswordScreen') != undefined && $('#subscriberPasswordScreen').is(':visible')) {
				var email = $.trim($("#subscribeEmailAddress").val());
				var pswd = $.trim($("#subscribePassword").val());
			} else if ($('#loginEmailAddress') && $('#loginPassword')) {

				var email = $("#loginEmailAddress").val();
				var pswd = $("#loginPassword").val();

			} else {
				var email = $.trim($("#emailAddress").val());
				var pswd = $.trim($("#password").val());
			}

			var showLoaderError = false;

			try {
				if (/MSIE\s/.test(navigator.userAgent) && parseFloat(navigator.appVersion.split("MSIE")[1]) < 10) {
					var signinUrl = contextPath + '/portal/signIn';
				} else {
					var signinUrl = contextPath + '/portal/signIn';
				}
			}
			catch (e) {
				var signinUrl = contextPath + '/portal/signIn';
			}

			$.ajax({
				type: "POST",
				url: signinUrl,
				xhrFields: { withCredentials: true },
				data: { username: email, password: pswd },
				success: function (response) {

					if (callback) { callback(); }

					if (response.user) {
						var locationChanged = false;
						GAUtils.eventTracking.trackevent("New-Sign In", "Sign In Click", "Registration-SignIn");

						if (response.changePassword == 'Y') {
							Utils.redirect("/changePwd");
							locationChanged = true;
						}
						if (response.passwordExpired == 'Y' && !locationChanged) {
							Utils.redirect("/portal/lean-regn/getChangeExpiredPasswordPage");
							locationChanged = true;
						}
						if (window.location.href.indexOf('/create-account') > -1 && !locationChanged) {

							var cUrl = window.location.href;
							if (cUrl.indexOf('action=resetPassword') > -1) {
								var redirectUrl = contextPath + '/';

								if (typeof (Storage) !== "undefined") {
									try {
										if (localStorage.nextUrl != undefined) {
											redirectUrl = localStorage.nextUrl;
										}

										localStorage.removeItem('nextUrl');
										Utils.removeStorage('registeringEmail');
									}
									catch (e) {

									}
								}

								window.location.href = redirectUrl;
							}
							else {
								Utils.redirect('/');
							}

							locationChanged = true;
							showLoaderError = true;

						}
						window.userSession = typeof window.userSession !== "undefined" ? window.userSession : {};
						window.userSession.user = response.user;
						window.userSession.user.loggedIn = true;
						mobileVerificationView.user = reponse.user;
						var url = window.location.href;
						if (url.indexOf('/property/') > -1 && !locationChanged) {
							golbalPDPView.afterLogin();
						}
						else if (url.indexOf('/searchResult') > -1 && !locationChanged) {
							golbalSRPView.afterLogin();
						}
						else if (url.indexOf('/portal') > -1 && !locationChanged) {
							window.location.reload();
						}
						else if (url.indexOf('/about/') > -1) {
							globalStaticViews.afterLogin();
						}
						Utils.RecentSearch.update();
						Utils.onLoggedIn();
						if (!locationChanged) {
							$.each(Utils.Login.callbacks, function (index, callback) {
								callback();
							});
						}

						if (window.location.href.indexOf('/portal/ci/') > -1) {
							showLoaderError = true;
						}

						$('.cross').click();

						//if($('.closeButton')) $('.closeButton').click();
						if ($('#closeRegisterLogin')) $('#closeRegisterLogin').click();


						$('.closeButtonSubscribe').click();
						scanStatus = response.idology_scan_status;
						if (scanStatus != "ID_SCAN_NOT_REQUIRED") {
							$("#ajax-loader-mask").show();
							$("#scanStatusToast").removeClass('hide');
							if (scanStatus == "ID_SCAN_SUCCESS") {
								$("#scanStatusSuccess").removeClass('hide');
							} else if (scanStatus == "ID_SCAN_PENDING") {
								$("#scanStatusPending").removeClass('hide');
							} else if (scanStatus == "ID_SCAN_FAILED") {
								$("#scanStatusFail").removeClass('hide');
							}
							setTimeout(function () {
								$("#ajax-loader-mask").hide();
								$("#scanStatusToast").addClass('hide');
							}, 5000);
						}
					} else if (response.error) {

						GAUtils.eventTracking.trackevent("New-Sign In", "Sign In Click", response.error);

						if (response.error.indexOf('redirectValidationMail') > -1) {
							Utils.redirectToRegistration('/create-account?action=verifyCode');
							showLoaderError = true;
						}
						else {
							if ($('#subscriberPasswordScreen') != undefined && $('#subscriberPasswordScreen').is(':visible')) {
								$('#subscriberPasswordScreen .login-form-error.responseError').html(response.error);
								$('#subscriberPasswordScreen .login-form-error.responseError').show();
							} else if ($('#loginEmailAddress') && $('#loginPassword')) {
								$('#leanLoginFrom .login-form-error.responseError').html(response.error);
								$('#leanLoginFrom .login-form-error.responseError').show();
							} else {
								$('#loginModal .table-cell.login-dialog-left>.login-form-error').html(response.error);
								$('#loginModal .table-cell.login-dialog-left>.login-form-error').show();
							}
						}


						try {
							if (response.redirectToRegistration == 'Y') {
								var redirectionUrl = "/create-account?emailOrScreenName=" + response.emailOrScreenName;
								setTimeout(function () {
									Utils.redirectToRegistration(redirectionUrl);
								}, 10000);
							}
						} catch (e) {

						}
					}

					if (showLoaderError == false) {
						$("#ajax-loader").hide();
						if ((scanStatus == "ID_SCAN_NOT_REQUIRED" && response.user) || response.error) {
							$("#ajax-loader-mask").hide();
						}
					}

				}
			});
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:doLogin|" + e, null, 1);
		}
	}

	String.prototype.endsWith = function (suffix) {
		return this.indexOf(suffix, this.length - suffix.length) !== -1;
	};


	Utils.savedSearchName = function () {
		var searchBy = '';
		if (typeof SearchJson != 'undefined' && SearchJson != null && SearchJson != '') {
			if (SearchJson.city != '' && SearchJson.zip != '') {
				searchBy = SearchJson.zip + ', ' + SearchJson.city + ', ' + SearchJson.state;
			} else if (SearchJson.city != '' && SearchJson.state != '') {
				searchBy = SearchJson.city + ', ' + SearchJson.state;
			} else if (SearchJson.state != '') {
				searchBy = SearchJson.stateName;
			} else if (SearchJson.searchBy != '') {
				searchBy = SearchJson.searchBy;
			}
		}
		return searchBy;

	}
	Utils.openSubscribePopupLink = function () {

		if (!(isMobile)) {
			$('.logged-in').show();
			$('.logged-out').hide();
			$(".saveListCount, .saved-items-count").text('').addClass('hide');
			$('#welcome-drop-down').html("<li class=\"subscriberDiv\">" + saveListDrawerContent.subcriberContent.subsContent + " <button id=\"completeYourProfile\" class=\"subsButton h-button-1\">" + saveListDrawerContent.subcriberContent.subsButton + "</button></li>");
			/*$('.welcome').addClass('open');*/
			$('.user-welcome-name').text(saveListDrawerContent.subcriberContent.displayName);

			if ($(".register-login-block").length) {
				$(".register-login-block").addClass("signed new-user");
				$(".user-ic-name .ic-text").html(saveListDrawerContent.subcriberContent.displayIcon || 'NU');
			}

			/*$('.commonConfirmPopup .commonConfirmPopupContent').html("<div class=\"subscriberDiv\">"+saveListDrawerContent.subcriberContent.subsContent+" <button id=\"completeYourProfile\" class=\"subsButton h-button-1\">"+saveListDrawerContent.subcriberContent.subsButton+"</button></div>");
			$('.commonConfirmPopup').modal('show');*/
			$('.subsButton').click(function (e) {
				var opt = {};
				var visitor_id = $.cookie('visitorId') || '';
				$.removeCookie("loggedInEmail");
				GAUtils.eventTracking.trackevent("", "Favourite Continue Click", "Clicked on the Contiue to Registration | visitorId : " + visitor_id);
				opt.content = saveListDrawerContent.alertViewSec.favDrawer;
				globalAlertView.showAlertsDrawer(opt);
			});
			$("html, body").animate({ scrollTop: 0 }, "slow");
		}

	},
		Utils.isSubscribedUser = function () {

			try {
				var user = {};
				var subscribeSearchParam = {};
				user = JSON.parse(Utils.getCookieDetails('subscribeUserDetails'));
				subscribeSearchParam = { user: user, saveSearchParameter: saveSearchJson };
				if (typeof SearchJson != 'undefined' && SearchJson != null && SearchJson != '') {
					var polyType = SearchJson.polygonType;
					var polyId = SearchJson.polygonId;
				} else {
					var polyType = '';
					var polyId = '';
				}
				if (user != false) {
					$.ajax({
						url: contextPath + '/portal/isSearchSubscribed/' + polyType + "/" + polyId,
						dataType: 'json',
						async: true,
						method: "POST",
						contentType: "application/json; charset=utf-8",
						data: JSON.stringify(subscribeSearchParam),
						success: function (response) {
							if (response.isSearchSaved == 'Y') {
								$('.saveSearchHeart').html(savedSearchText).addClass('savedSearchHeart').removeClass('saveSearchHeartIcon');
								if (response.searchId != undefined && response.searchId != '' && response.searchId != null) {
									$('.saveSearchHeart').attr('saveSearchId', response.searchId);
								}
								$('.notifySuccessMessage').addClass("hide");
								$('#notifyMeSection').addClass("hide");

								// New filters section - New SRP page
								if (($('.save-search-icon').length) && response && response.searchId) {
									if (isEnableNewSrp && isEnableNewSrp == 'Y') {
										$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#F25D49" });
										$('.save-search-icon').addClass('search-results-saved').animate(200, function () {
											$(this).find(".ss-title").text('Saved Search');
										});
									} else {
										$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#f55c43" });
										$('.save-search-icon')
											.addClass('search-results-saved')
											.animate({ "width": "75" })
											.find(".ss-title").text('Saved');
									}
									$('.save-search-icon').attr('save-search-id', response.searchId);
								}

							} else {

								if (!$('.save-search-icon').length) {
									$('#notifyMeSection').removeClass("hide");
								}
								$('.notifySuccessMessage').addClass("hide");

								$('.saveSearchHeart').html(saveSearchText).removeClass('savedSearchHeart').addClass('saveSearchHeartIcon');
							}
						},
						error: function (response) {
							console.log(response);
						}
					});
				}
				else {
					$('.notifySuccessMessage').addClass("hide");
					$('#notifyMeSection').removeClass("hide");
				}
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:isSubscribedUser|" + e, null, 1);
			}

		},
		Utils.highlightSavedProperties = function () {
			try {
				var propIdJson = {};
				if (typeof propIds != 'undefined' && propIds != '') {
					//Utils.uncheckWatchedProp();
					propIdJson = propIds;
					var propIdList = propIds.split(",");
					var propIdData = { "propertyIds": propIdJson };
					var currentRequest = null;
					currentRequest = $.ajax({
						url: contextPath + '/portal/getPropertyWatchStatus',
						dataType: "json",
						type: 'POST',
						data: JSON.stringify(propIdData),
						contentType: "application/json; charset=utf-8",
						beforeSend: function () {
							if (currentRequest != null) {
								currentRequest.abort();
							}
						}
					}).done(function (data) {
						if (data.propertyStatusList) {
							$(".heartButtonStyle").removeClass('Y').attr('iswatched', 'N').attr('notifyflag', '');
							$('.heart-icon-img').attr('src', contextPath + '/www/images/pdpSaveIcon.png').attr('iswatched', 'N');
							$('.header-icon-dark-img').attr('src', contextPath + '/www/images/headerHeartSelect.png');
							$.each(data.propertyStatusList, function (index, value) {
								$(".heartButtonStyle[rel='" + value + "']").addClass('Y').attr('iswatched', 'Y').attr('notifyflag', 'notify');

								$(".heartListButton[rel='" + value + "']").addClass('Y').attr('iswatched', 'Y').attr('notifyflag', 'notify');

								if ($.inArray(value, propIdList) != -1) {

									$('.heart-icon-img').attr('src', contextPath + '/www/images/pdpSavedIcon.png').attr('iswatched', 'Y');
									$('.header-icon-dark-img').attr('src', contextPath + '/www/images/pdpSavedIcon.png');
									$('.previewPropertyWatch span').html($('#watchPropPreview').attr('unsavetxt'));
									$('.previewPropertyWatch').addClass('disabled');

									$('.pdp-save-icon').addClass("saved").attr('iswatched', 'Y');
								}
							});

							// update Map Watch icons 
							if (window.htimestamp && window.htimestamp.mapView) {
								// if ($(".map-view-parent").is(':visible'))
								window.htimestamp.mapView.getUserSavedProperties({ callback: window.htimestamp.mapView.updateMapIcons });
							}
						}
					});
				}

			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:highlightSavedProperties|" + e, null, 1);
			}
		},
		Utils.uncheckWatchedProp = function () {
			try {
				//remove save search highlight when logout
				$('.saveSearchHeart').html(saveSearchText).removeClass('savedSearchHeart').addClass('saveSearchHeartIcon');
				$(".heartButtonStyle").addClass("N").removeClass('Y').attr('notifyflag', 'notify');
				$('.h-pdp-fav-btn').addClass("N").removeClass('Y').attr('iswatched', 'N').attr('notifyflag', '');

				$(".heartButtonStyle").removeClass('Y').attr('iswatched', 'N').attr('notifyflag', '');
				$('.heart-icon-img').attr('src', contextPath + '/www/images/pdpSaveIcon.png').attr('iswatched', 'N');
				$('.header-icon-dark-img').attr('src', contextPath + '/www/images/headerHeartSelect.png');
				$('.previewPropertyWatch span').html($('#watchPropPreview').attr('savetxt'));
				$('.previewPropertyWatch').removeClass('disabled');

				// Update map icons 
				if (window.htimestamp && window.htimestamp.mapView)
					window.htimestamp.mapView.resetMapWatchIcons();

			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:uncheckWatchedProp|" + e, null, 1);
			}
		},

		Utils.isMarketingCampaign = null;
	Utils.checkIfUserLoggedInFlag = function (viewFunc, callBackObj) {
		try {
			var url = window.location.href;
			if (typeof window.isWebViewLoaded !== "undefined" && window.isWebViewLoaded === "Y") {
				return;
			}
			$.post(contextPath + '/portal/isLogin', function (response) {
				if (_.isUndefined(response.screenName)) {
					viewFunc(false, callBackObj);
				} else {
					callBackObj.userDetails = response;
					viewFunc(true, callBackObj);
				}
			});
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:checkIfUserLoggedInFlag|" + e, null, 1);
		}
	},

		// Like search end
		Utils.checkIfLoggedIn = function (_options) {

			try {
				_options = _options || {};

				//need this on page load so need to either hit service or have a jsp tag		
				var url = window.location.href;
				if (url.indexOf('/my-shopper#myProfile') == -1) {
					localStorage.removeItem("funnelStart");
				}
				Utils.isMarketingCampaign = window.location.search.indexOf('isOptInMarketingCampaign') != -1
					? unescape(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape('isOptInMarketingCampaign').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")) || null
					: null;
				var obj = this;



				$('.account-icon').addClass('loginProgress');
				if (typeof window.isWebViewLoaded !== "undefined" && window.isWebViewLoaded === "Y") {
					return;
				}
				$.post(contextPath + '/portal/isLogin', function (response) {
					$('.account-icon').removeClass('loginProgress');

					if (_.isUndefined(response.screenName)) {

						if (url.indexOf('/my-seller') > -1 || url.indexOf('/cust-care') > -1) {
							Utils.redirect("/home?sl=1");
						}

						window.userSession = {
							userId: null,
							userStatus: null,
							idologyStatus: false,
							userType: null,
							enabledFeatures: null,
							optInEnabled: false,
							smsOptedFlag: null,
							phone: null,
							smallSellerUserRole: '',
							isCfdBuyer: null
						};
						/*** highlight the my account drop down in about us pages */

						var myAccountPageArray = [];
						if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.header != 'undefined' && typeof saveListDrawerContent.header.MyHubzu != 'undefined' && typeof saveListDrawerContent.header.MyHubzu.myAccountPages != 'undefined') {
							myAccountPageArray = (saveListDrawerContent.header.MyHubzu.myAccountPages).split(',');

						}
						var isMyAccountPage = false;
						$.each(myAccountPageArray, function (index, value) {
							if ((window.location.href).indexOf(value) > -1 && !isMyAccountPage) {
								isMyAccountPage = true;
							}
						});
						if (isMyAccountPage) {
							$('.mobile-back-btn').removeClass('hide');
						}
						/*** highlight the my account drop down in about us pages */

						Utils.setCookieDetails('userId', "", '-1');
						if (window.globalAlertView && Utils.isGlobalAlertsEnabled()) {
							window.globalAlertView.showDefaultNotification({});

							//Check if firebase session is available and Signout firebase
							if (typeof firebase != 'undefined' && firebase && firebase.apps.length) {
								window.globalAlertView.signOutFirebase({ isUserSignedOut: true });
							}
						}

						if (url.indexOf('/searchResult') > -1 || url.indexOf('/auctions') > -1) {
							if (!Utils.isMarketingCampaign) {
								/*(function(){
									golbalSubscribeView.render(response);	
								})($);*/
							}
						}
						if ($.cookie('subscribeEmail')) {

							Utils.openSubscribePopupLink();
						} else {
							$('.logged-in').hide();
							$('.logged-out').show();
						}

						if (url.indexOf('/user-profile') > -1) {

							$('.registerWhole').addClass('hide');
							Utils.redirect("/");
							setTimeout(function () {
								$("#ajax-loader").show();
								$("#ajax-loader-mask").show();
							}, 500);
						}

						if (url.indexOf('/searchResult') > -1) {
							Utils.isSavedSearch(response || {}, function () {
								Utils.isSubscribedUser();
							});
						}
						if (url.indexOf('/luxury-homes-for-sale') > -1) {
							Utils.isSubscribedUser();
						}
						if (typeof golbalSaveListDrawerView != 'undefined') {
							// golbalSaveListDrawerView.loadSaveListDrawer(false);
							golbalSaveListDrawerView.loadCount(false);
						}

						Utils.RecentSearch.initialize();

					} else {

						// var decryptedPrimaryPhone = response.primaryPhone ? Utils.decryptSensitiveUserData(response.primaryPhone, "phone") : "";

						// decrypted values

						// var add1 = response.address1 ? Utils.decryptSensitiveUserData(response.address1, "add1") : "";
						// var add2 = response.address2 ? Utils.decryptSensitiveUserData(response.address2, "add2") : "";
						// var uCity = response.city ? Utils.decryptSensitiveUserData(response.city, "city") : "";
						// var uState = response.state ? Utils.decryptSensitiveUserData(response.state, "state") : "";
						// var uZip = response.zip ? Utils.decryptSensitiveUserData(response.user, "zip") : "";
						// var decryptedFirstName = response.userFirstName ? Utils.decryptSensitiveUserData(response.userFirstName, "fname") : "";
						// var decryptedLastName = response.userLastName ? Utils.decryptSensitiveUserData(response.userLastName, "lname") : "";
						// var decryptedEmail = response.userEmailId ? Utils.decryptSensitiveUserData(response.userEmailId, "email") : "";
						// var decryptedPrimaryPhone = response.primaryPhone ? Utils.decryptSensitiveUserData(response.primaryPhone, "phone") : "";
						// var sName = response.screenName ? Utils.decryptSensitiveUserData(response.screenName, "screenName") : "";


						// response.userFirstName = decryptedFirstName;
						// response.userLastName = decryptedLastName;
						// response.screenName = sName;
						// response.userEmailId = decryptedEmail;
						// response.address1 = add1;
						// response.address2 = add2;
						// response.city = uCity;
						// response.state = uState;
						// response.zip = uZip;
						// response.primaryPhone = decryptedPrimaryPhone;
						
						window.userSession = {
							userId: response.realbidUserId,
							userStatus: response.userStatus,
							idologyStatus: response.idologyVerified || false,
							userType: response.userType || null,
							enabledFeatures: response.enabledFeatures || null,
							optInEnabled: response.smsOptInEnabled || false,
							smsOptedFlag: response.smsOptInFlag || null,
							phone: response.primaryPhone || null,
							isSmallSeller: response.isSmallSeller || null,
							smallSellerUserRole: response.smallSellerUserRole || '',
							isCfdBuyer: response.isCfdBuyer || null
						};

						window.userSession.user = response;
						window.userSession.user.loggedIn = true;
						mobileVerificationView.user = response;
						// If preferences are enabled for this USER
						if (typeof window.userSession !== "undefined" && window.userSession.enabledFeatures && window.userSession.enabledFeatures.length) {
							if (window.userSession.enabledFeatures.indexOf("BUYER_PREFERENCE") > -1)
								$(".add-preferences").removeClass('hide');
						}

						/*** highlight the my account drop down in about us pages */

						var myAccountPageArray = [];
						if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.header != 'undefined' && typeof saveListDrawerContent.header.MyHubzu != 'undefined' && typeof saveListDrawerContent.header.MyHubzu.myAccountPages != 'undefined') {
							myAccountPageArray = (saveListDrawerContent.header.MyHubzu.myAccountPages).split(',');

						}
						var isMyAccountPage = false;
						$.each(myAccountPageArray, function (index, value) {
							if ((window.location.href).indexOf(value) > -1 && !isMyAccountPage) {
								isMyAccountPage = true;
							}
						});
						if (isMobile) {

							if ((window.location.href).indexOf('navigateFrom=menu') > -1 && typeof userSession != 'undefined' && userSession.userId != null) {
								$('.myAccount').removeClass('active').addClass('active');
								$('.mobile-back-btn').removeClass('hide').addClass('openMyAccountMenu').addClass('closeBuyerDashboard').removeClass('mobile-back-btn');
								$(".closeBuyerDashboard").unbind('click').bind('click', function () {
									$('.myAccount').removeClass('active');
									nView.openMyAccountsMenu();
								});
							}
						}
						/*** highlight the my account drop down in about us pages */

						if (window.globalAlertView && Utils.isGlobalAlertsEnabled()) {
							if (response.userType === 'BUYR' || response.userType === 'AGENT') {
								var setDimensionParameters = { 'experimentType': 'onLogin', 'activateExperiment': false };
								Utils.setDimensionDetails(setDimensionParameters);
								window.globalAlertView.listenStateChage({ userId: response.realbidUserId });
							} else {
								if (typeof newSkinning !== "undefined" && newSkinning) {
									$('.globalAlertsContainer').addClass('cust-care');
								} else {
									$('.globalAlertsContainer').empty();
								}
							}
						}

						if (url.indexOf('/property/') > -1 && typeof golbalPDPView != 'undefined') {
							golbalPDPView.afterLogin();
						} else if (url.indexOf('/searchResult') > -1) {
							golbalSRPView.afterLogin();
						} else if (url.indexOf('/about/') > -1) {
							globalStaticViews.afterLogin();
						}

						$('.account-icon').removeClass('loginProgress');

						Utils.RecentSearch.update();
						obj.onLoggedIn();

						//remove Global alerts for the old Change password screen.
						if (url.indexOf('/changePwd') != -1) {
							$("#globalAlertsContainerMobile, #globalAlertsContainer").remove();
						}

						if (typeof golbalPDPView !== "undefined" && golbalPDPView && typeof propCategory != 'undefined' && propCategory == 'NPL') {
							golbalPDPView.renderNplLeadView();
						}
					}
					var alertUrlParams = saveListDrawerContent.globalAlerts.labels.alertUrlParam;
					var alertUrlPropParams = saveListDrawerContent.globalAlerts.labels.alertUrlPropParams;
					if (window.location.search.indexOf(alertUrlParams) > -1 && window.location.search.indexOf(alertUrlPropParams) > -1) {
						var queryParams = new URLSearchParams(window.location.search);
						var listingId = queryParams.get(alertUrlPropParams);
						var regxUrl = alertUrlParams.split("=")[0];
						var regxPropUrl = alertUrlPropParams;
						var regex = new RegExp("[\?&]" + regxUrl + "=[^&]+", "g");
						var regexProps = new RegExp("[\?&]" + regxPropUrl + "=[^&]+", "g");
						var queryString = window.location.pathname + window.location.search.replace(regex, '').replace(/^&/, '?');
						queryString = queryString.replace(regexProps, '').replace(/^&/, '?');
						queryString = window.location.protocol + "//" + window.location.host + queryString;
						if (history.pushState && queryString) {
							window.history.replaceState(null, null, queryString);
						}
						if (!$('.gobal-alerts-drawer-slider').hasClass('is-visible')) {
							window.globalAlertView.outBidData = {
								listingId: listingId,
								fromOutBid: true
							};
							$('.globalAlertsSvgIcon').trigger('click');
						}
					}

					if (_options.callback) {
						_options.callback(response);
					}
				});


			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:checkIfLoggedIn|" + e, null, 1);
			}

		};

	Utils.dashboardLogin = function (response) {
		try {
			window.userSession = typeof window.userSession !== "undefined" ? window.userSession : {};
			window.userSession.user = response.user;
			window.userSession.user.loggedIn = true;
			this.onLoggedIn();
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:dashboardLogin|" + e, null, 1);
		}
	};
	Utils.isSavedSearch = function (loginResponse, callback) {


		if (saveSearchJson != null && saveSearchJson != '' && saveSearchJson != "undefined" && saveSearchJson != undefined && Object.keys(saveSearchJson).length != 0 && saveSearchJson.constructor === Object) {

			var polyType = SearchJson.polygonType;
			var polyId = SearchJson.polygonId;

			if ($('.previewPropertyFilter').prop('checked')) {
				saveSearchJson.previewProperty = 'Y';
			}
			if ($('.noBuyerPremiumFilter').prop('checked')) {
				saveSearchJson.noBuyerPremiumFilter = 'Y';
			}
			if ($('.reservePriceFilter').prop('checked')) {
				saveSearchJson.reservePriceRevealed = 'Y';
			}
			if ($('.selfShowingFilter').prop('checked')) {
				saveSearchJson.selfShowingEnabled = 'Y';
			}
			if ($('.brokerCoOpFilter').prop('checked')) {
				saveSearchJson.brokerCoOp = 'Y';
			}
			if ($('.newToHubzuFilter').prop('checked')) {
				saveSearchJson.newToHubzu = 'Y';
			}
			if ($('.cashOnlyFilter').prop('checked')) {
				saveSearchJson.cashOnly = 'Y';
			}

			if (($('.save-search-icon').length)) {

				if ($('#sid_501').prop('checked')) {
					var targetValue = $('#sid_501').attr("filter-value");
					saveSearchJson.sid = targetValue;
				}

				if ($('#marketingPhase_preList').prop('checked')) {
					saveSearchJson.previewProperty = 'Y';
				}
				if ($('#nobuyerpremium_NO_BUYER_PREMIUM').prop('checked')) {
					saveSearchJson.noBuyerPremiumFilter = 'Y';
				}
				if ($('#reservepricerevealed_RESERVE_PRICE_REVEALED').prop('checked')) {
					saveSearchJson.reservePriceRevealed = 'Y';
				}
				if ($('#selfshowingenabled_SELF_SHOWING_ENABLED').prop('checked')) {
					saveSearchJson.selfShowingEnabled = 'Y';
				}
				if ($('#brokerCoOp_BROKER_CO_OP').prop('checked')) {
					saveSearchJson.brokerCoOp = 'Y';
				}
				if ($('#newToHubzu_NEW_TO_HUBZU').prop('checked')) {
					saveSearchJson.newToHubzu = 'Y';
				}
				if ($('#cashOnly_CASH_ONLY').prop('checked')) {
					saveSearchJson.cashOnly = 'Y';
				}
				if ($('#auctionEndingToday_Y').prop('checked')) {
					saveSearchJson.auctionEndingToday = 'Y';
				}
			}

			// Map related params 
			var url = window.location.href;

			if (url.indexOf('rect') > -1 && Utils.getQueryStringValue("rect") == "true") {
				saveSearchJson.rectFlag = 'Y';
			}
			if (url.indexOf('neLatLng') > -1) {
				saveSearchJson.neLatLng = Utils.getQueryStringValue("neLatLng");
			}
			if (url.indexOf('swLatLng') > -1) {
				saveSearchJson.swLatLng = Utils.getQueryStringValue("swLatLng");
			}
			if (url.indexOf('latitude') > -1) {
				saveSearchJson.latitude = Utils.getQueryStringValue("latitude");
			}
			if (url.indexOf('longitude') > -1) {
				saveSearchJson.longitude = Utils.getQueryStringValue("longitude");
			}
			if (url.indexOf('sid') > -1) { // for special type filters
				var params = {};
				new URL(url).searchParams.forEach(function (val, key) {
					if (params[key] !== undefined) {
						params[key] = [params[key]];
						params[key].push(val);
					} else {
						params[key] = val;
					}
				});
				saveSearchJson.sid = (Array.isArray(params.sid)) ? params.sid.join() : params.sid; // check for single or multiple values
			}

			var data = saveSearchJson;

			if (Utils.isMarketingCampaign && Utils.isMarketingCampaign == 'Y') {
				data.isOptInForMarketingCampaign = true;
			}

			$.post(contextPath + '/portal/isSearchSaved/' + polyType + "/" + polyId, data, function (response) {

				if (callback) callback();

				// trigger subscription pop 
				if (Utils.isMarketingCampaign == 'Y' && response && response.isSearchSaved == 'N') {
					var url = window.location.href;
					if (url.indexOf('/searchResult') > -1 || url.indexOf('/auctions') > -1) {
						golbalSubscribeView.render(loginResponse);
					}
				}

				if (response.isSearchSaved == 'Y') {
					if (savedSearchText != undefined) {
						$('.saveSearchHeart').html(savedSearchText).addClass('savedSearchHeart').removeClass('saveSearchHeartIcon');
					}
					if (response.searchId != undefined && response.searchId != '' && response.searchId != null) {
						$('.saveSearchHeart').attr('saveSearchId', response.searchId);
					}
					$('.notifySuccessMessage').addClass("hide");
					$('#notifyMeSection').addClass("hide");

					// New filters section - New SRP page
					if (($('.save-search-icon').length) && response && response.searchId) {
						if (isEnableNewSrp && isEnableNewSrp == 'Y') {
							$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#F25D49" });
							$('.save-search-icon').addClass('search-results-saved').animate(200, function () {
								$(this).find(".ss-title").text('Saved Search');
							});
						} else {
							$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#f55c43" });
							$('.save-search-icon').addClass('search-results-saved').animate({ "width": "75" }).find(".ss-title").text('Saved');
						}
						$('.save-search-icon').attr('save-search-id', response.searchId);
					}
				} else {
					if (saveSearchText != undefined) {
						$('.saveSearchHeart').html(saveSearchText).removeClass('savedSearchHeart').addClass('saveSearchHeartIcon');
					}
					$('.notifySuccessMessage').addClass("hide");
					$('#notifyMeSection').removeClass("hide");

					// New filters section - New SRP page
					if ($('.save-search-icon').length) {
						if (isEnableNewSrp && isEnableNewSrp == 'Y') {
							$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#118BF4" });
							$('.save-search-icon').removeClass('search-results-saved').animate(200, function () {
								$(this).find(".ss-title").text('Save Search');
							});
						} else {
							$('.save-search-icon').removeClass('search-results-saved').animate({ "width": "107" }, 200, function () {
								$(this).find(".ss-title").text('Save Search');
							});
							$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#f9f9f9" });
						}
						if (window.golbalSRPView && window.golbalSRPView.isShowSaveSearchModal) {
							window.golbalSRPView.showSaveSearchModal({
								viewObject: window.golbalSRPView
							});
						}
						$('.save-search-icon').removeAttr('save-search-id');
					}
				}
				if (window.golbalSRPView && window.golbalSRPView.isShowSaveSearchModal) {
					$("#ajax-loader, #loaderBG").hide();
					window.golbalSRPView.isShowSaveSearchModal = false;
				}
			});
		}
	},
		Utils.updateAnonymousUserData = function (emailAddr, isLogin) {

			$.ajax({
				type: "POST",
				url: contextPath + '/portal/updateRedisData',
				data: { 'emailAddr': window.btoa(emailAddr) },

				success: function (response) {
					if (response.status && response.status == "SUCCESS") {
						Utils.highlightSavedProperties();
						if (typeof golbalSaveListDrawerView != 'undefined') {
							// golbalSaveListDrawerView.loadSaveListDrawer(isLogin);
							golbalSaveListDrawerView.loadCount(isLogin);
						}
					}
					// This method is not required, if this Method is triggerd as part of login after clicking save search
					if (typeof window.golbalSRPView !== "undefined" && !window.golbalSRPView.isSaveSearchTriggered) {
						Utils.isSavedSearch({});
					}
				}
			});

		},
		Utils.onLoggedIn = function () {
			try {

				$(".header-nav-links .signin-link, .hamburger-menu .hamburger-user-mobile-actions, .hamburger-menu .support-link, .register-login-block, .hm-login-actions").addClass('signed');
				$(".header-nav-links .register-link").addClass('hide');

				if (typeof newSkinning !== "undefined" && newSkinning) {
					$('#headerHeart,#mobFavoriteItemList').removeClass('hide-item');
				}

				globalParam.config.noOfVisitAttempts = 1;
				var obj = this;
				globalParam.config.isLoggedin = true;
				window.userSession = typeof window.userSession !== "undefined" ? window.userSession : {};
				if (typeof sessionView != 'undefined') {
					sessionView.render();
				}
				var uEmail = window.userSession.user.userEmailId || "";
				var fname = window.userSession.user.userFirstName || "";
				var lname = window.userSession.user.userLastName || "";
				var phone = window.userSession.user.primaryPhone || "";
				var sName = window.userSession.user.screenName || "";

				Utils.setCookieDetails('loggedInEmailActive',uEmail);
				Utils.setCookieDetails('loggedInFirstName', fname);
				Utils.setCookieDetails('loggedInLastName', lname);
				Utils.setCookieDetails('userId', window.userSession.user.realbidUserId);
				Utils.setCookieDetails('subscribeEmail', "", '-1');

				if (window.userSession.user.primaryPhone != '' && window.userSession.user.primaryPhone != null) {
					Utils.setCookieDetails('loggedInPhone', phone);
				}

				$('.account-icon').addClass('my-account-icon').removeClass('account-icon');
				var myAccountText = 'Me';
				if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.header != 'undefined' && typeof saveListDrawerContent.header.MyHubzu != 'undefined' && typeof saveListDrawerContent.header.MyHubzu.myHubzu != 'undefined') {
					myAccountText = saveListDrawerContent.header.MyHubzu.myHubzu;
				}
				/*if(isMobile) {
					$("body").addClass('modal-open');
				}*/
				$('.my-account-icon span.ic-label').html(myAccountText);
				globalParam.config.isElite = window.userSession.user.isElite;

				var headerMenuItem;
				if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.header != 'undefined' && typeof saveListDrawerContent.header.MyHubzu != 'undefined' && typeof saveListDrawerContent.header.MyHubzu.myAccountPages != 'undefined') {
					headerMenuItem = saveListDrawerContent.header.MyHubzu.Buyer;
				} else {
					if (typeof headerMenuItems != "undefined" && headerMenuItems != undefined) {
						headerMenuItem = headerMenuItems || {};
					}
				}
				Utils.updateAnonymousUserData(uEmail, true);
				var url = window.location.href;
				if (url.indexOf('/searchResult') > -1 || url.indexOf('/luxury-homes-for-sale') > -1) {

				}
				if (url.indexOf('/bid') > -1 || url.indexOf('/offer') > -1) {
					$('.bidLogInLink').addClass('hide');
					$('.bidScrName').html(fname).removeClass('hide');
				}
				if (url.indexOf('/changePwd') > -1) {
					var validatEmailUrl = contextPath + '/portal/user/isValidEmailAddress';

					$.ajax({
						type: "POST",
						url: validatEmailUrl,
						data: { 'email': uEmail },
						dataType: 'json',
						success: function (data) {
							if (data.status == "SUCCESS" || data.status == "VALIDATION_DUPLICATE_EMAIL") {
								$('#passCodeToolTip').attr('data-content', data.passwordToolTip);
							}
						}
					});
				}
				if (url.indexOf('/my-shopper') > -1 || url.indexOf('/bid') > -1 || url.indexOf('/offer') > -1) {
					globalParam.config.userId = window.userSession.user.realbidUserId;
					globalParam.config.userEmail = uEmail;
					globalParam.config.userFirstName = fname;
					globalParam.config.userLastName = lname;

					if (window.userSession.user.userType == 'BUYR' || window.userSession.user.userType == 'SIMP_USER') {
						globalParam.config.isAgent = false;
					} else {
						globalParam.config.isAgent = true;
					}
				}

				if ($(".user-ic-name").length) {
					
					// Extract the initials
					var firstInitial = fname.charAt(0);
					var lastInitial = lname.charAt(0);

					// Concatenate the initials
					var initials = firstInitial + lastInitial;
					$(".user-ic-name .ic-text").html(initials);
				}

				if (window.userSession.user.userFirstName != null) {

					$('.user-welcome-name').html(app.utils.shrinkText(fname, 12));
					$('.register-login-block .user-welcome-name').html(app.utils.shrinkText(fname, 12));

					$('.user-welcome-emailId').html(uEmail);
					$('.logged-in').show();
					$('.logged-out').hide();
				} else if (window.userSession.user.screenName != null) {
					$('.user-welcome-name').html(app.utils.shrinkText(sName, 12));
					$('.logged-in').show();
					$('.logged-out').hide();
				} else {
					if ($.inArray('GETREAL_AGENT', window.userSession.user.roleIdList) != -1) {
						if (window.userSession.user.screenName == null) {
							$('.user-welcome-name').html(app.utils.truncate(fname + ' ' + lname, 12));
							$('.logged-in').show();
							$('.logged-out').hide();
						}
					}
					if ($.inArray('BROKER', window.userSessionuser.roleIdList) != -1) {
						if (window.userSession.user.screenName == null) {
							$('.user-welcome-name').html(app.utils.truncate(fname, 12));
							$('.logged-in').show();
							$('.logged-out').hide();
						}
					}
				}

				var screenNameValue = sName;
				if (screenNameValue == null) {
					screenNameValue = app.utils.truncate(lname, 27);
				}

				switch (window.userSession.user.userType) {
					case "AGENT":

						globalParam.config.isListingAgent = false;
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							if (window.userSession.user.agentOnly != 'Y') {
								globalParam.config.isListingAgent = true;
							}

							if (window.userSession.user.buddyMirrorFlag === 'Y') {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper?removeBuddyMirroring=Y" }, { "text": "Seller", "link": contextPath + "/portal/my-seller?removeBuddyMirroring=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }]));
							}
							else {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								var objAry = [{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }];
								var associatedSellers = window.userSession.user.associatedSellers;
								var sellers = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									sellers = associatedSellers.split('|');
								}
								if (sellers.length > 0) {
									var linkString = "";
									for (var i = 0; i < sellers.length; i++) {
										if (linkString.length > 0 && linkString.indexOf('[') === 0) {
											linkString += ',';
										} else if (linkString.length > 0 && linkString.indexOf('[') !== 0) {
											linkString = '[' + linkString + ',';
										}
										if (selectedSellerId == sellers[i].split('-')[0] && disableLink == "Y") {
											linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"disabled\" : true,\"link\": \"#\"}";
										} else {
											linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + sellers[i].split('-')[0] + "\"}";
										}
									}
									if (linkString.length > 0 && linkString.indexOf('[') === 0) {
										linkString += ']';
										objAry.push.apply(objAry, JSON.parse(linkString));
									} else {
										objAry.push(JSON.parse(linkString));
									}
								}
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

								// if menu options are more than 10, add scroll 
								if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }
							}

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}
						else if (window.userSession.user.agentOnly == 'Y') {
							var objAry = [{ "text": headerMenuItem.myProfile, "link": contextPath + "/portal/my-shopper#myProfile" },
							{ "text": headerMenuItem.myBids, "link": contextPath + "/portal/my-shopper#myBids" },
							{ "text": headerMenuItem.watchCommercial, "link": contextPath + "/portal/my-shopper#watchComProperties" },
							{ "text": headerMenuItem.myProxy, "link": contextPath + "/portal/my-shopper#myProxy", "enableFlag": saveListDrawerContent.header.MyHubzu.enableProxyBid },
							{ "text": headerMenuItem.purchaseProfile, "link": contextPath + "/portal/my-shopper#purchaseProfile", "menuClass": "showPurchaseProfile" },
							{ "text": headerMenuItem.myOffers, "link": contextPath + "/portal/my-shopper#myOffers" },
							{ "text": headerMenuItem.saveSearch, "link": contextPath + "/portal/my-shopper#savedSearches" },
							
							{ "text": headerMenuItem.watch, "link": contextPath + "/portal/my-shopper#watchProperties" }];

							if (typeof window.userSession !== "undefined" && window.userSession.isCfdBuyer) {
								var cfdMenuData = { "text": headerMenuItem.cfdDashboard, "link": contextPath + "/portal/my-shopper#cfdDashboard" };
								objAry.splice(5, 0, cfdMenuData);
							}
							// If preferences are enabled for this USER
							if (typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "N") {
								if (typeof window.userSession !== "undefined" && window.userSession.enabledFeatures && window.userSession.enabledFeatures.length) {
									if (window.userSession.enabledFeatures.indexOf("BUYER_PREFERENCE") > -1) {
										objAry.push({ "text": headerMenuItem.myPreferences, "link": contextPath + "/portal/my-shopper#myPreferences" });
									}
								}
							}

							objAry.push({ "text": headerMenuItem.changePass, "link": contextPath + "/portal/my-shopper#changePassword" });
							var objAryString = app.utils.buildMyHubzuLink(objAry);
							if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
								objAryString = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryString;
							}

							$(".welcome .dropdown-menu").html(objAryString);

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');

						}
						else {
							var objAry = [];
							if (headerMenuItem == undefined && typeof headerMenuItems != "undefined" && headerMenuItems != undefined) {
								var headerMenuItem = headerMenuItems || {};
							}
							if (window.userSession.user.internalUser != undefined && window.userSession.user.internalUser != '' && window.userSession.user.internalUser == 'Y') {
								objAry = [{ "text": "My Profile", "link": contextPath + "/portal/my-shopper#myProfile" },
								{ "text": "Change Password", "link": contextPath + "/portal/my-shopper#changePassword" }];
								$('[hidemenudashboard="true"]').addClass('hide');
							} else {
								objAry = [{ "text": headerMenuItem.myProfile, "link": contextPath + "/portal/my-shopper#myProfile" },
								{ "text": headerMenuItem.myBids, "link": contextPath + "/portal/my-shopper#myBids" },
								{ "text": headerMenuItem.watchCommercial, "link": contextPath + "/portal/my-shopper#watchComProperties" },
								{ "text": headerMenuItem.myProxy, "link": contextPath + "/portal/my-shopper#myProxy", "enableFlag": saveListDrawerContent.header.MyHubzu.enableProxyBid },
								{ "text": headerMenuItem.purchaseProfile, "link": contextPath + "/portal/my-shopper#purchaseProfile", "menuClass": "showPurchaseProfile" },
								{ "text": headerMenuItem.myOffers, "link": contextPath + "/portal/my-shopper#myOffers" },
								//   {"text": headerMenuItem.cfdDashboard, "link": contextPath+ "/portal/my-shopper#cfdDashboard"},
								{ "text": headerMenuItem.saveSearch, "link": contextPath + "/portal/my-shopper#savedSearches" },
								{ "text": headerMenuItem.watch, "link": contextPath + "/portal/my-shopper#watchProperties" }];
							}

							if (typeof window.userSession !== "undefined" && window.userSession.isCfdBuyer) {
								var cfdMenuData = { "text": headerMenuItem.cfdDashboard, "link": contextPath + "/portal/my-shopper#cfdDashboard" };
								objAry.splice(5, 0, cfdMenuData);
							}

							// If preferences are enabled for this USER
							if (typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "N") {
								if (typeof window.userSession !== "undefined" && window.userSession.enabledFeatures && window.userSession.enabledFeatures.length) {
									if (window.userSession.enabledFeatures.indexOf("BUYER_PREFERENCE") > -1) {
										objAry.push({ "text": headerMenuItem.myPreferences, "link": contextPath + "/portal/my-shopper#myPreferences" });
									}
								}
							}
							objAry.push({ "text": headerMenuItem.changePass, "link": contextPath + "/portal/my-shopper#changePassword" })
							//						objAry.push({"text": "Seller", "link": contextPath+ "/portal/my-seller"});create list of sellers
							var associatedSellers = window.userSession.user.associatedSellers;
							var sellers = "";
							if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
								sellers = associatedSellers.split('|');
							}
							if (sellers.length > 0) {
								var linkString = "";
								for (var i = 0; i < sellers.length; i++) {
									if (linkString.length > 0 && linkString.indexOf('[') === 0) {
										linkString += ',';
									} else if (linkString.length > 0 && linkString.indexOf('[') !== 0) {
										linkString = '[' + linkString + ',';
									}
									if (selectedSellerId == sellers[i].split('-')[0] && disableLink == "Y") {
										linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"disabled\" : true,\"link\": \"#\"}";
									} else {
										linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + sellers[i].split('-')[0] + "\"}";
									}
								}
								if (linkString.length > 0 && linkString.indexOf('[') === 0) {
									linkString += ']';
									objAry.push.apply(objAry, JSON.parse(linkString));
								} else {
									objAry.push(JSON.parse(linkString));
								}

								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

								// if menu options are more than 10, add scroll 
								if (objAry.length > 4) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }

							}

							globalParam.config.isListingAgent = true;

							var objAryString = app.utils.buildMyHubzuLink(objAry);

							if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
								objAryString = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryString;
							}

							$(".welcome .dropdown-menu").html(objAryString);

						}

						break;

					case "AUTH_USER":
						var sellerName = "";
						var selectedSellerName = "";
						var disableLink = "";
						var selectedSellerId = app.utils.param('sellerId');
						try {
							if (app.utils.param('sellerId')) {
								selectedSellerId = app.utils.param('sellerId');
								var associatedSellers = window.userSession.user.associatedSellers;
								var associatedSellersList = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									associatedSellersList = associatedSellers.split('|');
								}
								if (associatedSellersList.length > 0) {
									for (var i = 0; i < associatedSellersList.length; i++) {
										var associatedSellerId = associatedSellersList[i].split('-')[0];
										var associatedSellerName = associatedSellersList[i].split('-')[1];
										if (selectedSellerId == associatedSellerId) {
											selectedSellerName = associatedSellerName;
											disableLink = "Y";
										}
									}
								}
							} else {
								selectedSellerId = window.userSession.user.sellerId;
								selectedSellerName = window.userSession.user.sellerName;
								var location = window.location.toString().split('?')[0].split('#')[0].split('/').pop();
								if (location.match("my-seller")) {
									disableLink = "Y";
								}
							}
							if (selectedSellerName !== null && selectedSellerName !== '') {
								sellerName = '<div class="loggedInSellerName logged-in welcomeTxt">Logged in as : Seller (' + selectedSellerName + ')</div>';
								$('#loggedInAsContainer').html(sellerName);
								$('#user-welcome').css("top", "11px");
							}
						} catch (e) {
							GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:onLoggedIn|Case:AUTH_USER|" + e, null, 1);
						}
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							if (window.userSession.user.buddyMirrorFlag === 'Y') {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$('#loggedInAsContainer').html(sellerName);
								var associatedSellers = window.userSession.user.associatedSellers;
								var loggedSellerId = window.userSession.user.sellerId;
								var mirroredUserSeller1 = "";
								var mirroredUserSeller2 = "";
								var sellers = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									sellers = associatedSellers.split('|');
								}
								if (sellers.length > 0) {
									var linkString = "";
									for (var i = 0; i < sellers.length; i++) {
										linkString = linkString + "{\"text\": \"Seller (" + sellers[i].split('-')[1] + ")\", \"link\": \"" + contextPath + "/portal/my-seller?removeBuddyMirroring=Y&sellerId=" + sellers[i].split('-')[0] + "\"},";

										if (sellers[i].split('-')[0] == loggedSellerId) {
											mirroredUserSeller1 = sellers[i].split('-')[1];
											mirroredUserSeller2 = sellers[i].split('-')[0];
										}
									}

									linkString = linkString + "{\"text\": \"" + screenNameValue + " Seller (" + mirroredUserSeller1 + ")\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + mirroredUserSeller2 + "\"}";
									eval("var objAry = [" + linkString + "]");
									$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

									// remove scroll class
									$(".welcome .dropdown-menu").removeClass('scrollableDiv');

									// if menu options are more than 10, add scroll 
									if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }
								}

							} else {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$('#loggedInAsContainer').html(sellerName);
								var associatedSellers = window.userSession.user.associatedSellers;
								var sellers = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									sellers = associatedSellers.split('|');
								}
								if (sellers.length > 0) {
									var linkString = "";
									for (var i = 0; i < sellers.length; i++) {
										linkString = linkString + "{\"text\": \"Seller (" + sellers[i].split('-')[1] + ")\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + sellers[i].split('-')[0] + "\"},";
									}

									linkString = linkString.substring(0, linkString.length - 1);
									linkString = "{\"text\": \"Customer Care\", \"link\": \"" + contextPath + "/portal/cust-care?mirrorValue=Y\"}," + linkString;
									eval("var objAry = [" + linkString + "]");
									$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

									// remove scroll class
									$(".welcome .dropdown-menu").removeClass('scrollableDiv');

									// if menu options are more than 10, add scroll 
									if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }
								} else {

									$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }]));

									var objAry = [{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }];

									var objAryStrn = app.utils.buildMyHubzuLink(objAry);

									if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
										objAryStrn = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryStrn;
									}


									$(".welcome .dropdown-menu").html(objAryStrn);

									// remove scroll class
									$(".welcome .dropdown-menu").removeClass('scrollableDiv');


								}

							}
						}
						else {
							$('.user-welcome-name').html(app.utils.truncate(screenNameValue, 20));
							$('#loggedInAsContainer').html(sellerName);
							var associatedSellers = window.userSession.user.associatedSellers;
							var sellers = "";
							if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
								sellers = associatedSellers.split('|');
							}
							if (sellers.length > 0) {
								var linkString = "";
								var objAry = [];
								for (var i = 0; i < sellers.length; i++) {
									if (linkString.length > 0 && linkString.indexOf('[') === 0) {
										linkString += ',';
									} else if (linkString.length > 0 && linkString.indexOf('[') !== 0) {
										linkString = '[' + linkString + ',';
									}
									if (selectedSellerId == sellers[i].split('-')[0] && disableLink == "Y") {
										linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"disabled\" : true,\"link\": \"#\"}";
									} else {
										linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + sellers[i].split('-')[0] + "\"}";
									}
								}
								if (linkString.length > 0 && linkString.indexOf('[') === 0) {
									linkString += ']';
									objAry = JSON.parse(linkString);
								} else {
									objAry.push(JSON.parse(linkString));
								}

								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

								// remove scroll class
								$(".welcome .dropdown-menu").removeClass('scrollableDiv');

								// if menu options are more than 10, add scroll 
								if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }

							} else {
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Seller", "link": contextPath + "/portal/my-seller" }]));

								// remove scroll class
								$(".welcome .dropdown-menu").removeClass('scrollableDiv');
							}
						}

						break;
					case "PRIM_AUTH_USER":
						var sellerName = "";
						var selectedSellerName = "";
						var disableLink = "";
						var selectedSellerId = app.utils.param('sellerId');
						try {
							if (app.utils.param('sellerId')) {
								selectedSellerId = app.utils.param('sellerId');
								var associatedSellers = window.userSession.user.associatedSellers;
								var associatedSellersList = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									associatedSellersList = associatedSellers.split('|');
								}
								if (associatedSellersList.length > 0) {
									for (var i = 0; i < associatedSellersList.length; i++) {
										var associatedSellerId = associatedSellersList[i].split('-')[0];
										var associatedSellerName = associatedSellersList[i].split('-')[1];
										if (selectedSellerId == associatedSellerId) {
											selectedSellerName = associatedSellerName;
											disableLink = "Y";
										}
									}
								}
							} else {
								selectedSellerId = window.userSession.user.sellerId;
								selectedSellerName = window.userSession.user.sellerName;
								var location = window.location.toString().split('?')[0].split('#')[0].split('/').pop();
								if (location.match("my-seller")) {
									disableLink = "Y";
								}
							}
							if (selectedSellerName !== null && selectedSellerName !== '') {
								sellerName = '<div class="loggedInSellerName logged-in welcomeTxt">Logged in as : Seller (' + selectedSellerName + ')</div>';
								$('#user-welcome').css("top", "11px");
							}
						} catch (e) {
							GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:onLoggedIn|Case:PRIM_AUTH_USER|" + e, null, 1);
						}
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							if (window.userSession.user.buddyMirrorFlag === 'Y') {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + "</div>";

								var associatedSellers = window.userSession.user.associatedSellers;
								var loggedSellerId = selectedSellerId;
								var mirroredUserSeller1 = "";
								var mirroredUserSeller2 = "";
								var sellers = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									sellers = associatedSellers.split('|');
								}
								if (sellers.length > 0) {
									var linkString = "";
									for (var i = 0; i < sellers.length; i++) {
										linkString = linkString + "{\"text\": \"Seller (" + sellers[i].split('-')[1] + ")\", \"link\": \"" + contextPath + "/portal/my-seller?removeBuddyMirroring=Y&sellerId=" + sellers[i].split('-')[0] + "\"},";

										if (sellers[i].split('-')[0] == loggedSellerId) {
											mirroredUserSeller1 = sellers[i].split('-')[1];
											mirroredUserSeller2 = sellers[i].split('-')[0];
										}
									}

									linkString = linkString + "{\"text\": \"" + screenNameValue + " Seller (" + mirroredUserSeller1 + ")\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + mirroredUserSeller2 + "\"}";
									eval("var objAry = [" + linkString + "]");
									$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

									// remove scroll class
									$(".welcome .dropdown-menu").removeClass('scrollableDiv');

									// if menu options are more than 10, add scroll 
									if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }
								}

							} else {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + "</div>";
								var associatedSellers = window.userSession.user.associatedSellers;
								var sellers = "";
								if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
									sellers = associatedSellers.split('|');
								}
								if (sellers.length > 0) {
									var linkString = "";
									for (var i = 0; i < sellers.length; i++) {
										linkString = linkString + "{\"text\": \"Seller (" + sellers[i].split('-')[1] + ")\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + sellers[i].split('-')[0] + "\"},";
									}
									linkString = linkString.substring(0, linkString.length - 1);
									linkString = "{\"text\": \"Customer Care\", \"link\": \"" + contextPath + "/portal/cust-care?mirrorValue=Y\"}," + linkString;
									eval("var objAry = [" + linkString + "]");
									$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink(objAry));

									// remove scroll class
									$(".welcome .dropdown-menu").removeClass('scrollableDiv');

									// if menu options are more than 10, add scroll 
									if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }
								} else {

									$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }]));

									var objAry = [{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }];

									var objAryStrn = app.utils.buildMyHubzuLink(objAry);

									if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
										objAryStrn = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryStrn;
									}

									$(".welcome .dropdown-menu").html(objAryStrn);

									// remove scroll class
									$(".welcome .dropdown-menu").removeClass('scrollableDiv');

								}
							}
						}
						else {
							$('.user-welcome-name').html(app.utils.truncate(screenNameValue, 20));
							$('#loggedInAsContainer').html(sellerName);
							var associatedSellers = window.userSession.user.associatedSellers;

							var sellers = "";
							if (associatedSellers != undefined && associatedSellers != null && associatedSellers != "") {
								sellers = associatedSellers.split('|');
							}

							if (sellers.length > 0) {
								var linkString = "";
								var objAry = [];
								for (var i = 0; i < sellers.length; i++) {
									if (linkString.length > 0 && linkString.indexOf('[') === 0) {
										linkString += ',';
									} else if (linkString.length > 0 && linkString.indexOf('[') !== 0) {
										linkString = '[' + linkString + ',';
									}
									if (selectedSellerId == sellers[i].split('-')[0] && disableLink == "Y") {
										linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"disabled\" : true,\"link\": \"#\"}";
									} else {
										linkString = linkString + "{\"text\": \"Seller\", \"subText\": \"" + sellers[i].split('-')[1] + "\", \"link\": \"" + contextPath + "/portal/my-seller?sellerId=" + sellers[i].split('-')[0] + "\"}";
									}
								}
								if (linkString.length > 0 && linkString.indexOf('[') === 0) {
									linkString += ']';
									objAry = JSON.parse(linkString);
								} else {
									objAry.push(JSON.parse(linkString));
								}

								var objAryString = app.utils.buildMyHubzuLink(objAry);

								if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
									objAryString = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryString;
								}

								$(".welcome .dropdown-menu").html(objAryString);

								// remove scroll class
								$(".welcome .dropdown-menu").removeClass('scrollableDiv');

								// if menu options are more than 10, add scroll 
								if (objAry.length > 6) { $(".welcome .dropdown-menu").addClass('scrollableDiv'); }
							} else {

								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Seller", "link": contextPath + "/portal/my-seller" }]));

								// remove scroll class
								$(".welcome .dropdown-menu").removeClass('scrollableDiv');
							}
						}

						break;
					case "BUYR":
						if (window.userSession.user.mirrorUserFlag === 'Y') {

							sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';

							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }]));

							var objAry = [{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }];

							var objAryStrn = app.utils.buildMyHubzuLink(objAry);

							if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
								objAryStrn = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryStrn;
							}

							$(".welcome .dropdown-menu").html(objAryStrn);
							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}
						else {

							var objAry = [{ "text": headerMenuItem.myProfile, "link": contextPath + "/portal/my-shopper#myProfile" },
							{ "text": headerMenuItem.myBids, "link": contextPath + "/portal/my-shopper#myBids" },
							{ "text": headerMenuItem.watchCommercial, "link": contextPath + "/portal/my-shopper#watchComProperties" },
							{ "text": headerMenuItem.myProxy, "link": contextPath + "/portal/my-shopper#myProxy", "enableFlag": saveListDrawerContent.header.MyHubzu.enableProxyBid },
							{ "text": headerMenuItem.purchaseProfile, "link": contextPath + "/portal/my-shopper#purchaseProfile", "menuClass": "showPurchaseProfile" },
							{ "text": headerMenuItem.myOffers, "link": contextPath + "/portal/my-shopper#myOffers" },
							
							//   {"text": headerMenuItem.cfdDashboard, "link": contextPath+ "/portal/my-shopper#cfdDashboard"},
							{ "text": headerMenuItem.saveSearch, "link": contextPath + "/portal/my-shopper#savedSearches" },
							{ "text": headerMenuItem.watch, "link": contextPath + "/portal/my-shopper#watchProperties" }];

							if (typeof window.userSession !== "undefined" && window.userSession.isCfdBuyer) {
								var cfdMenuData = { "text": headerMenuItem.cfdDashboard, "link": contextPath + "/portal/my-shopper#cfdDashboard" };
								objAry.splice(5, 0, cfdMenuData);
							}
							// If preferences are enabled for this USER
							if (typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "N") {
								if (typeof window.userSession !== "undefined" && window.userSession.enabledFeatures && window.userSession.enabledFeatures.length) {
									if (window.userSession.enabledFeatures.indexOf("BUYER_PREFERENCE") > -1) {
										objAry.push({ "text": headerMenuItem.myPreferences, "link": contextPath + "/portal/my-shopper#myPreferences" });
									}
								}
							}


							objAry.push({ "text": headerMenuItem.changePass, "link": contextPath + "/portal/my-shopper#accountSettings" });

							var objAryString = app.utils.buildMyHubzuLink(objAry);

							if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
								objAryString = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li>' + objAryString;
							}

							$(".welcome .dropdown-menu").html(objAryString);

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}

						break;
					case "SIMP_USER":
						if (window.userSession.user.mirrorUserFlag === 'Y') {

							sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }]));

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}
						else {
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper" }]));

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}

						break;
					case "CUST_CARE":
						$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=N" }]));

						if (typeof newSkinning !== "undefined" && newSkinning) {
							$("#globalAlertsContainer").addClass("cust-care");
						}

						// remove scroll class
						$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						break;

					case "GETREALAGENT_AGENT_BUYER":
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
						} else {
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper" }, { "text": "Seller", "link": contextPath + "/portal/my-seller" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
						}

						// remove scroll class
						$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						break;

					case "GETREALAGENT_AGENT":
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							if (window.userSession.user.buddyMirrorFlag === 'Y') {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper?removeBuddyMirroring=Y" }, { "text": "Seller", "link": contextPath + "/portal/my-seller?removeBuddyMirroring=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }]));
							} else {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
							}
						} else {
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper" }, { "text": "Seller", "link": contextPath + "/portal/my-seller" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
						}

						// remove scroll class
						$(".welcome .dropdown-menu").removeClass('scrollableDiv');

						break;

					case "GETREALAGENT_BUYR":
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
						} else {
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
						}

						// remove scroll class
						$(".welcome .dropdown-menu").removeClass('scrollableDiv');

						break;
					case "GETREAL_AGENT":
						if (window.userSession.user.mirrorUserFlag === 'Y') {
							if (window.userSession.user.buddyMirrorFlag === 'Y') {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper?removeBuddyMirroring=Y" }, { "text": "Seller", "link": contextPath + "/portal/my-seller?removeBuddyMirroring=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": screenNameValue + " Seller Dashboard", "link": contextPath + "/portal/my-seller" }]));
							} else {
								sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
								$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }, { "text": screenNameValue + " Shopper Dashboard", "link": contextPath + "/portal/my-shopper" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
							}
						} else {
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "My Account", "link": contextPath + "/portal/my-shopper" }, { "text": "Listing Agent", "link": contextPath + "/portal/agent-dashboard" }]));
						}

						// remove scroll class
						$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						break;

					case "SIG_SELLER":
						if (window.userSession.user.mirrorUserFlag === 'Y') {

							sellerName = '<div class="loggedInWelcome logged-in welcomeTxt">You are mirroring ' + screenNameValue + '</div>';
							$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([{ "text": "Customer Care", "link": contextPath + "/portal/cust-care?mirrorValue=Y" }]));

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}
						else {
							if (window.userSession.smallSellerUserRole == 'AUTH_USER' || window.userSession.smallSellerUserRole == 'AGENT' || window.userSession.smallSellerUserRole == 'PRIM_AUTH_USER') {
								var objAryString = '<li class="my-shopper"><a href="javascript:void(0)" titletext="Small Seller Dashboard" hreflink="/sell-a-home-in-an-online-auction/seller/myProfile" class="textFade headerMenuClick" hashname="smallSellerDashboard"><div title="Small Seller Dashboard">' + headerMenuItem.smallSellerDashboard + '</div></a></li><li class="divider divider-my-shopper"></li><li class="sign-out-btn-li"><a href="#" id="sign-out-btn" class="textFade">Sign Out</a></li>';
							}

							$(".welcome .dropdown-menu").html(objAryString);

							// remove scroll class
							$(".welcome .dropdown-menu").removeClass('scrollableDiv');
						}

						break;

					default:
						$(".welcome .dropdown-menu").html(app.utils.buildMyHubzuLink([]));
						// remove scroll class
						$(".welcome .dropdown-menu").removeClass('scrollableDiv');
				}

				if (globalParam.config.isElite == 'Y') {
					var eliteLogo = '<li class="my-shopper elite-logo"><img src="' + contextPath + '/www/images/elite_logo.png" width="40" height="40" /><span>' + fname + ' ' + lname + '</span></li>';
					$(".welcome .dropdown-menu").prepend(eliteLogo);
					$(".eliteLogo span").text(fname + ' ' + lname);
					$(".eliteLogo").removeClass("hide");
					if (window.location.href.indexOf('#myBids') > -1 || window.location.href.indexOf('#myOffers') > -1 || window.location.href.indexOf('#myMessages') > -1) {
						$("#navMenu-custom").addClass("mobileFiltersEliteWidth");
					} else {
						$("#navMenu-custom").removeClass("mobileFiltersEliteWidth");
					}
				} else {
					$("#navMenu-custom").removeClass("mobileFiltersEliteWidth");
					var userDetails = '<li class="my-shopper userDetailsDisp hidden-md hidden-sm hidden-lg"><div class="userFN">' + fname + ' ' + lname + '</div><span class="userEmail">' + uEmail + '</span></li>';
					$(".welcome .dropdown-menu").prepend(userDetails);
					// $(".eliteLogo span").text(window.userSession.user.userFirstName+' '+window.userSession.user.userLastName);
					$(".eliteLogo").addClass("hide");
					$('.eliteMobile').removeClass('col-xs-6').addClass('col-xs-12');
				}

				$("#m-sign-out-btn,#sign-out-btn").click(function (e) {
					GAUtils.eventTracking.trackevent("New-Sign", ' sign-out | visitorId :' + $.cookie('visitorId') + ' | UID :' + window.userSession.userId, "Sign Out");
					Utils.signout(e);
				});

				$(".user-welcome-name, .m-user-welcome-name").click(function (e) {
					GAUtils.eventTracking.trackevent(window.location.href, " My Account", window.userSession.user.realbidUserId + "|" + $.cookie('visitorId'));
				});

				$(".welcome .dropdown-menu").removeAttr('style');
				if (isMobile) {
					if (dashboardContent !== undefined && dashboardContent.header != undefined && (dashboardContent.header.enableMobileLink) !== undefined && (dashboardContent.header.enableMobileLink).toUpperCase() == 'N') {
						$("#welcome-drop-down .my-shopper, #welcome-drop-down .divider-my-shopper, #welcome-drop-down .my-seller, #welcome-drop-down .divider-my-seller").addClass('hidden-xs');
						$("header-welcome-drop-down .my-shopper, header-welcome-drop-down .divider-my-shopper, header-welcome-drop-down .my-seller, header-welcome-drop-down .divider-my-seller").addClass('hidden-xs');
					} else {
						$("#welcome-drop-down .my-seller, #welcome-drop-down .divider-my-seller, .header-welcome-drop-down .my-seller, .header-welcome-drop-down .divider-my-seller").addClass('hidden-xs');
						$("#welcome-drop-down .my-shopper, #welcome-drop-down .divider-my-shopper, .header-welcome-drop-down .my-shopper, .header-welcome-drop-down .divider-my-shopper").removeClass('hidden-xs');
					}
					$(".menuActive").click(function () {
						$('.mobileMyaccountTab').removeClass('open');
						$("body").removeClass('modal-open');
						$('.mobile-overlay-mask').fadeOut();
					});
				}
				$(".headerMenuClick").click(function () {
					var urlHref = $(this).attr('hrefLink'),
						userDetails = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId');
					GAUtils.eventTracking.processLinkAndTrackEvent({ linkValue: urlHref, appendContextPath: false }, 'My Account Click', $(this).attr('titleText'), userDetails, "");
				});
				/*if(newSkinning){
					$('#headerHeart').removeClass('hide').removeClass('hide-item');
					$('#mobFavoriteItemList').removeClass('hide').removeClass('hide-item');
				}*/
				var postCallBackData = {};
				postCallBackData.postCallBackOptions = '';
				//postCallBackData.postCallBackOptions.routerObj.switchWorkFlagStage(postCallBackData);
				postCallBackData.experimentName = GOOGLE_OPTIMIZE_EXP.webExperiments['NEW_CIF_TEMPLATE'].experimentName;
				postCallBackData.callBackFunc = Utils.enableRequiredFeatures;
				postCallBackData.enabledFeatures = [];
				postCallBackData.enabledFeatures.push(GOOGLE_OPTIMIZE_EXP.webExperiments['NEW_CIF_TEMPLATE']);
				postCallBackData.enabledFeatures.push(GOOGLE_OPTIMIZE_EXP.webExperiments['NEW_REPEAT_AUTO_BID']);

				Utils.setMultiGoogleOptimizeExperiment(postCallBackData);

			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:onLoggedIn|" + e, null, 1);
			}

		},
		Utils.enableRequiredFeatures = function (callbackParams) {
			var cifVariationId = typeof _.where(callbackParams.enabledFeatures, { name: 'NEW_CIF_TEMPLATE' })[0] != 'undefined' ? _.where(callbackParams.enabledFeatures, { name: 'NEW_CIF_TEMPLATE' })[0].variationId : 0;
			var autoBidVariationId = typeof _.where(callbackParams.enabledFeatures, { name: 'NEW_REPEAT_AUTO_BID' })[0] != 'undefined' ? _.where(callbackParams.enabledFeatures, { name: 'NEW_REPEAT_AUTO_BID' })[0].variationId : 0;
			console.log(callbackParams);
			if (autoBidVariationId == 1 && typeof window.globalAlertView != 'undefined') {
				window.globalAlertView.showAutoBid = true;

			}
			if (cifVariationId == 0 || userSession.userStatus == 'PROFILE') {
				$('.showPurchaseProfile').remove();
			}
		},
		Utils.signout = function (event) {
			try {

				event.preventDefault();
				//not sure of service so have this for now
				$.post(contextPath + '/portal/logout', function (response) {
					$('#sellingBonus').hide();
					$('.logged-in').hide();
					$('#ajax-loader-mask').hide();
					$('.logged-out').show();
					$(".welcome .dropdown-menu").hide();
					$('.mobileMyaccountTab').removeClass('open');
					$("body").removeClass('modal-open');
					$('.mobile-overlay-mask').fadeOut();
					$(".pdp-save-icon").removeClass('Y').removeClass('saved').attr('isWatched', 'N');
					$('.my-account-icon').addClass('account-icon').removeClass('active').removeClass('my-account-icon');
					$('.account-icon span.ic-label').html((typeof newSkinning !== "undefined" && newSkinning) ? "Sign In" : 'Account');
					$("#globalAlertsContainer").removeClass("cust-care");

					$(".header-nav-links .signin-link, .hamburger-menu .hamburger-user-mobile-actions, .hamburger-menu .support-link, .register-login-block, .hm-login-actions").removeClass('signed');
					$(".header-nav-links .register-link").removeClass('hide');
					if (typeof newSkinning !== "undefined" && newSkinning) {
						$('#headerHeart,#mobFavoriteItemList').addClass('hide-item');
					}

					// Update save search button status;
					if (typeof golbalSRPView !== "undefined" && isEnableNewSrp && isEnableNewSrp == 'Y') {
						golbalSRPView.updateSaveSearchElement({ status: 'deleted' });
					} else if (typeof golbalSRPView !== "undefined" && golbalSRPView.filterView) {
						golbalSRPView.filterView.updateSaveSearchElement({ status: 'deleted' });
					}

					if ($(".proxy-bid-button").length)
						$(".proxy-bid-button").html('Register to Proxy Bid').attr('data-action', 'Register to Proxy Bid');
					localStorage.removeItem('showPlaceBid');
					localStorage.removeItem('RecentSearchList');
					Utils.uncheckWatchedProp();
					try {
						ga('set', 'dimension11', null);
						if (typeof sessionView != 'undefined') {
							sessionView.clearSessionTimeout();
						}
						// Notify Native app with message 
						if (window.notifyNativeApp) {
							setTimeout(function () {
								var userPostData = {
									"uuid": window.userSession.userId,
									"message": "PostMessageWebViewSignout"
								};
								window.notifyNativeApp(JSON.stringify(userPostData), '*');
							}, 1000);
						}

						Utils.setCookieDetails('loggedInEmailActive', "", '-1');
						Utils.setCookieDetails('loggedInFirstName', "", '-1');
						Utils.setCookieDetails('loggedInLastName', "", '-1');
						Utils.setCookieDetails('loggedInPhone', "", '-1');
						Utils.setCookieDetails('userId', "", '-1');

						if ($('#buttonPlaceHolder #bidOrBackUpBidButton a').length) {
							var clickEventPlaceBidLogout = $('#buttonPlaceHolder #bidOrBackUpBidButton a').attr("onclick").replace("Place Backup Bid", "Register - Place Backup Bid").replace("Place Bid", "Register - Place Bid");
							$('#buttonPlaceHolder #bidOrBackUpBidButton a').attr("onclick", clickEventPlaceBidLogout);
						}
						var accessToken = $.cookie('_fcat') || '',
							refreshToken = $.cookie('_fcrt') || '',
							oneday = new Date(Date.now() + 86400e3);
						if (accessToken)
							Utils.setSecureCookieDetails('_fcat', '', oneday);

						if (refreshToken)
							Utils.setSecureCookieDetails('_fcrt', '', oneday);
						// Clear web engage form
						if (typeof enableAutoPopulateFeedback !== "undefined" && enableAutoPopulateFeedback === true && typeof webengage !== "undefined") {
							webengage.feedback.options('formData', [
								{
									'name': 'email',
									'value': ''
								}, {
									'name': 'name',
									'value': '',
								}, {
									'name': 'Phone',
									'value': '',
								}
							]);
							webengage.feedback.render();
						}

					} catch (e) {
						GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:signout|" + e, null, 1);
					}
					if ($('#buttonPlaceHolder').css('display') == 'none') {
						$('#bidDivId .logged-in').hide();
						$('#bidDivId .logged-out').show();
					} else {
						$('#bidDivId .logged-in').hide();
						$('#bidDivId .logged-out').hide();
					}
					if ($('#interimMsg').is(':visible')) {
						$('.logged-out.placcBidCenter').hide();
					}
					var location = window.location.toString();

					if (location.indexOf('create-listing') != -1 ||
						location.indexOf('agent-dashboard') != -1 ||
						location.indexOf('edit-listing') != -1 ||
						location.indexOf('reListing') != -1 ||
						location.indexOf('sourcePropertySearch') != -1 ||
						location.indexOf('unsubscribe') != -1 ||
						location.indexOf('my-listings') != -1 ||
						location.indexOf('messages') != -1 ||
						location.indexOf('my-profile') != -1 ||
						location.indexOf('createlisting-help') != -1 ||
						location.indexOf('my-seller') != -1 ||
						location.indexOf('my-shopper') != -1 ||
						location.indexOf('hubzu-auctions-work') != -1 ||
						location.indexOf('changetoauction') != -1 ||
						location.indexOf('create-account') != -1 ||
						location.indexOf('user-profile') != -1 ||
						location.indexOf('cust-care') != -1 ||
						location.indexOf('changePwd') != -1 ||
						location.indexOf('getChangeExpiredPasswordPage') != -1 ||
						location.indexOf('/ci') != -1

					) {
						Utils.redirect("/");
					} else {
						$('.user-welcome').html('');
						$('#register-button').show();
						$('#sign-in-reg-separator').show();
						$('#sign-in-nav').show();
						$('#my-hubzu-button').hide();
						$('#sign-out-separator').hide();
						$('#sign-out-button').hide();
						$('.welcome .dropdown-menu').html('');
						$("#help-banner-signup").addClass("cursor_hand active");
						var myAccountPageArray = [];
						if (typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.header != 'undefined' && typeof saveListDrawerContent.header.MyHubzu != 'undefined' && typeof saveListDrawerContent.header.MyHubzu.myAccountPages != 'undefined') {
							myAccountPageArray = (saveListDrawerContent.header.MyHubzu.myAccountPages).split(',');

						}
						var isMyAccountPage = false;
						$.each(myAccountPageArray, function (index, value) {
							if ((window.location.href).indexOf(value) > -1 && !isMyAccountPage) {
								isMyAccountPage = true;
							}
						});
						if (isMyAccountPage) {
							$('.openMyAccountMenu').removeClass('hide');
							$('.openMyAccountMenu').removeClass('hide').addClass('mobile-back-btn').removeClass('openMyAccountMenu').removeClass('closeBuyerDashboard');

						}
						if (location.match('agents-landing')) {
							app.utils.updateAgentLandingContent();
						}

					}

					delete window.userSession.user;

					var url = window.location.href;
					if (url.indexOf('/property/') > -1) {
						golbalPDPView.afterLogout();
					}

					// in SRP, update save search changes in case of new filters
					if (url.indexOf('/searchResult/') > -1) {
						if ($('.save-search-icon').length) {
							if (isEnableNewSrp && isEnableNewSrp == 'Y') {
								$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#118BF4" });
								$('.save-search-icon').removeClass('search-results-saved').animate(200, function () {
									$(this).find(".ss-title").text('Save Search');
								});
							} else {
								$('.save-search-icon').removeClass('search-results-saved').animate({ "width": "107" }, 200, function () {
									$(this).find(".ss-title").text('Save Search');
								});
								$('.save-search-icon').find(".inner-outer-layer").css({ "fill": "#f9f9f9" });
							}
						}
					}

					// hide preferences if any
					if ($(".add-preferences").length)
						$(".add-preferences").addClass('hide');

					window.userSession = {
						userId: null,
						userStatus: null,
						idologyStatus: null,
						userType: null,
						enabledFeatures: null,
						optInEnabled: false,
						smsOptedFlag: null,
						phone: null,
						smallSellerUserRole: '',
						isCfdBuyer: null
					};

					if (window.globalAlertView && Utils.isGlobalAlertsEnabled()) {
						localStorage.removeItem("showIdologyPopup");

						globalAlertView.render();
						globalAlertView.showDefaultNotification({});

						//Check if firebase session is available and Signout firebase
						if (typeof firebase != 'undefined' && firebase && firebase.apps.length) {
							globalAlertView.signOutFirebase({ isUserSignedOut: true });
						}
					}

					globalParam.config.isLoggedin = false;
					if (typeof golbalSaveListDrawerView != 'undefined') {
						golbalSaveListDrawerView.loadSaveListDrawer(false);

					}

					/**
					 * New home page callbacks - after login ceremonies 
					 */
					if (typeof window.htimestamp === "object" && window.htimestamp.home) {
						window.htimestamp.home.updateUserComponnetsOnLogout();
					}
					if (typeof window.htimestamp === "object" && window.htimestamp.srpPage) {
						window.htimestamp.srpPage.updateUserComponnetsOnLogout();
					}
				});


				globalParam.config.noOfVisitAttempts = 1;

				return;
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:signout|" + e, null, 1);
			}
		};
	Utils.googleAutoSuggest = { enabled: true };
	Utils.googleAutoSuggest.initGoogleAutoSuggest = function () {

	};



	Utils.invokeAutoRotateTab = {};
	Utils.invokeAutoRotateTab.initilize = function (tabid, autorotateInterval, tabReinitilizeTime) {
		// Tab-Pane change function
		var tabChange = function () {
			var tabs = $(tabid + " > li");
			var active = tabs.filter('.active');
			var next = active.next('li').length ? active.next('li').find('img') : tabs.filter(':first-child').find('img');
			// Use the Bootsrap tab show method
			next.tab('show')
		};
		// Tab Cycle function
		var tabCycle = setInterval(tabChange, autorotateInterval);
		// Tab click event handler
		(function () {
			$(tabid + ' img').click(function (e) {
				e.preventDefault();
				// Stop the cycle
				clearInterval(tabCycle);
				// Show the clicked tabs associated tab-pane
				$(this).tab('show');
				// Start the cycle again in a predefined amount of time
				setTimeout(function () {
					tabCycle = setInterval(tabChange, autorotateInterval)
				}, tabReinitilizeTime);
			});
		})($);
	};

	Utils.autoRotateTicker = {};
	Utils.autoRotateTicker.initilize = function (containerId, speed, reInitlizeTime) {
		$(containerId).show();
		$(containerId).inewsticker({
			speed: speed,
			effect: 'fade',
			dir: 'ltr',
			delay_after: reInitlizeTime
		});
	};


	Utils.menuHover = {};
	Utils.menuHover.initilize = function () {
		if ($(window).width() > 767) {
			$(".dropdown-toggle,.dropdown-menu").on("mouseenter", function () {
				$(".dropdown-toggle").parent().removeClass('open');

				$(this).parent().toggleClass('open');

			}).on("mouseleave", function () {
				$(".dropdown-toggle").parent().removeClass('open');

			});
		}
		$(".dropdown-toggle,.dropdown-menu").on("click", function () {

			if ($(this).find("span.caret").hasClass("support")) {
				$(".dropdown-toggle,.dropdown-menu").find("span.buyahome").removeClass('caret-up');
			} else {
				$(".dropdown-toggle,.dropdown-menu").find("span.support").removeClass('caret-up');
			}

			if ($(this).find("span.caret").hasClass("caret-up")) {
				$(this).find("span.caret").removeClass('caret-up');
			} else {
				$(this).find("span.caret").addClass('caret-up');
			}

		})

		$(".dropdown-toggle").bind('hover', function (event) {
			var $this = $(this).next("ul");
			var width = $this.width();
			var centerX = width / 2;
			var $element = $(this).width();
			$element = $element / 2;
			$(".dropdown-menu").css("left", ($element - centerX));
		});
	};

	Utils.slider = {};
	Utils.slider.initilize = function (containerid, slidercontainerclass) {
		if ($(window).width() < 768) {
			this.setMobileSlider(containerid, slidercontainerclass, 2);
			this.setMobileSlider('recentlyViewed', 'recentList', 2);
		} else {
			this.setMobileSlider('recommentaions', 'col-lg-4', 3);
			this.setMobileSlider('recentlyViewed', 'recentList', 6);
		}
	};

	Utils.slider.setMobileSlider = function (containerid, slidercontainerclass, slideCount) {
		var outhtml = '<div class="item active"><div class="row">';
		var loopVal = 1;
		var columnselector = $('#' + containerid + ' .' + slidercontainerclass);
		var selecLength = columnselector.length;

		columnselector.each(function () {
			outhtml += $("<div />").append($(this).clone()).html();

			if (loopVal % slideCount == 0 && loopVal < selecLength) {
				outhtml += '</div></div><div class="item"><div class="row">';
			}

			loopVal++;

		});
		outhtml += '</div></div>';
		$('#' + containerid + ' .carousel-inner').html(outhtml);
		$("#" + containerid).carousel();
	};

	Utils.generateUrl = {};
	Utils.generateUrl.home = function () {
		var fullUrl = contextPath + "/home";
		return fullUrl;
	};
	Utils.generateUrl.srp = function (searchBy, filters, isLux) {
		var fullUrl = contextPath;
		if (isLux) {
			fullUrl += "/portal/luxury-homes-for-sale?searchBy=" + searchBy;
		} else {
			fullUrl += "/searchResult?searchBy=" + searchBy;
		}
		if (app.utils.param('viewType') != '' && app.utils.param('viewType') != undefined) {
			fullUrl += '&viewType=' + app.utils.param('viewType');
		}
		if (app.utils.param('offers') != '' && app.utils.param('offers') != undefined) {
			fullUrl += '&offers=' + app.utils.param('offers');
		}
		if (app.utils.param('category') != '' && app.utils.param('category') != undefined) {
			fullUrl += '&category=' + app.utils.param('category');
		}
		if (app.utils.param('listingType') != '' && app.utils.param('listingType') != undefined) {
			fullUrl += '&listingType=' + app.utils.param('listingType');
		}
		if (app.utils.param('priceRange') != '' && app.utils.param('priceRange') != undefined) {
			fullUrl += '&priceRange=' + app.utils.param('priceRange');
		}
		return fullUrl;
	};
	Utils.generateUrl.pdp = function (propertyUrl) {
		var fullUrl = contextPath + "/property/" + propertyUrl;
		return fullUrl;
	};
	Utils.navigate = {}
	Utils.navigate.srp = function (searchParam, isLux) {
		$(".searchInput").attr('value', '');
		var thisUrl = Utils.generateUrl.srp(searchParam, isLux);
		window.location.href = thisUrl;
	};

	Utils.navigate.pdp = function (propertyUrl) {
		$(".searchInput").attr('value', '');
		var thisUrl = Utils.generateUrl.pdp(propertyUrl);
		window.location.href = thisUrl;
	}

	Utils.RecentSearch = {};
	Utils.RecentSearch.initialize = function () {
		try {
			var recentSearch = localStorage.getItem("RecentSearchList");
		} catch (e) { }
		if (recentSearch) {
			Utils.RecentSearch.update();
		} else {
			Utils.RecentSearch.load();
		}

	};
	Utils.RecentSearch.save = function (data, url, defaultSearchText, searchURI) {
		var newRecent = {
			id: url,
			value: data,
			flag: false,
			searchURI: searchURI,
			timeStamp: (new Date()).getTime(),
			defaultSearchText: defaultSearchText
		};
		try {
			var recentSearch = localStorage.getItem("RecentSearchList");
			recentSearch = JSON.parse(recentSearch);
		} catch (e) { }
		//  if(recentSearch && recentSearch.cookieElements && location.search.indexOf('eventId') == -1) {

		if (recentSearch && recentSearch.cookieElements) {
			recentSearch.cookieElements.unshift(newRecent);
			recentSearch = JSON.stringify(recentSearch);
			try {
				localStorage.setItem("RecentSearchList", recentSearch);
			} catch (e) { }
		}
	};
	Utils.RecentSearch.getSearchObjectFromQuery = function (query) {
		return { searchBy: query };
	}

	Utils.RecentSearch.load = function () {
		var dateParam = new Date().getTime();
		$.post(contextPath + '/portal/userRecentSearch?' + dateParam, function (searches) {
			var validRecents = JSON.parse(searches);
			Utils.RecentSearch.getValidRecents(validRecents);
			try {
				localStorage.setItem("RecentSearchList", JSON.stringify(validRecents));
			} catch (e) { }
			Utils.RecentSearch.getRecentSearchForHome();
			console.log("getRecentSearchForHome load");
		});

	};

	Utils.RecentSearch.getValidRecents = function (validRecents) {
		var cookies = validRecents.cookieElements;
		var validCookies = [];
		var isDuplicate = false;
		for (var outerCount = 0; outerCount < cookies.length; outerCount++) {
			isDuplicate = false;
			for (var innerCount = 0; innerCount < outerCount; innerCount++) {
				if ((typeof cookies[outerCount].value != 'undefined' && typeof cookies[innerCount].value != 'undefined') && cookies[outerCount].value.trim().toUpperCase() === cookies[innerCount].value.trim().toUpperCase()) {
					isDuplicate = true;
				}
			}
			if (!isDuplicate) {
				validCookies.push(cookies[outerCount]);
			}
		}
		validRecents.cookieElements = validCookies;
	},

		/**
		 * 
		 * @param {*} setDimensionParameters 
		 * experimentId : Optimize ExperimentId
		 * callBackFunc : Call Back function after the Variation is set
		 */
		Utils.setGoogleOptimizeExperiment = function (callBackOptions) {
			var experimentName = callBackOptions.experimentName;
			Utils.optimizeCallBack = callBackOptions || '';
			if (typeof GOOGLE_OPTIMIZE_EXP.webExperiments != 'undefined' && GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName] != 'undefined' && GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].experimentKey != 'undefined') {
				var experimentId = GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].experimentKey;

				if (GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].isActive == 'true') {
					if (typeof GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].whiteListAll != 'undefined' && GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].whiteListAll == 'true' && typeof GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].activeVariationId != 'undefined') {
						//Utils.decideVariation(0,experimentId);
						Utils.decideVariation(parseInt(GOOGLE_OPTIMIZE_EXP.webExperiments[experimentName].activeVariationId), experimentId);
					}
					else if ((typeof google_optimize == 'undefined') || (google_optimize && typeof google_optimize.get(experimentId) == 'undefined') || (google_optimize && typeof google_optimize.get(experimentId) == 0)) {
						//Utils.decideVariation(0,experimentId);
						Utils.decideVariation(0, experimentId);
					} else {
						gtag('event', 'optimize.callback', {
							name: experimentId,
							callback: Utils.decideVariation,
						});
					}
				} else {
					Utils.decideVariation(0, experimentId);
				}
			}


		},
		Utils.setMultiGoogleOptimizeExperiment = function (callBackOptions) {
			Utils.enabledFeatures = [];
			Utils.optimizeCallBack = callBackOptions || '';
			var enabledModules = callBackOptions.enabledFeatures;
			Utils.enabledModule = enabledModules;
			//	gtag('event', 'optimize.callback', {
			//		callback: Utils.decideMultiExpVariation,
			//	});
			$.when(
				$.each(enabledModules, function (key, value) {
					console.log(key + "=======" + value);
					if (typeof value != undefined && value != '') {
						var callBackOptions = {};
						callBackOptions.experimentName = value.experimentName;
						Utils.setGoogleOptimizeExperiment(callBackOptions);
					}

				})
			).done(function () {
				console.log("Enabled Feature");
				console.log(Utils.enabledFeatures);
				callBackOptions.enabledFeatures = Utils.enabledFeatures;
				callBackOptions.callBackFunc(callBackOptions);
			});

		},

		/**
		 * 
		 * @param {*} setDimensionParameters 
		 * experimentId : Optimize ExperimentId
		 * callBackFunc : Call Back function after the Variation is set
		 */
		Utils.decideVariation = function (value, name) {
			/*var  callBackOptions = Utils.optimizeCallBack || {};
			callBackOptions.variationId = value;
			callBackOptions.callBackFunc(callBackOptions);*/
			var currModule = _.where(Utils.enabledModule, { experimentKey: name })[0];
			var enableFeature = [];
			enableFeature['name'] = currModule['experimentName']
			enableFeature['variationId'] = value;
			Utils.enabledFeatures.push(enableFeature);

		},
		/**
		 * 
		 * @param {*} setDimensionParameters 
		 * experimentId : Optimize ExperimentId
		 * callBackFunc : Call Back function after the Variation is set
		 */
		Utils.decideMultiExpVariation = function (value, name) {
			/*var  callBackOptions = Utils.optimizeCallBack || {};
			callBackOptions.variationId = value;
			callBackOptions.callBackFunc(callBackOptions);*/
			var enableFeature = [];

			var currModule = _.where(Utils.enabledModule, { experimentKey: name })[0];
			enableFeature['name'] = currModule['experimentName']
			enableFeature['variationId'] = value;

			if (typeof currModule != 'undefined' && currModule.experimentKey != 'undefined' && currModule.isActive == 'true') {
				var experimentId = currModule.experimentKey;
				if (typeof currModule.whiteListAll != 'undefined' && currModule.whiteListAll == 'true' && typeof currModule.activeVariationId != 'undefined') {
					//Utils.decideVariation(0,experimentId);
					enableFeature['variationId'] = parseInt(currModule.activeVariationId);
				}
				else if ((typeof google_optimize == 'undefined') || (google_optimize && typeof google_optimize.get(experimentId) == 'undefined') || (google_optimize && typeof google_optimize.get(experimentId) == 0)) {
					//Utils.decideVariation(0,experimentId);
					enableFeature['variationId'] = 0;
				}
			} else {
				enableFeature['variationId'] = 0;
			}
			Utils.enabledFeatures.push(enableFeature);

		},
		/**
		 * 
		 * @param {*} setDimensionParameters 
		 * experimentType : onLoad/onDemand
		 * activateExperiment : Experiment Name to be activated
		 * 
		 */
		Utils.setDimensionDetails = function (setDimensionParameters) {
			try {
				var queryParam = "experimentType=" + setDimensionParameters.experimentType,
					userId = '';
				if (window.userSession != 'undefined' && window.userSession != undefined && window.userSession.userId != 'undefined' && window.userSession.userId != null && window.userSession.userId != 'null') {
					var userId = window.userSession.userId;
				}
				if (setDimensionParameters != undefined && $.trim(setDimensionParameters.experimentType) != undefined && setDimensionParameters.experimentType != '' && $.trim(setDimensionParameters.experimentType) == 'onLogin') {
					queryParam += '&userId=' + userId;
				}
				$.get(contextPath + "/portal/api/getExperiments?" + queryParam, function (response) {
					try {
						if (response) {
							$.each(response.data, function (x, data) {
								if (data.isActive == "true" && data.variation != '' && data.variation != 'null' && data.variation != null) {
									ga('set', 'dimension' + data.experimentIndex, data.variation);
									window._mfq.push(["setVariable", data.experimentKey, data.variation]);
								}
							});
						}
					} catch (error) {
						GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:setDimensionDetails - API /getExperiments|" + error, null, 1);
					}
				});

			} catch (error) {
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:setDimensionDetails|" + error, null, 1);
			}
		};

	Utils.RecentSearch.update = function () {
		try {
			var recentSearch = localStorage.getItem("RecentSearchList");
			recentSearch = JSON.parse(recentSearch);
		} catch (e) { }
		if (recentSearch && recentSearch.cookieElements && recentSearch.cookieElements.length > 0) {
			var validCounts = 0, updateLater = false;
			var validUpdates = [];

			for (var count = 0; count < recentSearch.cookieElements.length; count = count + 1) {
				if (!recentSearch.cookieElements[count].flag) {
					var search = { searchBy: recentSearch.cookieElements[count].value, baseSearchUrl: recentSearch.cookieElements[count].id, searchURI: recentSearch.cookieElements[count].searchURI };

					if (window.userSession.user && window.userSession.user.loggedIn) {
						validCounts++;
						updateLater = true;
						validUpdates.push(search);
					}
				}
			}
			if (updateLater) {
				Utils.RecentSearch.relayUpdate(validUpdates, Utils.RecentSearch.load);
			} else if ((recentSearch.cookieElements.length === 0 || !updateLater) && window.userSession.user && window.userSession.user.loggedIn) {
				Utils.RecentSearch.load();
			} else {
				Utils.RecentSearch.getRecentSearchForHome();
				console.log("getRecentSearchForHome update");
			}
		} else {
			if (window.userSession.user && window.userSession.user.loggedIn) {
				Utils.RecentSearch.load();
			} else {
				Utils.RecentSearch.getRecentSearchForHome();
				console.log("getRecentSearchForHome else update");
			}

		}
	};
	Utils.RecentSearch.relayUpdate = function (searchByArr, callback) {
		var search = searchByArr.pop();



		$.post(contextPath + '/portal/saveRecentSearch', search, function (response) {
			if (searchByArr.length === 0) {
				callback();
			} else {
				Utils.RecentSearch.relayUpdate(searchByArr, callback);
			}
		});
	};
	Utils.RecentSearch.getRecentSearchForHome = function () {
		if (typeof window.htimestamp === "object" && window.htimestamp.home) {
			return;
		}
		var cUrl = window.location.href;
		var options = {}, cmsContent = typeof saveListDrawerContent !== 'undefined' ? saveListDrawerContent : {}, recentSearchUrl;
		try {
			var recentSearch = localStorage.getItem("RecentSearchList");
			recentSearch = JSON.parse(recentSearch);
		} catch (e) { }
		var recentSearchList = "", temp = 1;
		if (recentSearch != null && recentSearch != undefined && recentSearch != '' && recentSearch.cookieElements != null && recentSearch.cookieElements != undefined && recentSearch.cookieElements != '') {
			Utils.RecentSearch.getValidRecents(recentSearch);

			if ($(window).width() < 768) {
				recentSearch.characterLimit = parseInt(recentSearch.mobileCharacterLimit);
				recentSearch.showLimit = recentSearch.mobileShowLimit;
			}
			$.each(recentSearch.cookieElements, function (index, obj) {

				if (temp <= recentSearch.showLimit) {

					var value = (obj.value).substring(0, recentSearch.characterLimit);
					if ((obj.value).length >= recentSearch.characterLimit) {
						value = value + "...";
					}
					if (index != 0) {

						recentSearchList += "|";
					} else {
						var lable = $("#recentSearchData").html();
						$("#recentSearchData").html(lable + _.escape(value));
						$(".propertiesIn").show();
					}

					if (typeof recentSearchSelectedValue !== "undefined" && recentSearchSelectedValue === '') {
						recentSearchSelectedValue = _.escape(obj.defaultSearchText);
					}

					// if(backToSearchUrl === '' && (obj.id).indexOf('/property/')==-1 ){
					if (!backToSearchUrl && (obj.id).indexOf('/property/') == -1) {
						backToSearchUrl = (obj.id);
					}


					if (cUrl.indexOf('/property/') == -1 && cUrl.indexOf("/searchResult") == -1 && cUrl.indexOf("/landingPage") == -1) {
						if (!(typeof newSkinning != "undefined" && newSkinning)) {

							$(".searchInput").val(recentSearchSelectedValue);
						}
						//recentSearchList += "<a href='javascript:void(0);' data-url='"+_.escape(obj.id)+"' data-url-href='"+_.escape(obj.searchURI)+"' class='textFade recentSearchLink' data-hubzu-searchBy='"+_.escape(recentSearchSelectedValue)+"'  title='"+_.escape(obj.value)+" Search'>"+ _.escape(value) +"</a>";

					} else if (cUrl.indexOf("/searchResult") >= 0 && cUrl.indexOf("srchBtnClk=") >= 0) {
						if (!(typeof newSkinning != "undefined" && newSkinning)) {
							$(".searchInput").val(recentSearchSelectedValue);
						}

						//recentSearchList += "<a href='javascript:void(0);' data-url='"+_.escape(obj.id)+"' data-url-href='"+_.escape(obj.searchURI)+"' class='textFade recentSearchLink' data-hubzu-searchBy='"+_.escape(obj.value)+"'  title='"+_.escape(obj.value)+" Search'>"+ _.escape(value) +"</a>";

					}

					recentSearchList += "<a href='javascript:void(0);' data-default-txt = '" + _.escape(obj.defaultSearchText) + "' data-url='" + _.escape(obj.id) + "' data-url-href='" + _.escape(obj.searchURI) + "' class='textFade recentSearchLink' data-hubzu-searchBy='" + _.escape(obj.value) + "'  title='" + _.escape(obj.value) + " Search'>" + _.escape(value) + "</a>";
				}
				temp++;
			});

			if (backToSearchUrl !== '') {
				$(".backtosearch").attr("href", contextPath + backToSearchUrl);
			} else {
				//if recent search is pdp 
				options.type = 'PDP';
				Utils.RecentSearch.getStateBasedOnIP(options);
			}
			if (window.globalHomeView) {
				globalHomeView.populatePropertyCarousel();
			}
			$("#recentSearch").html(recentSearchList);
			$(".recentSearch").show();

			$(".recentSearchLink").on("click", function (e) {
				var queryVal = $(e.target).attr("data-hubzu-searchBy"),
					defaultText = $(e.target).attr("data-default-txt"),
					responseURL = $(e.target).attr("data-url"),
					responseURLHref = $(e.target).attr("data-url-href"),
					gaText = 'visitorId: ' + $.cookie('visitorId') || 'visitorId: ';

				if (window.userSession != 'undefined' && window.userSession != undefined && window.userSession.userId != 'undefined') {
					gaText += '|userID:' + window.userSession.userId;
				}
				if ($.cookie('userId') != 'undefined' && $.cookie('userId') != '' && $.cookie('userId') != null) {
					gaText += '|userID:' + $.cookie('userId');
				}

				gaText += ' |Location: ' + queryVal;

				Utils.RecentSearch.save(queryVal, responseURL, defaultText, responseURLHref);
				if (cmsContent.GlobalConfig.enableLandingPageSearch === 'true' && typeof responseURLHref != 'undefined' && responseURLHref != undefined && responseURLHref != '' && responseURLHref != 'null' && responseURLHref != null) {
					responseURL = responseURLHref;
				}
				GAUtils.eventTracking.trackevent("", "Recent Search Click", gaText, '');

				window.location = responseURL;
			});


		} else {
			if (typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y") {
				options.type = 'SRP';
				$(".recentSearch").hide();
				if (!backToSearchUrl && cUrl.indexOf('/property/') > 0) {
					options.type = 'PDP';
				}
				Utils.RecentSearch.getStateBasedOnIP(options);
			} else {
				options.type = 'SRP';
				$(".recentSearch").hide();
				if (cUrl.indexOf('/property/') == -1 && cUrl.indexOf("/searchResult") == -1 && cUrl.indexOf("/landingPage") == -1) {
					Utils.RecentSearch.getStateBasedOnIP(options);
				} else if (cUrl.indexOf("/searchResult") >= 0 && cUrl.indexOf("srchBtnClk=") >= 0) {
					Utils.RecentSearch.getStateBasedOnIP(options);
				}
				if (!backToSearchUrl && cUrl.indexOf('/property/') > 0) {
					options.type = 'PDP';
					Utils.RecentSearch.getStateBasedOnIP(options);
				}
			}
		}
	};

	Utils.getRecentSearch = function () {
		try {
			var recentSearch = localStorage.getItem("RecentSearchList");
			recentSearch = JSON.parse(recentSearch);
		} catch (e) { }
		var recentSearchList = "", temp = 1;
		var results = document.getElementById('#googleAPI_result_1');
		results.innerHTML = '';
		if (recentSearch != null && recentSearch != undefined && recentSearch != '' && recentSearch.cookieElements != null && recentSearch.cookieElements != undefined && recentSearch.cookieElements != '') {
			Utils.RecentSearch.getValidRecents(recentSearch);
			$.each(recentSearch.cookieElements, function (index, obj) {
				if (index < 5) {
					if (obj.value.length > 30 && (obj.value.indexOf(' ') > 30 || obj.value.indexOf(' ') === -1)) {
						recentSearchList += '<li class="search" title="' + _.escape(obj.value) + '">' + _.escape(obj.value.substring(0, 27)) + '...</li>';
					} else {
						recentSearchList += '<li class="search">' + _.escape(obj.value) + '</li>';
					}

				}
			});
		}
	};

	Utils.RecentSearch.getStateBasedOnIP = function (options) {
		var dateParam = new Date().getTime();
		var pageType = options.type || null;

		$.get(contextPath + "/portal/geoLocation?" + dateParam, function (response) {
			if (response.status == 'SUCCESS') {
				var state = response.ipLocationDto && response.ipLocationDto.region || '';
				if (!backToSearchUrl && pageType == 'PDP') {
					$(".backtosearch").attr("href", contextPath + "/searchResult?state=" + state);
				} else {
					if (!(typeof newSkinning !== "undefined" && newSkinning)) {
						$(".homeSearchInput").val(state);
					}
					if (window.globalHomeView) {
						globalHomeView.populatePropertyCarousel();
					}

				}
			}
		});

	};


	Utils.upArrow = function () {
		$(window).scroll(function (e) {
			var yPos = ($(window).scrollTop());
			var starting_position = 250;
			if (yPos > starting_position) { // show sticky header after these many (starting_position) pixels have been scrolled down from the top and only when viewport width is equal to second session.
				$(".arrowTop").fadeIn();
			} else if (yPos < 100) {
				$(".arrowTop").fadeOut();
			}
		});
		$('.arrowTop').bind('click', function () {
			$("html, body").animate({ scrollTop: 0 }, { duration: 1000 });
		});
	};
	Utils.buildLableForGAKeyWord = function () {
		var keyword = '';
		if (app.utils.param('searchBy')) {
			keyword = "searchBy=" + app.utils.param('searchBy');
		}
		else if (app.utils.param('category')) {
			keyword = "category=" + app.utils.param('category');
		}
		else if (app.utils.param('state') && app.utils.param('city')) {
			keyword = "state=" + app.utils.param('state') + "&city=" + app.utils.param('city');
		}
		else if (app.utils.param('state')) {
			keyword = "state=" + app.utils.param('state');
		}
		else if (app.utils.param('listingType') && app.utils.param('name')) {
			keyword = "listingType=" + app.utils.param('listingType') + "&name=" + app.utils.param('name');
		}
		if (keyword == "") {
			keyword = window.location.href.split("?");
			keyword = keyword[1];
		}
		return keyword;

	};

	Utils.buildLableForGAKeyWordTracking = function () {
		var keyword = '';
		if (app.utils.param('searchBy')) {
			if (SearchJson.city != '' || SearchJson.state != '' || SearchJson.zip != '') {

				if (SearchJson.city != '') {
					(keyword == '') ? keyword += "CITY:" + SearchJson.city : keyword += ";CITY:" + SearchJson.city;
				}
				if (SearchJson.state != '') {
					(keyword == '') ? keyword += "STATE:" + SearchJson.state : keyword += ";STATE:" + SearchJson.state;
				}
				if (SearchJson.zip != '') {
					(keyword == '') ? keyword += "ZIP:" + SearchJson.zip : keyword += ";ZIP:" + SearchJson.zip;
				}
			} else {
				keyword = "SearchBy:" + app.utils.param('searchBy');
			}
		}
		else if (app.utils.param('category')) {
			keyword = "category=" + app.utils.param('category');
		}
		else if (app.utils.param('state') && app.utils.param('city')) {
			keyword = "state=" + app.utils.param('state') + "&city=" + app.utils.param('city');
		}
		else if (app.utils.param('state')) {
			keyword = "state=" + app.utils.param('state');
		}
		else if (app.utils.param('listingType') && app.utils.param('name')) {
			keyword = "listingType=" + app.utils.param('listingType') + "&name=" + app.utils.param('name');
		}
		if (keyword == "") {
			keyword = window.location.href.split("?");
			keyword = keyword[1];
		}
		return keyword;
	};
	Utils.trackPageResultArray = function (clearAll) {

		var keyword = this.buildLableForGAKeyWord();
		app.utils.updateCSP(window.location.href);
		if (!clearAll) {
			var returnResultArray = '|Filters:' + JSON.stringify(app.session.srp.currentSearchParameters) + '|Ocwen count:' + ocwen_count + '|D2B count:' + d2b_count + '|Prop-type:{' + propSubTypeGA + '}| Sale-type {AUCN:' + aucn_count + ',TRNL:' + trnl_count + '[' + saleTypeFilterGA + ']}|Listing Type:{' + listingTypeFilter + '}|Listing Status{' + listingStatusFilter + '}';
		} else {
			var returnResultArray = '|Filters:|Ocwen count:' + ocwen_count + '|D2B count:' + d2b_count + '|Prop-type:{' + propSubTypeGA + '}| Sale-type {AUCN:' + aucn_count + ',TRNL:' + trnl_count + '[' + saleTypeFilterGA + ']}|Listing Type:{' + listingTypeFilter + '}|Listing Status{' + listingStatusFilter + '}';
		}
		return returnResultArray;

	};
	Utils.getQueryStringValue = function (key) {
		var locationUrl = encodeURI(window.location.search);
		return unescape(locationUrl.replace(new RegExp("^(?:.*[&\\?]" + escape(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
	},
		Utils.getUrlVarsTitle = function (name, url, domType) {

			var urlParamValue, hashes, dispvalue = '', parentClass,
				isNewFiltersEnabled = (typeof enableNewFilters !== 'undefined' && enableNewFilters === 'YES') ? 'Y' : 'N';

			if (isNewFiltersEnabled === 'Y') {
				urlParamValue = Utils.getQueryStringValue(name);
				parentClass = (urlParamValue) ? $.trim($('[id="' + name + "_" + urlParamValue + '"]').attr('parent-class')) : '';
				dispvalue = (parentClass) ? $.trim($("." + parentClass).attr("filter-name")) : '';
			} else {
				hashes = url.slice(url.indexOf('?') + 1).split('&');
				for (var i = 0; i < hashes.length; i++) {
					urlParamValue = hashes[i].split('=');
					if (urlParamValue[0] == name) {
						if (domType == 'a') {
							dispvalue = $.trim($("a[value='" + hashes[i] + "']:first").parents('.filterParent').attr('dispvalue'));

						} else {
							dispvalue = $.trim($(":checkbox[value='" + hashes[i] + "']:first").parents('.filterParent').attr('dispvalue'));
						}
					}
				}
			}
			return dispvalue;
		};
	Utils.buildResultArrayTrackingData = function (url) {
		var currSearchParams = {};
		var parentLabel = '';
		if (app.utils.param('priceRange', url)) {
			var priceArray = app.utils.param('priceRange', url).split('-');
			currSearchParams['minPriceValue'] = decodeURIComponent(priceArray[0]);
			currSearchParams['maxPriceValue'] = decodeURIComponent(priceArray[1]);
		}

		if (app.utils.param('bed', url)) {
			parentLabel = this.getUrlVarsTitle('bed', url, 'a');
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabelsBedBath('bed', url);
		}

		if (app.utils.param('bath', url)) {
			parentLabel = this.getUrlVarsTitle('bath', url, 'a');
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabelsBedBath('bath', url);
		}

		if (app.utils.param('squareFeetRange', url)) {
			var squareFeetRangeArray = app.utils.param('squareFeetRange', url).split('-');
			currSearchParams['minSqFootage'] = decodeURIComponent(squareFeetRangeArray[0]);
			currSearchParams['maxSqFootage'] = decodeURIComponent(squareFeetRangeArray[1]);
		}
		if (app.utils.param('yearBuiltRange', url)) {
			var yearBuiltRangeArray = app.utils.param('yearBuiltRange', url).split('-');
			currSearchParams['minYearBuilt'] = decodeURIComponent(yearBuiltRangeArray[0]);
			currSearchParams['maxYearBuilt'] = decodeURIComponent(yearBuiltRangeArray[1]);
		}

		if (app.utils.param('listingType', url)) {
			parentLabel = this.getUrlVarsTitle('listingType', url);
			currSearchParams['listingType'] = app.utils.getUrlVarsLabels('listingType', url);
		}

		if (app.utils.param('propertyStatus', url)) {
			parentLabel = this.getUrlVarsTitle('propertyStatus', url);
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabels('propertyStatus', url);
		}
		if (app.utils.param('propertySubType', url)) {
			parentLabel = this.getUrlVarsTitle('propertySubType', url);
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabels('propertySubType', url);
		}
		if (app.utils.param('sid', url)) {
			parentLabel = this.getUrlVarsTitle('sid', url);
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabels('sid', url);
		}

		// Featured filters 
		if (app.utils.param('propertyShowMoreStatus', url)) {
			parentLabel = this.getUrlVarsTitle('propertyShowMoreStatus', url);
			currSearchParams[parentLabel] = (currSearchParams[parentLabel])
				? currSearchParams[parentLabel] += (', ' + app.utils.getUrlVarsLabels('propertyShowMoreStatus', url))
				: app.utils.getUrlVarsLabels('propertyShowMoreStatus', url);
		}
		if (app.utils.param('selfshowingenabled', url)) {
			parentLabel = this.getUrlVarsTitle('selfshowingenabled', url);
			currSearchParams[parentLabel] = (currSearchParams[parentLabel])
				? currSearchParams[parentLabel] += (', ' + app.utils.getUrlVarsLabels('selfshowingenabled', url))
				: app.utils.getUrlVarsLabels('selfshowingenabled', url);
		}
		if (app.utils.param('reservepricerevealed', url)) {
			parentLabel = this.getUrlVarsTitle('reservepricerevealed', url);
			currSearchParams[parentLabel] = (currSearchParams[parentLabel])
				? currSearchParams[parentLabel] += (', ' + app.utils.getUrlVarsLabels('reservepricerevealed', url))
				: app.utils.getUrlVarsLabels('reservepricerevealed', url);
		}
		if (app.utils.param('nobuyerpremium', url)) {
			parentLabel = this.getUrlVarsTitle('nobuyerpremium', url);
			currSearchParams[parentLabel] = (currSearchParams[parentLabel])
				? currSearchParams[parentLabel] += (', ' + app.utils.getUrlVarsLabels('nobuyerpremium', url))
				: app.utils.getUrlVarsLabels('nobuyerpremium', url);
		}
		if (app.utils.param('ownItNow', url)) {
			parentLabel = this.getUrlVarsTitle('ownItNow', url);
			currSearchParams[parentLabel] = (currSearchParams[parentLabel])
				? currSearchParams[parentLabel] += (', ' + app.utils.getUrlVarsLabels('ownItNow', url))
				: app.utils.getUrlVarsLabels('ownItNow', url);
		}
		if (app.utils.param('brokerCoOp', url)) {
			parentLabel = this.getUrlVarsTitle('brokerCoOp', url);
			currSearchParams[parentLabel] = (currSearchParams[parentLabel])
				? currSearchParams[parentLabel] += (', ' + app.utils.getUrlVarsLabels('brokerCoOp', url))
				: app.utils.getUrlVarsLabels('brokerCoOp', url);
		}

		if (app.utils.param('marketingPhase', url)) {
			parentLabel = this.getUrlVarsTitle('marketingPhase', url);
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabels('marketingPhase', url);
		}
		if (app.utils.param('pageNumber', url)) {
			currSearchParams['pageNumber'] = app.utils.param('pageNumber', url);
		}
		if (app.utils.param('perPage', url)) {
			currSearchParams['perPage'] = app.utils.param('perPage', url);
		}

		if (app.utils.param('sortOrder', url)) {
			currSearchParams['sortOrder'] = app.utils.param('sortOrder', url);
		}
		if (app.utils.param('sort', url)) {
			var sortBy = decodeURIComponent(app.utils.param('sort', url)).split(' ');
			currSearchParams['sortBy'] = sortBy[0];
			currSearchParams['sortOrder'] = sortBy[1];

			var sortByFilter = decodeURIComponent(app.utils.param('sort', url));
			if (sortByFilter === 'Time Left') {
				currSearchParams['sortBy'] = 'Time Left';
				currSearchParams['sortOrder'] = 'Ascending';
			} else if (sortByFilter === 'TimeLeftHL') {
				currSearchParams['sortBy'] = 'Time Left';
				currSearchParams['sortOrder'] = 'Descending';
			}
		}

		if (app.utils.param('category', url)) {
			parentLabel = this.getUrlVarsTitle('marketingPhase', url);
			currSearchParams[parentLabel] = app.utils.getUrlVarsLabels('category', url);
		} else if (app.utils.param('eventId', url)) {
			currSearchParams[parentLabel] = 'foreclosure';
		}

		var resultArray = [];
		$.each(currSearchParams, function (index, filter) {
			resultArray.push(index + ": " + filter);
		});
		return resultArray.join('|');
	},
		Utils.trackResultArrayDataNew = function () {
			//var keyword=this.buildLableForGAKeyWordTracking();
			var gaTrackingData = this.buildResultArrayTrackingData(window.location.href);
			var UniqueSearchId = this.UniqueSearchId();
			var gaText = 'VID :' + $.cookie('visitorId') + '|UniqueSearchId:' + UniqueSearchId;
			var viewType = '';
			var totalResultCount = "";
			if (SearchJson && SearchJson.totalResultCount) {
				totalResultCount = parseInt(SearchJson.totalResultCount);
				totalResultCount = totalResultCount >= 10000 ? "10000+ " : totalResultCount.formatMoney();
			} else {
				try {
					totalResultCount = $(".totalResultCount:eq(0)").text().split(" P")[0];
				} catch (e) { }
			}
			var countData = 'TotalPropertiesCount:{' + totalResultCount + '}|Prop-type: {' + propSubTypeGA + '}| Sale-type {AUCN:' + aucn_count + ',TRNL:' + trnl_count + '[' + saleTypeFilterGA + ']}|Listing Type: {' + listingTypeFilter + '}|Listing Status {' + listingStatusFilter + '}';
			if (window.location.search.indexOf("viewType=map") >= 0) {
				viewType = 'Map';
			} else {
				viewType = 'List';
			}
			if ($.cookie('userId') != 'undefined' && $.cookie('userId') != '' && $.cookie('userId') != null) {
				gaText += '|UID:' + $.cookie('userId');
			}
			//GAUtils.eventTracking.trackevent("","Views",'Keyword - '+keyword +'| View Type: '+viewType+'|'+gaText,'',1);
			//GAUtils.eventTracking.trackevent("","ResArray",'Keyword - '+keyword +'| Listing Id: '+listingIds+'|'+gaText,'',1);
			//GAUtils.eventTracking.trackevent("","Result Array",'Keyword - '+keyword + '|' + gaTrackingData + '|' + countData + '|' + gaText,'',1);
		},
		Utils.trackListMapToggle = function (viewType) {
			//var keyword=this.buildLableForGAKeyWord();
			var gaTrackingData = this.buildResultArrayTrackingData(window.location.href);
			var UniqueSearchId = this.UniqueSearchId();
			var gaText = 'VID :' + $.cookie('visitorId') + '|UniqueSearchId:' + UniqueSearchId;
			if (window.userSession != 'undefined' && window.userSession != undefined && window.userSession.userId != 'undefined') {
				gaText += '|UID:' + window.userSession.userId;
			}

			GAUtils.eventTracking.trackevent("", "User Selected View", keyword + '| User View : ' + viewType + '|' + gaText, '', 0);

		},
		Utils.buildSearchParamsForGa = function () {
			var searchParams = "";
			if (app.session.srp.currentSearchParameters.maxPriceValue != "" && app.session.srp.currentSearchParameters.maxPriceValue != undefined) {
				searchParams += "&maxPriceValue=" + app.session.srp.currentSearchParameters.maxPriceValue;
			}
			if (app.session.srp.currentSearchParameters.minPriceValue != "" && app.session.srp.currentSearchParameters.minPriceValue != undefined) {
				searchParams += "&minPriceValue=" + app.session.srp.currentSearchParameters.minPriceValue;
			}
			if (app.session.srp.currentSearchParameters.minBedroomCount != "" && app.session.srp.currentSearchParameters.minBedroomCount != undefined) {
				searchParams += "&bedroomCount=" + app.session.srp.currentSearchParameters.minBedroomCount;
			}
			if (app.session.srp.currentSearchParameters.maxBedroomCount != "" && app.session.srp.currentSearchParameters.maxBedroomCount != undefined) {
				searchParams += "&maxBedroomCount=" + app.session.srp.currentSearchParameters.maxBedroomCount;
			}
			if (app.session.srp.currentSearchParameters.maxBathroomCount != "" && app.session.srp.currentSearchParameters.maxBathroomCount != undefined) {
				searchParams += "&maxBathroomCount=" + app.session.srp.currentSearchParameters.maxBathroomCount;
			}
			if (app.session.srp.currentSearchParameters.minBathroomCount != "" && app.session.srp.currentSearchParameters.minBathroomCount != undefined) {
				searchParams += "&bathroomCount=" + app.session.srp.currentSearchParameters.minBathroomCount;
			}
			if (app.session.srp.currentSearchParameters.minPropertySize != "" && app.session.srp.currentSearchParameters.minPropertySize != undefined) {
				searchParams += "&minPropertySize=" + app.session.srp.currentSearchParameters.minPropertySize;
			}
			if (app.session.srp.currentSearchParameters.maxPropertySize != "" && app.session.srp.currentSearchParameters.maxPropertySize != undefined) {
				searchParams += "&maxPropertySize=" + app.session.srp.currentSearchParameters.maxPropertySize;
			}
			if (app.session.srp.currentSearchParameters.minYearBuilt != "" && app.session.srp.currentSearchParameters.minYearBuilt != undefined) {
				searchParams += "&minYearBuilt=" + app.session.srp.currentSearchParameters.minYearBuilt;
			}
			if (app.session.srp.currentSearchParameters.maxYearBuilt != "" && app.session.srp.currentSearchParameters.maxYearBuilt != undefined) {
				searchParams += "&maxYearBuilt=" + app.session.srp.currentSearchParameters.maxYearBuilt;
			}
			if (app.session.srp.currentSearchParameters.listingType != "" && app.session.srp.currentSearchParameters.listingType != undefined) {
				if ($("#filters-overlay-sale-type div:first-child").attr("value") == app.session.srp.currentSearchParameters.listingType) {
					searchParams += "&listingType=ANY";
				} else {
					searchParams += "&listingType=" + app.session.srp.currentSearchParameters.listingType;
				}
			} else { searchParams += "&listingType=ANY"; }
			if (app.session.srp.currentSearchParameters.occupancyStatus != "" && app.session.srp.currentSearchParameters.occupancyStatus != undefined) {
				searchParams += "&occupancyStatus=" + app.session.srp.currentSearchParameters.occupancyStatus;
			}
			if (app.session.srp.currentSearchParameters.propertyStatus != "" && app.session.srp.currentSearchParameters.propertyStatus != undefined) {
				var cate_Value = app.session.srp.currentSearchParameters.propertyStatus;
				if (cate_Value.indexOf("UNDERREVIEW") != -1 && cate_Value.indexOf("UNDERCONTRACT") != -1 && cate_Value.indexOf("ACTIVE") != -1 && cate_Value.indexOf("PREVIEW") != -1) {
					searchParams += "&propertyStatus=ALL";
				}
				else {
					searchParams += "&propertyStatus=" + app.session.srp.currentSearchParameters.propertyStatus;
				}
			} else { searchParams += "&propertyStatus=ALL"; }
			if (app.session.srp.currentSearchParameters.pageNumber != "" && app.session.srp.currentSearchParameters.pageNumber != undefined) {
				searchParams += "&pageNumber=" + app.session.srp.currentSearchParameters.pageNumber;
			}
			if (app.session.srp.currentSearchParameters.perPage != "" && app.session.srp.currentSearchParameters.perPage != undefined) {
				searchParams += "&perPage=" + app.session.srp.currentSearchParameters.perPage;
			}
			if (app.session.srp.currentSearchParameters.offers != "" && app.session.srp.currentSearchParameters.offers != undefined) {
				searchParams += "&offers=" + app.session.srp.currentSearchParameters.offers;
			}
			if (app.session.srp.currentSearchParameters.propertySubType != "" && app.session.srp.currentSearchParameters.propertySubType != undefined) {
				if ($("#filters-overlay-property-type div:first-child").attr("value") == app.session.srp.currentSearchParameters.propertySubType) {
					searchParams += "&propertySubType=ALL";
				} else { searchParams += "&propertySubType=" + app.session.srp.currentSearchParameters.propertySubType; }
			} else { searchParams += "&propertySubType=ALL"; }
			if (app.session.srp.currentSearchParameters.state != "" && app.session.srp.currentSearchParameters.state != undefined) {
				searchParams += "&state=" + app.session.srp.currentSearchParameters.state;
			}
			if (app.session.srp.currentSearchParameters.city != "" && app.session.srp.currentSearchParameters.city != undefined) {
				searchParams += "&city=" + app.session.srp.currentSearchParameters.city;
			}
			if (app.session.srp.currentSearchParameters.zip != "" && app.session.srp.currentSearchParameters.zip != zip) {
				searchParams += "&zip=" + app.session.srp.currentSearchParameters.zip;
			}
			if (app.session.srp.currentSearchParameters.category != "") {
				if ($("#filters-overlay-listing-type div:first-child").attr("value") == app.session.srp.currentSearchParameters.category) {
					searchParams += "&typeCategory=ALL";
				} else if ($("#filters-overlay-listing-type div:first-child").attr("queryValue") == app.session.srp.currentSearchParameters.category) {
					searchParams += "&typeCategory=ALL";
				} else { searchParams += "&typeCategory=" + app.session.srp.currentSearchParameters.category; }

			} else { searchParams += "&typeCategory=ANY"; }
			return searchParams;
		};

	Utils.getSearchParams = function () {
		var searchParams = "";
		return app.utils.updateCSP(window.location.href);
	};
	Utils.trackFilteredEvent = function (checkedRefinement, unCheckedValue, sort, moreFilter, clearAll) {
		var UniqueSearchId = this.UniqueSearchId();
		if (clearAll) {
			var deselectedSliderValues = '';
			var priceRange = $.trim($('#amountSliderVal').html());
			var bedCount = $.trim($('.bedNumber .active').html());
			var bathCount = $.trim($('.bathNumber .active').html());
			var squareFeetRange = $.trim($('#sqftSliderVal').html());
			var yearBuiltRange = $.trim($('#yearbuiltSliderVal').html());


			if (priceRange != '' && priceRange != undefined) {
				if (deselectedSliderValues == '') {
					deselectedSliderValues = "price:" + priceRange;
				} else {
					deselectedSliderValues += ",price:" + priceRange;
				}
			}
			if (bedCount != '' && bedCount != undefined) {
				if (deselectedSliderValues == '') {
					deselectedSliderValues = "bedCount:" + bedCount;
				} else {
					deselectedSliderValues += ",bedCount:" + bedCount;
				}
			}
			if (bathCount != '' && bathCount != undefined) {
				if (deselectedSliderValues == '') {
					deselectedSliderValues = "bathCount:" + bathCount;
				} else {
					deselectedSliderValues += ",bathCount:" + bathCount;
				}
			}
			if (squareFeetRange != '' && squareFeetRange != undefined) {
				if (deselectedSliderValues == '') {
					deselectedSliderValues = "squareFeetRange:" + squareFeetRange;
				} else {
					deselectedSliderValues += ",squareFeetRange:" + squareFeetRange;
				}
			}
			if (yearBuiltRange != '' && yearBuiltRange != undefined) {
				if (deselectedSliderValues == '') {
					deselectedSliderValues = "yearBuiltRange:" + yearBuiltRange;
				} else {
					deselectedSliderValues += ",yearBuiltRange:" + yearBuiltRange;
				}
			}
			if (unCheckedValue == '') {
				unCheckedValue = deselectedSliderValues;
			} else {
				unCheckedValue = unCheckedValue + ',' + deselectedSliderValues;
			}
			if (typeof (Storage) !== "undefined") {
				try {
					localStorage.setItem("deselectedValues", unCheckedValue.replace('=', ':'));
					localStorage.setItem("isClearAll", true);
				} catch (e) { }
			}

		} else {

			/*if(SearchJson.viewType!=='' && SearchJson.viewType!=undefined && SearchJson.viewType=='map'){

				GAUtils.eventTracking.trackevent("","Result Array map - Refines",this.trackPageResultArray()+'| UniqueSearchId:'+UniqueSearchId+'|Deselected:'+unCheckedValue.replace('=',':'));
				GAUtils.eventTracking.trackevent("","Map","Dynamic Search Terms:"+Utils.generateSearchLabelForGATrackEvent()+'| UniqueSearchId:'+UniqueSearchId);
				GAUtils.eventTracking.trackevent("","Result Array Map",this.trackPageResultArray()+'| UniqueSearchId:'+UniqueSearchId);
			    
			}else{
				/*if(sort){
					GAUtils.eventTracking.trackevent("","Sort by - Most Popular/Price",Utils.trackPageResultArray(clearAll)+'| UniqueSearchId:'+UniqueSearchId);
				}else{
					GAUtils.eventTracking.trackevent("","Result Array  - Refines",this.trackPageResultArray(clearAll)+'| UniqueSearchId:'+UniqueSearchId+'|Deselected:'+unCheckedValue.replace('=',':'));
				}
				GAUtils.eventTracking.trackevent("","List","Dynamic Search Terms:"+Utils.generateSearchLabelForGATrackEvent()+'| UniqueSearchId:'+UniqueSearchId);
				GAUtils.eventTracking.trackevent("","ResArray",listingIds+'| UniqueSearchId:'+UniqueSearchId);
				GAUtils.eventTracking.trackevent("","Result Array",this.trackPageResultArray(clearAll)+'| UniqueSearchId:'+UniqueSearchId);*/
			/* if(moreFilter!=undefined && moreFilter=='moreFilterCheckbox'){
				 var keyword=this.buildLableForGAKeyWord();
				 GAUtils.eventTracking.trackevent("","Additional Search Filters",keyword+'| UniqueSearchId:'+UniqueSearchId);
			 }
		 }*/
		}
	},
		Utils.trackClickEvent = function (eventAction, trackLabel) {
			var UniqueSearchId = this.UniqueSearchId();
			var gaText = 'visitorId :' + $.cookie('visitorId') + '|UniqueSearchId:' + UniqueSearchId;
			if (window.userSession != 'undefined' && window.userSession != undefined && window.userSession.userId != 'undefined' && window.userSession.userId != null && window.userSession.userId != 'null') {
				gaText += '|UID:' + window.userSession.userId;
			}
			GAUtils.eventTracking.trackevent("", eventAction, gaText + '|' + trackLabel);

		},
		Utils.generateSearchLabelForGATrackEvent = function (linkObj) {
			var searchParams = "";
			app.utils.updateCSP(window.location.href);
			if (app.session.srp.currentSearchParameters.searchBy != "" && app.session.srp.currentSearchParameters.searchBy != undefined) {
				searchParams += 'SB:' + app.session.srp.currentSearchParameters.searchBy + '|';
			} else if (app.session.srp.currentSearchParameters.state != "" || app.session.srp.currentSearchParameters.city != "" || app.session.srp.currentSearchParameters.zip != "" || app.session.srp.currentSearchParameters.county != "") {
				if (app.session.srp.currentSearchParameters.state != "" && app.session.srp.currentSearchParameters.state != undefined) {
					searchParams += "STA:" + app.session.srp.currentSearchParameters.state + '|';
				}
				if (app.session.srp.currentSearchParameters.city != "" && app.session.srp.currentSearchParameters.city != undefined) {
					searchParams += "CIT:" + app.session.srp.currentSearchParameters.city + '|';
				}
				if (app.session.srp.currentSearchParameters.zip != "" && app.session.srp.currentSearchParameters.zip != undefined) {
					searchParams += "ZIP:" + app.session.srp.currentSearchParameters.zip + '|';
				}
				if (app.session.srp.currentSearchParameters.county != "" && app.session.srp.currentSearchParameters.county != undefined) {
					searchParams += "COT:" + app.session.srp.currentSearchParameters.county + '|';
				}
			} else {
				searchParams += 'SB:All States|';
			}

			if (app.session.srp.currentSearchParameters.listingType != "" && app.session.srp.currentSearchParameters.listingType != undefined) {
				searchParams += "LT:" + app.session.srp.currentSearchParameters.listingType + '|';
			}
			if (app.session.srp.currentSearchParameters.occupancyStatus != "" && app.session.srp.currentSearchParameters.occupancyStatus != undefined) {
				searchParams += "OS:" + app.session.srp.currentSearchParameters.occupancyStatus + '|';
			}
			if (app.session.srp.currentSearchParameters.propertyStatus != "" && app.session.srp.currentSearchParameters.propertyStatus != undefined) {
				searchParams += "PS:" + app.session.srp.currentSearchParameters.propertyStatus + '|';
			}
			if (app.session.srp.currentSearchParameters.offers != "" && app.session.srp.currentSearchParameters.offers != undefined) {
				searchParams += "OFS:" + app.session.srp.currentSearchParameters.offers + '|';
			}
			if (app.session.srp.currentSearchParameters.propertySubType != "" && app.session.srp.currentSearchParameters.propertySubType != undefined) {
				searchParams += "PT:" + app.session.srp.currentSearchParameters.propertySubType + '|';
			}
			if (app.session.srp.currentSearchParameters.category != "" && app.session.srp.currentSearchParameters.category != undefined) {
				searchParams += "CT:" + app.session.srp.currentSearchParameters.category + '|';
			}
			if (app.session.srp.currentSearchParameters.maxPriceValue != "" && app.session.srp.currentSearchParameters.maxPriceValue != undefined) {
				searchParams += "PM:" + app.session.srp.currentSearchParameters.maxPriceValue + '|';
			}
			if (app.session.srp.currentSearchParameters.minPriceValue != "" && app.session.srp.currentSearchParameters.minPriceValue != undefined) {
				searchParams += "MP:" + app.session.srp.currentSearchParameters.minPriceValue + '|';
			}
			if (app.session.srp.currentSearchParameters.minBedroomCount != "" && app.session.srp.currentSearchParameters.minBedroomCount != undefined) {
				searchParams += "BC:" + app.session.srp.currentSearchParameters.minBedroomCount + '|';
			}
			if (app.session.srp.currentSearchParameters.maxBedroomCount != "" && app.session.srp.currentSearchParameters.maxBedroomCount != undefined) {
				searchParams += "MBC:" + app.session.srp.currentSearchParameters.maxBedroomCount + '|';
			}
			if (app.session.srp.currentSearchParameters.maxBathroomCount != "" && app.session.srp.currentSearchParameters.maxBathroomCount != undefined) {
				searchParams += "MBA" + app.session.srp.currentSearchParameters.maxBathroomCount + '|';
			}
			if (app.session.srp.currentSearchParameters.minBathroomCount != "" && app.session.srp.currentSearchParameters.minBathroomCount != undefined) {
				searchParams += "BA:" + app.session.srp.currentSearchParameters.minBathroomCount + '|';
			}
			if (app.session.srp.currentSearchParameters.minPropertySize != "" && app.session.srp.currentSearchParameters.minPropertySize != undefined) {
				searchParams += "PS:" + app.session.srp.currentSearchParameters.minPropertySize + '|';
			}
			if (app.session.srp.currentSearchParameters.maxPropertySize != "" && app.session.srp.currentSearchParameters.maxPropertySize != undefined) {
				searchParams += "MPS:" + app.session.srp.currentSearchParameters.maxPropertySize + '|';
			}
			if (app.session.srp.currentSearchParameters.minYearBuilt != "" && app.session.srp.currentSearchParameters.minYearBuilt != undefined) {
				searchParams += "YB:" + app.session.srp.currentSearchParameters.minYearBuilt + '|';
			}
			if (app.session.srp.currentSearchParameters.maxYearBuilt != "" && app.session.srp.currentSearchParameters.maxYearBuilt != undefined) {
				searchParams += "MYB:" + app.session.srp.currentSearchParameters.maxYearBuilt + '|';
			}
			return searchParams;
		};


	Utils.validateSearchInput = function (e, pattern) {
		var key;
		var keychar;
		if (window.event)
			key = window.event.keyCode;
		else if (e)
			key = e.which;
		else
			return true;
		/* key == 35 removed # */
		if (key == 0 || key == 13 || key == 8 || key == 46 || key == 36 || key == 37 || key == 38 || key == 39 || key == 40) { return true; }

		keychar = String.fromCharCode(key);

		var allowed = pattern; //"01234567890abcdefghijklmnopqrstuvwxyz &.'!@$%,;-/+.#(){}[]<>"; 
		if (allowed.indexOf(keychar.toLowerCase()) != -1) { return true; }

		else { return false; }
	};

	Number.prototype.formatMoney = function (c, d, t) {
		var n = this,
			c = isNaN(c = Math.abs(c)) ? 0 : c,
			d = d == undefined ? "." : d,
			t = t == undefined ? "," : t,
			s = n < 0 ? "-" : "",
			i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
			j = (j = i.length) > 3 ? j % 3 : 0;
		return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	};
	Number.prototype.formatAmount = function (minDigits, d, t) {

		minDigits = typeof minDigits !== 'undefined' ? minDigits : 2;
		return new Intl.NumberFormat('en-US', { maximumFractionDigits: (minDigits) }).format(this)

		// var n = this, 
		//     c = isNaN(c = Math.abs(c)) ? 0 : c, 
		//     d = d == undefined ? "." : d, 
		//     t = t == undefined ? "," : t, 
		//     s = n < 0 ? "-" : "", 
		//     i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
		//     j = (j = i.length) > 3 ? j % 3 : 0;
		// var amount =  s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		// return amount.replace(/\.00$/, '');
	};

	String.prototype.replaceAll = function (find, replace) {
		var str = this;
		find = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
		return str.replace(new RegExp(find, 'g'), replace);
	};

	window.onerror = function (message, file, line) {
		GAUtils.eventTracking.trackevent('New JS Error In the Page', window.location.pathname, file + ':' + line + '\n\n' + message, null, 1);
	};

	/* Utils.updateAnonymousCookieEmail =  function(emailId, isLogin){
		//var anonymousEmail = Utils.getCookieDetails("userPreferenceEmailId");
		var emailId = window.btoa(emailId);
		Utils.setCookieDetails('userPreferenceEmailId', emailId, 365);
		Utils.updateAnonymousUserData(emailId, isLogin);
	}; */
	Utils.setHubzToken = function (callbackOptions) {
		try {
			var postData = {},
				callback = callbackOptions.callback;
			postData.refreshToken = $.cookie('_fcrt');

			$.ajax({
				url: "/fc-service/api/getNewAccessToken",
				type: 'POST',
				success: function (responseData) {
					var response = responseData || '',
						status = response.status || 'ERROR';

					if (status === 'SUCCESS') {
						console.debug('getNewAccessToken :: 2:', response);
						return callback(response.status === "SUCCESS" && response.data && response.data.accessToken || nullresponse.status === "SUCCESS" && response.data && response.data.accessToken || null);
					}

				},
				error: function (error) {

				}
			});
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getEncryptionDetails|" + encodeURI, null, 1);

		}
	},
		Utils.browserVersionUpdate = function () {

			$.browser = {};
			$.browser.mozilla = /mozilla/.test(navigator.userAgent.toLowerCase()) && !/webkit/.test(navigator.userAgent.toLowerCase());
			$.browser.opera = /opera/.test(navigator.userAgent.toLowerCase());
			$.browser.msie = /msie/.test(navigator.userAgent.toLowerCase());
			$.browser.safari = ($.browser.webkit && !(/chrome/.test(navigator.userAgent.toLowerCase())));

			var browser_version = Utils.get_browser_version();

			if (!Utils.getCookieOutdatedBrowser('outDated-browser-flag')) {


				if ($.browser.msie && parseFloat(browser_version) < 9) {
					Utils.browserOutDatedMess("//windows.microsoft.com/ie");
				}
				else if ($.browser.mozilla) //If the given browser is Mozilla Firefox
				{
					if (/Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent)) { //test for Firefox/x.x or Firefox x.x (ignoring remaining digits);
						var ffversion = new Number(RegExp.$1) // capture x.x portion and store as a number
						if (parseInt(ffversion) < 24) {
							Utils.browserOutDatedMess("//www.firefox.com/");
						}
					}
				}
				else if (window.chrome && !(/OPR[\/\s](\d+\.\d+)/.test(navigator.userAgent))) {
					var chromeVer = parseInt(window.navigator.appVersion.match(/Chrome\/(\d+)\./)[1], 10);
					if (parseInt(chromeVer) < 27) {
						Utils.browserOutDatedMess("//www.google.com/chrome");
					}
				} else if ($.browser.safari) {

					if (parseInt(browser_version) < 5) {
						Utils.browserOutDatedMess("//www.apple.com/safari/");
					}
				}
				else if (/OPR[\/\s](\d+\.\d+)/.test(navigator.userAgent)) //If the given browser is Opera
				{
					var oprversion = new Number(RegExp.$1) // capture x.x portion and store as a number
					if (parseInt(oprversion) < 16) {
						Utils.browserOutDatedMess("//www.opera.com/");
					}

				}
			}
		};

	Utils.setCookieDetails = function (c_name, value, expiredays) {
		var exdate = new Date();
		exdate.setDate(exdate.getDate() + expiredays);
		document.cookie = c_name + "=" + (value) + ((expiredays == null) ? ";path=/" : ";path=/;expires=" + exdate.toGMTString());
		console.log('cookie set:', c_name)
		// clean cookie as per concent
		Utils.cleanCookie();
	};

	Utils.setSecureCookieDetails = function (name, value, expiry) {
		expiry = expiry || new Date(Date.now() + 86400e3) // One day from now
		document.cookie =
			name +
			"=" +
			value +
			";path=/;samesite=strict" +
			(expiry ? ";expires=" + expiry.toGMTString() : "");
	}

	Utils.getCookieDetails = function (c_name) {
		if (document.cookie.length > 0) {
			var c_start = document.cookie.indexOf(c_name + "=");
			if (c_start != -1) {
				c_start = c_start + c_name.length + 1;
				var c_end = document.cookie.indexOf(";", c_start);
				if (c_end == -1) {
					c_end = document.cookie.length;
				}
				return (document.cookie.substring(c_start, c_end));
			}
		} return false;
	};

	Utils.deleteCookie = function (c_name) {
		if (Utils.getCookieDetails(c_name))
			Utils.setCookieDetails(c_name, "", -1);
	};

	Utils.get_browser_version = function () {
		var N = navigator.appName, ua = navigator.userAgent, tem;
		var M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
		if (M && (tem = ua.match(/version\/([\.\d]+)/i)) != null) M[2] = tem[1];
		M = M ? [M[1], M[2]] : [N, navigator.appVersion, '-?'];
		return M[1];
	};

	Utils.browserOutDatedMess = function (link) {
		$(".browser-version-link").attr("href", link);
		$("#browserVersionUpdate").slideDown('slow');
		$("#browserVersionUpdate").attr("flg", 'Y');
		$("header").css("min-height", "106px");

	};

	Utils.getEventSRPUrl = function (id) {
		var eventSrpUrl = "auctions?listingType=AUCN"; //Fallback url incase of failure	
		$.get(contextPath + "/portal/getEventSRPUrl?eventId=" + id, function (response) {
			if (response) {
				eventSrpUrl = response.searchURI || eventSrpUrl;
				var uagent = navigator.userAgent.toLowerCase();
				if (uagent.search("iphone") > -1) {
					$('.global-Mask').hide();
				}
				if (isMobile) {
					window.location.href = encodeURI(contextPath + "/" + eventSrpUrl);
				} else {
					window.open(contextPath + "/" + eventSrpUrl, '_blank');

				}

			} else {
				if (isMobile) {
					window.location.href = encodeURI(contextPath + "/" + eventSrpUrl);
				} else {
					window.open(contextPath + "/" + eventSrpUrl, '_blank');

				}
			}
			$("#ajax-loader").hide();
			$("#ajax-loader-mask").hide();
		});
	};

	Utils.correctTooltipPosition = function () {

		// Get .ktooltiptext sibling
		var tooltip = this.parentNode.querySelector(".tooltiptext");

		// Get calculated ktooltip coordinates and size
		var ktooltip_rect = this.getBoundingClientRect();

		var tipX = ktooltip_rect.width;
		var tipY = 30;

		tooltip.style.top = tipY + 'px';
		tooltip.style.left = tipX + 'px';

		// Get calculated tooltip coordinates and size
		var tooltip_rect = tooltip.getBoundingClientRect();
		// Corrections if out of window
		if ((tooltip_rect.x + tooltip_rect.width) > window.innerWidth) {
			tipX = -((tooltip_rect.x + tooltip_rect.width + 10) - window.innerWidth);  // Out on the right
			// Apply corrected position
			tooltip.style.marginLeft = -160 + tipX + 'px';
		} else if (tooltip_rect.x < 0) {

		}
		if (tooltip_rect.y < 0) {
			tipY = tipY - tooltip_rect.y;                // Out on the top
			// tooltip.style.left = tipX + 'px';   // Align on the top
		}
	};

	Utils.enableTooltip = function (parent) {
		try {

			$('.tooltipPop').next().off('click').click(function (e) {
				e.stopPropagation();
			});

			$(document).off('mouseenter click').on('mouseenter click', '.tooltipPop', function (e) {
				e.stopPropagation();
				var next = $(this).next();

				if (next.css('display') == 'none') {
					$('.tooltipPopdesc').fadeOut('fast');
					if (next.find('.uparrow').length == 0) {
						var html = next.html();
						next.html('<div class="uparrow"></div><div class="popcontent">' + html + '</div>');
					}

					var position = $(this).position();
					var ptop = position.top;
					var firstWidth = next.outerWidth();
					var pleft = position.left - firstWidth + 35;

					if (pleft < 0) {
						pleft = 20;
					}

					next.css({ "left": pleft + 'px', "top": (ptop + 33) + 'px' });

					var secondWidth = next.outerWidth();

					if (firstWidth > secondWidth) {
						//pleft = firstWidth - secondWidth + 10;
						//next.css({"left": '-=' + pleft + 'px'});
					}

					next.fadeIn('fast');

					pleft = $(this).position().left - next.position().left;
					pleft = (pleft < 3) ? 10 : pleft
					next.find('.uparrow').css({ "left": (pleft + 1) + 'px' });
				}
				/*else
				{
					next.fadeOut('fast');
				}*/

			});


		} catch (error) {

		}
	};


	Utils.setIntervalToShowRegister = function () {

		var cmsContent = typeof saveListDrawerContent !== 'undefined' ? saveListDrawerContent : {},
			timeOut = cmsContent.subscribeContent && cmsContent.subscribeContent.showRegister && cmsContent.subscribeContent.showRegister.timeOut || 60000,
			userID = typeof window.userSession !== "undefined" && window.userSession.userId !== null && window.userSession.userId || '',
			resetPopup = Utils.getCookieDetails('resetPopup') || '';

		if (resetPopup == '') {
			clearTimeout(openRegisterPopupInterval);
			openRegisterPopupInterval = setTimeout(function () {
				GAUtils.eventTracking.trackevent("", "Anonymous Register Capture", $.cookie('visitorId'));
				userID = typeof window.userSession !== "undefined" && window.userSession.userId !== null && window.userSession.userId || '';
				if (userID == '') {
					Utils.setCookieDetails('resetPopup', 'Y', 1);
					$("#loginRegisterPopup").click();
				}
			}, timeOut);
		}
	};

	/**
	 * File scope config to save encryption details
	 */
	Utils.encryptionConfig = {
		enabled: false,
		param: null
	};

	/**
	 * Function to get encryption details 
	 */
	Utils.getEncryptionDetails = function (options) {

		try {

			options = options || {};

			var relativeUri = (typeof contextPath === 'undefined') ? "" : contextPath;

			relativeUri = (relativeUri.indexOf('portal') > -1) ? relativeUri : relativeUri + '/portal';

			$.ajax({
				url: relativeUri + "/lean-regn/getPasswordEncConfig",
				type: 'POST',
				success: function (responseData) {
					var response = responseData || '',
						status = response.status || 'ERROR';

					if (status === 'SUCCESS') {
						Utils.encryptionConfig.enabled = response.data && response.data.encryptionFlag;
						Utils.encryptionConfig.param = response.data && window.atob(response.data.param) || null;
					}

				},
				error: function (error) {
					GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getEncryptionDetails|Ajax Error:|" + error, null, 1);
				}
			});
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getEncryptionDetails|" + encodeURI, null, 1);
		}

	};
	/**
	 * Function to encrypt the give password 
	 * @param {password} options 
	 */
	Utils.encryptPassword = function (options) {
		try {

			options = options || {};

			var password = options.password || '',
				salt = options.salt || '';

			if (!password) return password;

			// check if salt is available
			if (salt) {
				password = cryptoJs.AES.encrypt(password, salt);
				password = password.toString() || '';
			}

			return password;

		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:encryptPassword|" + e, null, 1);
		}
	};

	/**
	 * Encrypt the password by using forge.js AEG_GCM 
	 */
	Utils.forgeEncryptPassword = function (options) {
		try {
			options = options || {};
			var password = options.password || '',
				salt = options.salt || '';
			if (!password) return password;
			// check if salt is available
			var key = forge.util.encode64(forge.random.getBytesSync(24));
			var iv = forge.util.encode64(forge.random.getBytesSync(10));
			// encrypt some bytes using GCM mode
			var cipher = forge.cipher.createCipher('AES-GCM', key);
			cipher.start({
				iv: iv,
				additionalData: salt,
				tagLength: 128 // optional, defaults to 128 bits
			});
			cipher.update(forge.util.createBuffer(password));
			cipher.finish();
			var encrypted = cipher.output;
			var encodedB64 = forge.util.encode64(encrypted.data);
			var tag = cipher.mode.tag;
			var tagB64 = forge.util.encode64(tag.data);
			var encryptedText = key + iv + tagB64 + encodedB64;
			return encryptedText;
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:forgeEncryptPassword|" + e, null, 1);
		}
	};

	/**
	   *   Function to get CIF details for listing. 
	   *   Wheather CIF is mandatory or not, if yes show the CIF url in confirmation pages
	   *   @params 
	   *           Object 
	   * 				- listingIds : Array of listingIds (String)  
	   *				- viewObject : View Object 
	   *				- callback 	 : Callback function 
	   *   @returns 
	   *           Object - Array of objects
	   *                       each object contains link and status of CIF
	   */
	Utils.getCIFDetails = function (options) {

		try {
			options = options || {};

			var data = options.data || [],
				callback = options.callback || null,
				url = options.url || null,
				type = options.type || 'GET',
				listingType = options.listingType || null,
				errorMessage, status,
				relativeUri = (typeof contextPath === 'undefined') ? "" : contextPath;

			relativeUri = (relativeUri.indexOf('portal') > -1) ? relativeUri : relativeUri + '/portal';

			if (listingType && url) {
				$.ajax({
					url: relativeUri + '/api/' + listingType + '/' + url,
					dataType: "json",
					type: type,
					data: data,
					contentType: "application/json; charset=utf-8",
					success: function (response) {

						response = response || {};
						status = 'success';

						if (callback) {
							callback({
								callbackOptions: options,
								response: response,
								status: status
							});
						}
					},
					error: function (xmlhttprequest, textstatus, message) {
						errorMessage = (textstatus === "timeout") ? "Network timeout" : message;
						if (callback) {
							callback({
								callbackOptions: options,
								status: 'error',
								errorMessage: errorMessage
							});
						}
						// TODO: Log GA event - Inconsistant data to get CIF details
						GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getCIFDetails|AJAX error - " + errorMessage, null, 1);
					}
				});
			} else {
				// TODO: Log GA event - Inconsistant data to get CIF details
				GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getCIFDetails|Listing ids not available", null, 1);
			}
		} catch (e) {
			// TODO: Log GA event
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getCIFDetails|" + e, null, 1);
		}
	};
	/**
		*   Function to format the phoneNumber details for listing. 
		*   @params 
		*           variable 
		* 				- phoneNumber : Phone number
		*				
		*   @returns 
		*           variable - return formatted phone number
		*/
	Utils.formatPhoneNumber = function (phoneNumber) {
		var phoneNumberFormatted = phoneNumber.replace(/(\d{3})\)?(\d{3})\-?(\d{4})/, '($1) $2-$3');
		return phoneNumberFormatted;
	};
	/**
	*   Function to format the phoneNumber details for listing. 
	*   @params 
	*           variable 
	* 				- phoneNumber : Phone number
	*				
	*   @returns 
	*           variable - return unformatted phone number
	*/
	Utils.unformatPhoneNumber = function (phoneNumber) {
		var phoneNumberUnFormatted = phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
		return $.trim(phoneNumberUnFormatted);
	};

	Utils.isFetchInprogress = false;

	Utils.fetch = function (options) {
		try {
			var callback = options.callback || null,
				type = options.type || 'GET',
				url = options.url || '',
				data = options.data || {},
				contentType = options.contentType ? options.contentType : "application/json;";

			if (contentType === "application/json;") {
				data = JSON.stringify(data);
			};

			Utils.isFetchInprogress = true;
			if (options.data == null) {
				data = "";
			}

			$.ajax({
				url: url,
				type: type,
				dataType: 'json',
				data: data,
				contentType: contentType,
				success: function (response) {
					response = response || {};
					if (callback) callback({ options: options, status: 'SUCCESS', response: response });
				},
				error: function (jqXhr, status, error, response) {
					if (callback) callback({ options: options, status: 'ERROR', response: jqXhr });
					GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:fetch|Ajax error: " + error, null, 1);
				},
				complete: function () {
					Utils.isFetchInprogress = false;
				}
			});

		} catch (exception) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:fetch|" + exception, null, 1);
		}
	};

	Utils.getAccessToken = function (options) {
		try {
			options = options || {};

			var self = this,
				applicationPath = (typeof contextPath !== "undefined") ? contextPath : '',
				url = (applicationPath.indexOf('portal') > -1) ? applicationPath + '/lean-regn/getUserToken' : applicationPath + '/portal/lean-regn/getUserToken',
				viewObject = options.viewObject || null,
				callback = options.callback;

			self.fetch({
				type: "POST",
				url: url,
				viewObject: viewObject,
				data: {
					requestId: self.getRandomToken()
				},
				callback: function (data) {
					var response = data.response || {},
						inputData = data.options.data || {},
						apiStatus = data.status || 'ERROR',
						status = response.status || 'ERROR',
						self = inputData.viewObject || this;

					if (apiStatus === "SUCCESS") {
						if (status === "SUCCESS") {

							callback.call(viewObject, {
								requestId: inputData.requestId,
								salt: response.data
							})

						} else {
							// Exception - 
						}
					} else {
						// API issue - 500, 400, 404
					}
				}
			});


		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getAccessToken|" + error, null, 1);
		}
	};

	Utils.getRandomToken = function () {
		try {
			return cryptoJs.AES.encrypt("UZBUH", new Date().getTime().toString()).toString()
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:getRandomToken|" + error, null, 1);
		}
	};
	Utils.decryptToken = function (params) {
		try {
			params = params || {};

			var plainText, originalText;

			plainText = cryptoJs.AES.decrypt(params.hash, params.key);
			originalText = plainText.toString(cryptoJs.enc.Utf8);

			return originalText;

		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:decryptToken|" + error, null, 1);
		}
	};
	/**
	 * Decrypt the password by using forge.js AES-GCM
	 */
	Utils.forgeDecryptToken = function (params) {
		try {
			params = params || {};
			var cipherString = params.hash;
			var salt = params.key;
			var key = cipherString.substr(0, 32);
			var iv = cipherString.substr(32, 16);
			var encryptedStr = cipherString.substr(48);
			var tag = encryptedStr.substr(encryptedStr.length - 24);
			var encyptedText = encryptedStr.substr(0, encryptedStr.length - 24);
			key = forge.util.decode64(key);
			iv = forge.util.decode64(iv);
			tag = forge.util.decode64(tag);
			// dec some bytes using GCM mode
			var deCipher = forge.cipher.createDecipher('AES-GCM', key);
			deCipher.start({
				iv: iv,
				additionalData: salt, // optional
				// tagLength: 128, // optional, defaults to 128 bits
				tag: {
					data: tag,
					read: 0
				}
			});

			deCipher.update(forge.util.createBuffer(forge.util.decode64(encyptedText)));
			deCipher.finish();
			var decrypted = deCipher.output;
			decrypted = decrypted.toString();
			return decrypted;
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Common Utils", "file:commonutils.js|function:forgeDecryptToken|" + error, null, 1);
		}
	};

	return Utils;
});	 