
define('pdpBidCenter',['jquery', 'underscore', 'backbone', 'bootstrap', 'handlebars', 'uiPagination', 'pdpcommon', 'commonutils', 'documentsView', 'hbs!tpl/pdpBidDeposit', "GoogleAnalyticsUtils", 'loginRegisterView', 'userCheckView', 'dUtil', 'hbs!tpl/placeBestBidPDP', 'hbs!tpl/placeBestBidDetailsModal', 'hbs!tpl/confirmNegotiationModal'],
    function ($, _, Backbone, bootstrap, Handlebars, uiPagination, pdpCommon, commonutils, DocumentsView, pdpBidDeposit, GAUtils, loginRegisterView, userCheckView, dUtil, placeBestBidTemp, placeBestBidDetails, confirmNegotiation) {
        'use strict';
        var userId = 'NA';
        var visitorID = "";
        var documentsView;
        /**
         * Added for place best bid and offer negotiation CMS content
         */
        if (typeof cmsJSONContent != 'undefined') {
            var placeBestBidLabelContent = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.placeBestBidLabel;
            placeBestBidLabelContent.pdpBackupBidButton = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.placebackupbid;
            placeBestBidLabelContent.underreviewdesc = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.underreviewdesc;

            var pdpBodyContent = cmsJSONContent.sections.pdpBodySection;
        }
        var pdpBidCenter = Backbone.View.extend({

            el: $("#pdpBidCenterInner"),
            events: {
                "click .open-bid-history-btn": "getBidHistory",
                //"click .proxy-Bid-Button" : "placeProxyBid"
            },
            initialize: function () {
                var self = this;
                (function () {
                    $("#ajax-loader, #ajax-loader-mask").hide();
                })($);



                console.log('inside bid center');
                self.bidCenterDetails = {};
                if (window.location.href.indexOf('openBstBid') > -1) {
                    self.openPlaceBestBidDetails();
                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
                    window.history.pushState(null, null, newurl);
                }

                if (window.location.href.indexOf('openConfirmOffer') > -1) {
                    self.openOfferNegotiationPopup();
                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
                    window.history.pushState(null, null, newurl);
                }
                var listingId = pdpDetails.businessListingId || "NA",
                    userId = window.userSession && window.userSession.userId || 'NA';
                visitorID = $.cookie('visitorId');
                //self = this;
                var isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false;
                if (isProxyBiddingEnabled) {
                    self.trackGALabel = "Property Id: " + propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId");
                } else {
                    self.trackGALabel = "listingId: " + listingId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId");
                }


                self.isMobile = $(window).width() < 768 ? true : false;
                $('.proxy-bid-button').off('click').click(function () {
                    self.pdpBidCenterSubmit();
                });
              
                $(window).scroll(function () {
                    var yPos = ($(window).scrollTop() || $("body").scrollTop());
                    var starting_position = 625;
                    if (yPos > starting_position && window.innerWidth > 767) { // show sticky menu after these many (starting_position) pixels have been scrolled down from the top and only when viewport width is greater than 500px.
                        $(".btn-grp").hide(); // hide save print options
                        if (isProxyBiddingEnabled) {
                            if ($("#pdpBidCenterInner .proxy-bid-button").is(':visible') && !$('.sticky-nav .right-section').hasClass('active')) {
                                $('.sticky-nav .right-section').removeClass('hide');
                                $('.sticky-nav .right-section').addClass('active');
                            }
                        } else {
                            if (!$('.sticky-nav .right-section').hasClass('active')) {
                                $('.sticky-nav .right-section').removeClass('hide');
                                $('.sticky-nav .right-section').addClass('active');
                            }
                        }

                    } else {
                        $(".btn-grp").show(); // show save print options
                        $('.sticky-nav .right-section').addClass('hide');
                        $('.sticky-nav .right-section').removeClass('active');
                    }
                });

                self.isBidDepositRequired = 'N';
                self.auctionEndTime = '';
                self.timeLeft = '';
                self.mobileVerificationEnabled = true;
                documentsView = new DocumentsView({ el: $(".documents-container") });
            },

            getBidHistory: function (event) {
                try {

                    var URL = typeof contextPath !== "undefined" ? contextPath : "",
                        pdpDetails = typeof window.pdpDetails !== "undefined" ? window.pdpDetails : {},
                        listingId = pdpDetails.businessListingId || "",
                        self = this,
                        userId = window.userSession && window.userSession.userId || null;

                    if (!listingId) return;
                    // If user is logged in 
                    if(userId) {
                        GAUtils.eventTracking.trackevent("", "ViewBidHistoryChart", this.trackGALabel);

                        // show loader
                        URL += "/portal/bidHistory/" + listingId + "?timeStamp=" + (new Date().getTime());
    
                        $("#bidCenterPopup").modal("show");
                        $("#bidCenterPopup .modal-body").html("<div class='modal-loader'>Loading...</div>")
    
                        $.ajax({
                            url: URL,
                            type: 'GET',
                            success: function (response) {
                                response = response || {};
                                var bidHistoryList = response.data.bidHistoryList || [],
                                    historyTemplate, bidDate, bidDetailTemplate, bidDetail;
    
                                if (!bidHistoryList.length) {
                                    // hide loader
                                    return;
                                }
    
                                historyTemplate = $("#bidHistoryPopupTemplate").html();
                                historyTemplate = Handlebars.compile(historyTemplate);
    
                                // Test data
                                // var bid = bidHistoryList[0];
                                // for (var i = 0 ; i < 10 ; i++) {
                                //     bidHistoryList.push(bid);
                                // }
    
                                var fcStateConfig = cmsJSONContent.sections.pdpBodySection.bidHistory.bidHistoryTxt.config,
                                    stateCode = typeof window.stateCode !== "undefined" ? window.stateCode : '',
                                    isFcOnlineProperty = false,
                                    fcInfo = '';
    
                                if (fcStateConfig.indexOf(stateCode) > -1) {
                                    fcInfo = cmsJSONContent.sections.pdpBodySection.bidHistory.bidHistoryTxt[stateCode].text;
                                    isFcOnlineProperty = true;
                                }
    
                                historyTemplate = historyTemplate({
                                    bidders: response.numberOfBidders || '',
                                    bids: response.totalBids || '',
                                    fcInfo: fcInfo,
                                    isFcOnlineProperty: isFcOnlineProperty
                                });
                                $("#bidCenterPopup .modal-body").html(historyTemplate);
    
                                // Apply Pagination 
                                bidDetailTemplate = $("#bidHistoryItem").html();
                                bidDetailTemplate = Handlebars.compile(bidDetailTemplate);
    
                                var isMobile = $(window).width() < 768 ? true : false;
    
                                if (bidHistoryList.length > 6 && !isMobile) {
                                    self.applyBidHistoryPagination({
                                        bidDetailTemplate: bidDetailTemplate,
                                        bidHistoryList: bidHistoryList
                                    });
                                } else {
                                    bidDetail = bidDetailTemplate({
                                        bidsList: bidHistoryList
                                    });
                                    $('#bidCenterPopup .bh-body').html(bidDetail);
                                }
                            },
                            error: function (xhr, status, error) {
    
                            }
                        })
                    } else {
                        // promt user to login 
                        $('#loginNewPopup').click();
                    }
                   

                } catch (error) {
                    console.error(error);
                }
            },
            applyBidHistoryPagination: function (options) {
                try {

                    options = options || {};

                    var bidDetail, pageEnd, pageStart;

                    $('#bidCenterPopup .bh-pagination').uiPagination({
                        dataSource: options.bidHistoryList,
                        pageSize: 6,
                        callback: function (data, pagination) {

                            bidDetail = options.bidDetailTemplate({
                                bidsList: data
                            });
                            $('#bidCenterPopup .bh-body').html(bidDetail);

                            pageEnd = pagination.pageNumber * pagination.pageSize;
                            pageStart = pageEnd - pagination.pageSize;

                            pageEnd = pageEnd > pagination.totalNumber ? pagination.totalNumber : pageEnd;
                            pageStart = pageStart <= 0 ? 1 : (pageStart + 1);

                            $(".bh-pagination-info").html(pageStart + ' - ' + pageEnd + ' of ' + pagination.totalNumber + ' bids');
                        }
                    });

                } catch (error) {
                    console.error(error);
                }
            },
            render: function () {
                return this;
            },


            /*
               Bid center poll to update the lastest listing status from DB
               All active listings updated with the latest highest bid amount, reserve met status updates
               In case of change in property status A new API call will be made changePropertyStatus() ;
   
               Params: Options: will be having a callback function in case of timer expiration
   
           */


            pdpBidCenterPoll: function (options) {
                options = options || {};
                /*if (this.pdpBidCenterPollProcess==true) return;*/
                var that = this,
                    isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false,
                    _propertyId = typeof pdpDetails !== "undefined" ? pdpDetails.propertyId : '',
                    bListingId = typeof pdpDetails !== "undefined" ? pdpDetails.businessListingId : '',
                    url = contextPath + "/portal/latestBidCenter/" + bListingId;
                that.pdpBidCenterPollProcess = true;
                if (isProxyBiddingEnabled) {
                    url = contextPath + "/portal/latestBidCenter/" + _propertyId + "?isFcAuction=Y";
                }

                if (!isProxyBiddingEnabled && !bListingId) return;

                $.ajax({
                    dataType: "json",
                    url: url,
                    type: "POST",
                    success: function (data) {
                        if (data) {
                            
                            that.bidCenterDetails = data;
                            console.log('bidCenterDetails', that.bidCenterDetails);
                            window.cifCompleted = (window.cifCompleted) ? window.cifCompleted : that.bidCenterDetails.cifCompleted;
                            
                            if (isProxyBiddingEnabled) {


                                if ((fcAuctionStatus !== data.fcAuctionStatus) || data.propertyStatus !== propertyStatus) {
                                    var _auctionId = isProxyBiddingEnabled ? data.fcAuctionId : null;
                                    var _status = isProxyBiddingEnabled ? data.fcAuctionStatus : null;
                                    propertyStatus = data.propertyStatus;
                                    that.changePropertyStatus(_auctionId, _status);
                                }
                                var isMobile = $(window).width() < 768 ? true : false;
                                if (isMobile) {
                                    if (data.fcAuctionStatus == "TRANSACTION_COMPLETED" || propertyStatus == "AUCTION_EVENT_ENDED") {
                                        $('.bid-center-mobile').addClass('hide');
                                    }

                                    if (data.fcAuctionStatus == "CRY_STARTED" || data.fcAuctionStatus == "CRY_PAUSED" || data.fcAuctionStatus == "CLEAR_FOR_SALE_RESCHEDULED" || data.fcAuctionStatus == "TRANSACTION_PENDING" || data.fcAuctionStatus == "TRANSACTION_COMPLETED") {
                                        $(".sticky-nav").removeClass('fcp-action').addClass("hide");
                                    }
                                }

                            } else {
                                var auctionTimeLeft = Math.floor(data.auctionTimeLeft / 1000);

                                if (options && options.callback) {
                                    if (auctionTimeLeft <= 0) {
                                        options.callback(options);
                                    }
                                }

                                // Listing start date time for  WFA property
                                if (data.propertyStatus === "WFA" && data.listingStartTime) {

                                    var listStartTime = new Date(data.listingStartTime) - new Date();
                                    listStartTime = Math.floor(listStartTime / 1000) || 0;
                                    if (listStartTime > 0) {
                                        $(".wfa-prop-pdp-bid-center .wfa-listing-start-timer").slideDown();
                                        that.initTimer(listStartTime);
                                    } else {
                                        $(".wfa-prop-pdp-bid-center .wfa-listing-start-timer").slideUp();
                                    }
                                }

                                var calcView = window.htimestamp && window.htimestamp.calculator || '';
                                if (calcView) {
                                    if (data.propertyStatus === "ACTV") {
                                        calcView.bidAmount = data.auctionTimeLeft > 0 ? data.nextpossibleBidAmount : calcView.bidAmount;

                                    }
                                    if (data.propertyStatus === "SPND" && data.highestBidAmount) {
                                        calcView.bidAmount = data.highestBidAmount && data.highestBidAmount || calcView.bidAmount;

                                        if (bidVariation == 4) {
                                            $("#stick-place-backup-bid,#gallery-place-backup-bid").removeClass("hide");
                                        }
                                    }
                                    if (data.propertyStatus === "REVIEW" && data.highestBidAmount) {
                                        calcView.bidAmount = data.highestBidAmount && data.highestBidAmount || calcView.bidAmount;

                                        if (bidVariation == 7) {
                                            $("#stick-place-backup-bid,#gallery-place-backup-bid").removeClass("hide");
                                        }
                                    }
                                    if (calcView.calcApiResponse && calcView.bidAmount) {
                                        calcView.renderInvestmentCalculator(calcView.calcApiResponse);
                                    }
                                }

                                if (data.propertyStatus == 'ACTV' && typeof that.pdpBidtimer != 'undefined') {

                                    if (Math.abs(auctionTimeLeft - that.pdpBidtimer.bidLeftTime) > 30) {
                                        that.initTimer(auctionTimeLeft);
                                    }
                                    $(".bid-deposit-tooltip").show();
                                }



                                if (data.propertyStatus != propertyStatus) {
                                    propertyStatus = data.propertyStatus;
                                    if (listingType == 'AUCN') {
                                        if (typeof bulkProperty !== 'undefined' && bulkProperty !== 'Y') {
                                            that.changePropertyStatus();
                                        }
                                    }
                                }
                                else if (propertyStatus == 'ACTV' && data.highestBidAmount > 0) {
                                    if (!data.clientCodeRestrictedFlag) {
                                        if (data.reservePriceMet == 'YES' && !isInvestorAprvlReqd) {
                                            var bidReserveMetTxt = bidReserveMetTxt || 'Met';
                                            $('.reserve-met-status').html(bidReserveMetTxt);
                                            $(".own-it-now-enabled").hide();
                                            $('.reserve-met-status').html(bidReserveMetTxt);
                                            var viewHistoryText = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.viewallbids;
                                            $('.open-bid-history-btn').html(viewHistoryText + '(<span class="bid-history-count"></span>)');
                                            $(".bid-history-count").html(data.totalBids);
                                            // var reservePriceImg = '<span class="icon-reservemet propertyIconFont propertyIconParent reserveMetPropertyIcon"  aria-label="'+commonComponentsLabels.reserveMet+'" tabindex="0"><span class="path1 hotPropertyIcon1 propertyIconFontSize"></span><span class="path2 hotPropertyIcon2 propertyIconFontSize"></span><span class="propertyTagSpan">'+commonComponentsLabels.reserveMet+'</span></span>';
                                            $(".hotPropertyIconTag").find('.icon-reservemet').remove();
                                            var reserveMetIcon = '<svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                                                '<defs>' +
                                                '<circle id="path-rm-icon-1" cx="14" cy="14" r="14"></circle>' +
                                                '<filter x="-25.0%" y="-17.9%" width="150.0%" height="150.0%" filterUnits="objectBoundingBox" id="filter-2">' +
                                                '<feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>' +
                                                '<feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>' +
                                                '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>' +
                                                '</filter>' +
                                                '</defs>' +
                                                '<g id="Designs-v2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
                                                '<g id="Homepage---Desktop---Returning,-Not-Logged-In" transform="translate(-818.000000, -934.000000)">' +
                                                '<g id="Group-4" transform="translate(66.000000, 924.000000)">' +
                                                '<g id="property/card/app/boxed-copy-2" transform="translate(672.000000, 0.000000)">' +
                                                '<g id="card/tags/hot-property-copy-2" transform="translate(84.000000, 12.000000)">' +
                                                '<g id="Oval">' +
                                                '<use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-rm-icon-1"></use>' +
                                                '<use fill="#8FB035" fill-rule="evenodd" xlink:href="#path-rm-icon-1"></use>' +
                                                '</g>' +
                                                '<g id="icon/flame" transform="translate(5.250000, 5.250000)" fill="#F4F7FA">' +
                                                '<path d="M8.75,0.21875 C13.4616793,0.21875 17.28125,4.03832073 17.28125,8.75 C17.28125,13.4616793 13.4616793,17.28125 8.75,17.28125 C4.03832073,17.28125 0.21875,13.4616793 0.21875,8.75 C0.21875,4.03832073 4.03832073,0.21875 8.75,0.21875 Z M8.75,1.53125 C4.76319446,1.53125 1.53125,4.76319446 1.53125,8.75 C1.53125,12.7368055 4.76319446,15.96875 8.75,15.96875 C12.7368055,15.96875 15.96875,12.7368055 15.96875,8.75 C15.96875,4.76319446 12.7368055,1.53125 8.75,1.53125 Z M12.670121,6.05835479 C12.9232408,6.2692879 12.9773417,6.63045379 12.8107614,6.90356694 L12.7541452,6.98262101 L8.33903883,12.2765388 L8.29831493,12.3140147 L8.23133905,12.3637801 L8.14931811,12.4088333 L8.08383158,12.4347887 L8.01814942,12.4530225 L7.95455975,12.4639114 L7.87035129,12.4687525 L7.76786689,12.4600525 L7.67204162,12.4367604 L7.61556385,12.4154769 L7.56967627,12.3935911 L7.4845648,12.3400797 L7.45487899,12.3166452 L7.41096117,12.2765388 L4.78596117,9.65153883 C4.52967961,9.39525726 4.52967961,8.97974274 4.78596117,8.72346117 C5.01894442,8.49047793 5.38352514,8.46929764 5.6404352,8.65992029 L5.71403883,8.72346117 L7.830375,10.8395 L11.7458548,6.14237899 C11.9778812,5.86394727 12.3916893,5.82632836 12.670121,6.05835479 Z" id="Combined-Shape"></path>' +
                                                '</g>' +
                                                '</g>' +
                                                '</g>' +
                                                '</g>' +
                                                '</g>' +
                                                '</g>' +
                                                '</svg>';
                                            var reservePriceImg = '<span class="icon-reservemet propertyIconFont propertyIconParent reserveMetPropertyIcon"  aria-label="' + commonComponentsLabels.reserveMet + '" tabindex="0">' + reserveMetIcon + '</span>';
                                            $(".hotPropertyIconTag").append(reservePriceImg);
                                        } else {
                                            $(".own-it-now-enabled").show();
                                        }
                                    }

                                    that.pdpBidCenterPollProcess = false;
                                    if (data.bidIncrement && data.bidIncrement > 0) {
                                        biddingIncrementalAmount = bidIncrementRange = data.bidIncrement;
                                    }



                                    if (data.reservePriceMet == 'YES' & fcOnlineFlag == 'Y' && typeof data.incrementMultiplier != 'undefined' && data.incrementMultiplier && data.incrementMultiplier > 0) {
                                        var bidIncrementMultiplier = data.incrementMultiplier;
                                        var customBidEnabled = $.cookie('customBidEnabled');
                                        var customBidIncrementMultiplier = data.customBidIncrementMultiplier || 0;
                                        if (customBidEnabled == 'Y' && customBidIncrementMultiplier != 0) {
                                            bidIncrementMultiplier = customBidIncrementMultiplier;
                                        }
                                    }


                                    if (data.nextpossibleBidAmount && data.nextpossibleBidAmount > 0) {
                                        bidMinLimit = data.nextpossibleBidAmount;
                                    } else {
                                        bidMinLimit = data.highestBidAmount + bidIncrementRange;
                                    }
                                    if (data.maximumPossibleBidAmount && data.maximumPossibleBidAmount > 0) {
                                        bidMaxLimit = data.maximumPossibleBidAmount;
                                    }



                                    //update the amount on 5Arch banner based on CHB value

                                    var fiveArchPercentage = (data.highestBidAmount * Number(fiveArchPercent)) / 100;
                                    $(".fiveArchPrice").text('$' + fiveArchPercentage.formatMoney());
                                    $('.adPrice').text('$' + fiveArchPercentage.formatMoney());



                                    if (bidMinLimit > bidMaxLimit && bidMaxLimit > 0) {
                                        bidMinLimit = bidMaxLimit;
                                    }

                                    if (data.displayListPriceForAuction != null && data.displayListPriceForAuction == "Y") {
                                        $('.bid-price-label').html(listingPriceTxt);
                                    } else {
                                        $('.bid-price-label').html(currentHighestBidTxt);
                                    }
                                    $('.pdp-bid-price .bid-price a').removeClass('bidCount-0');

                                    bidCount = 1;
                                    $('.pdp-bid-price .bid-price a span').html(bidCount);
                                    $('.pdp-bid-price .bid-price a').addClass('bidCount-' + bidCount);


                                    if (that.bidInputFocus == false) {
                                        var highestprice = data.highestBidAmount;
                                        highestprice = highestprice.formatMoney();
                                        if (data.displayListPriceForAuction != null && data.displayListPriceForAuction == "Y") {
                                            $('.bid-price-val').html(listingPrice);
                                        } else {
                                            $('.bid-price-val').html('$' + highestprice);
                                        }



                                        $('.bid-plus-minus input').each(function (e) {
                                            var bidval = parseInt($(this).val().replaceAll(',', '').replaceAll('$', ''));
                                            if ($(this).attr('disabled') != 'disabled' && (bidval < bidMinLimit || bidval > bidMaxLimit) && $('.bid-error-msg').css('display') == 'none') {
                                                $(this).val(bidMinLimit);
                                                that.pdpFormatMoney(this);

                                            }
                                        });
                                    }
                                }
                                else {
                                    that.pdpBidCenterPollProcess = false;
                                }

                                if (propertyStatus !== 'SLD' || propertyStatus !== 'WLST') {
                                    $(".bid-deposit-tooltip").show();
                                } else {
                                    $(".bid-deposit-tooltip").hide();
                                }

                                // If property status is not active, hide bid center in light box.
                                if (propertyStatus === 'ACTV' && auctionTimeLeft < 0) {
                                    $(".lightbox-bid-details").hide();
                                }

                            }
                            if (typeof FCOnlineReserveMetText !== 'undefined' && isForeClosure == 'Y' && fcOnlineFlag == 'Y') {
                                $(".pdp-bid-tooltip .tooltipPopdesc").html(FCOnlineReserveMetText);
                            }

                        }
                       
                    }
                });
            },

            pdpFormatMoney: function (obj) {
                this.bidInputFocus = false;
                var bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', ''));
                this.pdpBidError = true;
                var errormsg = '';
                var spanVal = '';
                var maxerror = false;
                var that = this;
                var isblur = false;
                that.isMobile = false;
                var content = placeBestBidLabelContent.errorMsg;
                var isActiveBidRefinementEnabled = this.bidCenterDetails.activeBidRefinementEnabled,
                    lowerThresholdAmount = this.bidCenterDetails.lowerThresholdAmount,
                    upperThresholdAmount = this.bidCenterDetails.upperThresholdAmount,
                    lowTreshInc = parseInt(placeBidContent.bidConfig.lowTreshInc),
                    highTreshInc = parseInt(placeBidContent.bidConfig.highTreshInc),
                    nextpossibleBidAmount = this.bidCenterDetails.nextpossibleBidAmount,
                    lowTreshIncMultiplier = this.bidCenterDetails.lowerThresholdIncrementMultiplier,
                    highTreshIncMultiplier = this.bidCenterDetails.upperThresholdIncrementMultiplier,
                    customCurrentHighestBid = currentHighestBid ? currentHighestBid : (this.bidCenterDetails.nextpossibleBidAmount - this.bidCenterDetails.incrementMultiplier),
                    customErrorMax = placeBidContent.placeBidError['MAX_BID_AMOUNT'];

                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Opera Mobile|Kindle|Windows Phone|PSP|AvantGo|Atomic Web Browser|Blazer|Chrome Mobile|Dolphin|Dolfin|Doris|GO Browser|Jasmine|MicroB|Mobile Firefox|Mobile Safari|Mobile Silk|Motorola Internet Browser|NetFront|NineSky|Nokia Web Browser|Obigo|Openwave Mobile Browser|Palm Pre web browser|Polaris|PS Vita browser|Puffin|QQbrowser|SEMC Browser|Skyfire|Tear|TeaShark|UC Browser|uZard Web|wOSBrowser|Yandex.Browser mobile/i.test(navigator.userAgent)) that.isMobile = true;


                if (typeof obj.event != 'undefined' && typeof obj.event.type != 'undefined' && obj.event.type == 'blur') {
                    isblur = true;
                }
                if (isNaN(bidval)) {
                    errormsg = biderrorempty;
                }
                else if (currentHighestBid >= bidMaxLimit && listingType == 'AUCN') {
                    errormsg = biderrorexceed;

                }
                else if (bidval < bidMinLimit) {
                    errormsg = biderrormin;
                    spanVal = '$' + bidMinLimit.formatMoney();
                    // } else if(that.bidCenterDetails.activeBidRefinementEnabled && currentHighestBid < that.bidCenterDetails.lowerThresholdAmount && bidval >  (that.bidCenterDetails.lowerThresholdAmount + (that.bidCenterDetails.lowerThresholdIncrementMultiplier * parseInt(placeBidContent.bidConfig.lowTreshInc)))){
                    //     errormsg = "Maximum possible bid amount is ";
                    //     spanVal = '$' + bidMaxLimit.formatMoney();
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount <= (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))
                    && bidval > (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))) {
                    var maxBidAmount = (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier));
                    errormsg = customErrorMax.replace('###MAX_BID_AMOUNT###', maxBidAmount.formatMoney());
                    spanVal = '$' + bidMaxLimit.formatMoney();
                    maxerror = true;
                    // } else if(isActiveBidRefinementEnabled && nextpossibleBidAmount < lowerThresholdAmount 
                    //     && bidval >= (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier) + (lowTreshIncMultiplier * lowTreshInc)) 
                    //     && bidval >= upperThresholdAmount){
                    //     var maxBidAmount = (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier));
                    //     errormsg = customErrorMax.replace('###MAX_BID_AMOUNT###', maxBidAmount.formatMoney());
                    //     spanVal = '$' + bidMaxLimit.formatMoney();
                    //     maxerror = true;
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                    && bidval > (customCurrentHighestBid + (lowTreshIncMultiplier * lowTreshInc))
                    && (nextpossibleBidAmount < upperThresholdAmount || ((nextpossibleBidAmount < upperThresholdAmount && bidval >= upperThresholdAmount) || (nextpossibleBidAmount < upperThresholdAmount && bidval > bidMaxLimit)))) {
                    var maxBidAmount = customCurrentHighestBid + (lowTreshIncMultiplier * lowTreshInc);
                    errormsg = customErrorMax.replace('###MAX_BID_AMOUNT###', maxBidAmount.formatMoney());
                    spanVal = '$' + bidMaxLimit.formatMoney();
                    maxerror = true;
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                    && nextpossibleBidAmount <= (upperThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))
                    && bidval > (upperThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))) {
                    var maxBidAmount = (upperThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier));
                    errormsg = customErrorMax.replace('###MAX_BID_AMOUNT###', maxBidAmount.formatMoney());
                    spanVal = '$' + bidMaxLimit.formatMoney();
                    maxerror = true;
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                    && nextpossibleBidAmount > (upperThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))
                    && (bidval > (customCurrentHighestBid + (highTreshIncMultiplier * highTreshInc)) || bidval > bidMaxLimit)) {
                    var maxBidAmount = customCurrentHighestBid + (highTreshIncMultiplier * highTreshInc);
                    errormsg = customErrorMax.replace('###MAX_BID_AMOUNT###', maxBidAmount.formatMoney());
                    spanVal = '$' + bidMaxLimit.formatMoney();
                    maxerror = true;
                } else if (bidval > bidMaxLimit) {
                    errormsg = biderrormax;
                    spanVal = '$' + bidMaxLimit.formatMoney();
                    maxerror = true;
                } else if ($('#pdp-bid-form').length != 0 && (bidval - bidMinLimit) % bidIncrementMultiplier != 0 && bidval != bidMaxLimit && bidval != bidMinLimit && firstBidKyState == "N") {
                    errormsg = biderrorincrement;
                    spanVal = '$' + bidIncrementMultiplier.formatMoney();
                }

                if (bidval <= bidMinLimit) {
                    $('.bid-plus-minus .bid-minus').addClass('disable');
                    // } else if(isActiveBidRefinementEnabled && nextpossibleBidAmount < lowerThresholdAmount 
                    //     && bidval >= (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier)+ (lowTreshIncMultiplier * lowTreshInc)) 
                    //     && bidval < upperThresholdAmount){
                    //     $('.bid-plus-minus .bid-minus').removeClass('disable');
                    //     $('.bid-plus-minus .bid-plus').addClass('disable');
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount <= lowerThresholdAmount
                    && bidval >= lowerThresholdAmount
                    && bidval < upperThresholdAmount) {
                    $('.bid-plus-minus .bid-minus').removeClass('disable');
                    $('.bid-plus-minus .bid-plus').addClass('disable');
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount < lowerThresholdAmount
                    && bidval >= (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier) + (lowTreshIncMultiplier * lowTreshInc))
                    && bidval >= upperThresholdAmount) {
                    $('.bid-plus-minus .bid-minus').removeClass('disable');
                    $('.bid-plus-minus .bid-plus').addClass('disable');
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                    && bidval >= (customCurrentHighestBid + (lowTreshIncMultiplier * lowTreshInc))
                    && bidval < upperThresholdAmount) {
                    $('.bid-plus-minus .bid-minus').removeClass('disable');
                    $('.bid-plus-minus .bid-plus').addClass('disable');
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                    && nextpossibleBidAmount <= (upperThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))
                    && bidval > (upperThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier))) {
                    $('.bid-plus-minus .bid-minus').removeClass('disable');
                    $('.bid-plus-minus .bid-plus').addClass('disable');
                } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                    && bidval >= (upperThresholdAmount + (highTreshIncMultiplier * highTreshInc))) {
                    $('.bid-plus-minus .bid-minus').removeClass('disable');
                    $('.bid-plus-minus .bid-plus').addClass('disable');
                }
                else if (bidval >= bidMaxLimit) {
                    $('.bid-plus-minus .bid-plus').addClass('disable');
                }

                if (errormsg != '') {


                    if (that.pdpBidError == true) {

                        if (that.isMobile) {
                            $('#bidErrorToast .bidErrorMsg').html(errormsg);
                            $('#bidErrorToast .bidErrorMsg span').html(spanVal);
                            if (isblur) {

                                $('#bidErrorToast').fadeIn('300').delay(2000).fadeOut('slow');
                            }

                        } else {

                            $('.bid-error-msg').html(errormsg);
                            $('.bid-error-msg span').html(spanVal);
                            $('.bid-error-msg').show();
                        }
                    }

                    if (maxerror) {
                        $('.stickynav-desktop .lightbox-header .bid-error-msg').css("margin-left", "-50px");
                    } else {
                        $('.stickynav-desktop .lightbox-header .bid-error-msg').css("margin-left", "0");
                    }



                }
                else {
                    this.pdpBidError = false;
                    $('.bid-error-msg').hide();
                    $('#bidErrorToast').fadeOut();

                    if (bidval == bidMaxLimit) {

                        if (that.isMobile) {
                            $('#bidErrorToast .bidErrorMsg').html(biderrorreachedmax);
                            $('#bidErrorToast').fadeIn('300').delay(2000).fadeOut('slow');


                        } else {

                            $('.bid-error-msg').html(biderrorreachedmax);
                            $('.bid-error-msg').show();
                            if (maxerror) {
                                $('.stickynav-desktop .lightbox-header .bid-error-msg').css("margin-left", "-50px");
                            } else {
                                $('.stickynav-desktop .lightbox-header .bid-error-msg').css("margin-left", "0");
                            }
                        }
                    }
                }


                if (!isNaN(bidval)) {
                    var moneyFormat = bidval.formatMoney();
                    $('.bid-plus-minus input').val('$' + moneyFormat);
                    $('.bidAmount').val(bidval);
                } else {
                    $('.bid-plus-minus input').val('');
                    $('.bidAmount').val('');
                }

                // if(this.pdpBidError==false)
                // {
                //     if(this.prevBidVal!=bidval)
                //     {
                //         $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                //         $('#buyerPremium').html(content.loading);
                //         $('#techFee').html(content.loading);
                //         $('#totalDue').html(content.loading);

                //         this.buyersPremium(bidval);

                //     }else{
                //         $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled');
                //     }

                //     this.prevBidVal = bidval;

                // } else {
                //     $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                // }
            },
            initTimer: function (leftTime) {

                if (listingType != undefined && listingType == 'TRNL') { return; }

                var that = this;

                if (that.pdpBidtimer != undefined) {
                    clearInterval(that.pdpBidtimer.interval);
                }

                that.pdpBidtimer = new pdpCommon(leftTime);
                that.pdpBidtimer.onexpired = that.pdpBidExpire;
                that.pdpBidtimer.daysClass = 'bid-days';
                that.pdpBidtimer.hoursClass = 'bid-hours';
                that.pdpBidtimer.minsClass = 'bid-mins';
                that.pdpBidtimer.secsClass = 'bid-secs';
                that.pdpBidtimer.timeClass = 'bid-time';
                that.pdpBidtimer.time15Class = 'bid-time-15';
                that.pdpBidtimer.context = that;
                that.pdpBidtimer.init();

            },
            showBidDeposit: function () {
                //if(bidParticipationFlag && bidParticipationFlag == 'Y') {
                // var data = {"tooltip": bidDepositContent.bidDepositTooltip.replace('###BP_AMOUNT###', bidParticipationFee), "content":bidDepositContent.bidDepositContent};
                var data = { "tooltip": bidDepositContent.bidDepositTooltip, "content": bidDepositContent.bidDepositContent };
                $("#bidDepositContainer").html(pdpBidDeposit(data));

                $(".bid-deposit-tooltip").removeClass("hide");

                $(document).on('click', '.tooltipPop', function (e) {
                    e.stopPropagation();
                    var next = $(this).next();

                    if (next.css('display') == 'none') {
                        $('.tooltipPopdesc').fadeOut('fast');
                        if (next.find('.uparrow').length == 0) {
                            var html = next.html();
                            next.html('<div class="uparrow"></div><div class="popcontent">' + html + '</div>');
                        }
                        var position = $(this).position();
                        var ptop = position.top;
                        var firstWidth = next.outerWidth();
                        var pleft = position.left - firstWidth + 35;

                        if (pleft < 0) {
                            pleft = 20;
                        }
                        next.css({ "left": pleft + 'px', "top": (ptop + 33) + 'px' });
                        var secondWidth = next.outerWidth();
                        next.fadeIn('fast');
                        pleft = $(this).position().left - next.position().left;
                        next.find('.uparrow').css({ "left": (pleft + 1) + 'px' });
                    }

                });
                //  }
            },
            notifyTimeLeft: function (timeLeftInSeconds) {
                var self = this;
                if (!timeLeftInSeconds || timeLeftInSeconds <= 0 || !self.notifyAuctionTimeLeft) {
                    return false;
                }
                var minAuctionTimeLeftSec = cmsJSONContent.config.minAuctionTimeLeftSec || 86400;
                if (timeLeftInSeconds > minAuctionTimeLeftSec) {
                    return false;
                }
                var mins = Math.floor(timeLeftInSeconds / 60) % 60;
                var hours = Math.floor(timeLeftInSeconds / 60 / 60) % 24;
                var days = Math.floor(timeLeftInSeconds / 60 / 60 / 24);
                var timeLeft;
                if (days == 0 && hours > 0) {
                    timeLeft = hours + (hours == 1 ? " hour" : " hours");
                } else if (days == 0 && hours == 0 && mins > 0) {
                    timeLeft = "last few minutes";
                }
                if (timeLeft) {
                    self.notifyAuctionTimeLeft = false;
                    var msg = self.replaceDynamicParam(cmsJSONContent.sections.pdpToastMessages.timeLeft, "TIME_LEFT", timeLeft)
                    $.queueItem({
                        displayTime: toastMsgdisplayTime,
                        message: msg,
                        container: 'auctiontimeleft',
                        callback: self.showFloatingToastMessage
                    });
                }
                return true;
            },

            addUserConnection: function (userConnectionsNodeUrl, visitorId) {
                if (!visitorId) {
                    return;
                }
                try {
                    var self = this;
                    var alertView = window.globalAlertView || null;
                    var connectionsNodeReference = alertView.firebaseApp.database().ref(userConnectionsNodeUrl);
                    var connectionsList = [], deletedConnectionId;
                    connectionsNodeReference.once('value', function (snapshot) {
                        snapshot.forEach(function (child) {
                            connectionsList.push({
                                id: child.key,
                            });
                        });
                        connectionsNodeReference.on('child_added', function (snapshot) {
                            if (!self.isDuplicateConnection(connectionsList, snapshot.key)) {
                                connectionsList.push({
                                    id: snapshot.key
                                });
                                if (connectionsList.length > 1 && snapshot.key != deletedConnectionId) {
                                    var msg = self.replaceDynamicParam(cmsJSONContent.sections.pdpToastMessages.userViews, "USERS_COUNT", connectionsList.length.toString())
                                    $.queueItem({
                                        displayTime: toastMsgdisplayTime,
                                        message: msg,
                                        container: 'pdpviews',
                                        callback: self.showFloatingToastMessage
                                    });
                                }
                            }
                        }, function (error) {
                            alertView.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'child_added'
                            });
                        });
                        connectionsNodeReference.on('child_removed', function (snapshot) {
                            deletedConnectionId = snapshot.key;
                            var removeIndex = connectionsList.map(function (connection) { return connection.id; }).indexOf(snapshot.key);
                            if (removeIndex !== -1)
                                connectionsList.splice(removeIndex, 1);
                        }, function (error) {
                            alertView.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'child_removed'
                            });
                        });
                        // Add ourselves to presence list when online.
                        var presenceRef = alertView.firebaseApp.database().ref("/.info/connected");
                        presenceRef.on("value", function (snap) {
                            if (snap.val()) {
                                connectionsNodeReference.child(visitorId).onDisconnect().remove();
                                connectionsNodeReference.child(visitorId).set(true);
                            }
                        });
                    });
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:addUserConnection|" + e, null, 1);
                }
            },
            bidPlusMinus: function () {
                try {

                    var that = this;
                    that.bidInputFocus = false;

                    $('.bid-plus-minus input').each(function (e) {

                        $(this).keydown(function (e) {
                            that.bidInputFocus = true;
                            // Allow: backspace, delete, tab, escape, enter,  (comma), and $
                            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 188, 52]) !== -1 ||
                                // Allow: Ctrl+A
                                (e.keyCode == 65 && e.ctrlKey === true) ||
                                // Allow: home, end, left, right, down, up
                                (e.keyCode >= 35 && e.keyCode <= 40)) {
                                // let it happen, don't do anything
                                return;
                            }
                            // Ensure that it is a number and stop the keypress
                            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                                e.preventDefault();
                            }
                        });

                        $(this).change(function (e) {

                            that.bidFormatMoney(this);
                        });

                        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                            $(this).on('change textInput input', function () {
                                that.bidFormatMoney(this);
                            });
                        }

                        that.bidFormatMoney(this);

                    });

                    $('.bid-plus-minus .bid-plus').off('click').click(function (e) {

                        var obj = $(this).prev();
                        var bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', '')) + that.bidIncrementRange;

                        if (isNaN(bidval)) {
                            bidval = that.bidMinLimit;
                        }

                        if ((bidval - that.bidMinLimit) % that.bidIncrementRange != 0) {
                            bidval = that.bidMinLimit + Math.floor((bidval - that.bidMinLimit) / that.bidIncrementRange) * that.bidIncrementRange;
                        }
                        that.bidPlusMinusPress(bidval, obj);
                        that.bidFormatMoney(obj);
                        GAUtils.eventTracking.trackevent("Best Bid Wizard", "Button Click", "Increase");
                    });

                    $('.bid-plus-minus .bid-minus').off('click').click(function (e) {

                        var obj = $(this).next();
                        var bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', '')) - that.bidIncrementRange;

                        if (isNaN(bidval)) {
                            bidval = that.bidMinLimit;
                        }

                        if ((bidval - that.bidMinLimit) % that.bidIncrementRange != 0) {
                            bidval = that.bidMinLimit + (Math.floor((bidval - that.bidMinLimit) / that.bidIncrementRange) + 1) * that.bidIncrementRange;
                        }
                        that.bidPlusMinusPress(bidval, obj);
                        that.bidFormatMoney(obj);
                        GAUtils.eventTracking.trackevent("Best Bid Wizard", "Button Click", "Decrease");

                    });

                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:bidPlusMinus|" + e, null, 1);
                }

            },

            bidFormatMoney: function (obj) {
                try {
                    var that = this;
                    that.bidInputFocus = false;
                    var bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', ''));
                    that.editBidError = true;
                    var errormsg = '';
                    var spanVal = '';

                    var content = placeBestBidLabelContent.errorMsg;

                    $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                    if (isNaN(bidval)) {
                        errormsg = content.biderrorempty;
                    }
                    else if (that.currentHighestBid >= that.bidMaxLimit) {
                        errormsg = content.biderrorexceed;
                    }
                    else if (bidval < that.bidMinLimit) {
                        errormsg = content.biderrormin;
                        spanVal = '$' + that.bidMinLimit.formatMoney();
                    }
                    else if (bidval > that.bidMaxLimit) {
                        errormsg = content.biderrormax;
                        spanVal = '$' + that.bidMaxLimit.formatMoney();
                    }
                    else if ((bidval - that.bidMinLimit) % that.bidIncrementRange != 0 && bidval != that.bidMaxLimit && bidval != that.bidMinLimit && firstBidKyState == "N") {
                        errormsg = content.biderrorincrement;
                        spanVal = '$' + that.bidIncrementRange.formatMoney();
                    }

                    if (bidval <= that.bidMinLimit) {
                        $('.bid-plus-minus .bid-minus').addClass('disable');
                        $('.bid-plus-minus .bid-plus').removeClass('disable');
                    }
                    else if (bidval >= that.bidMaxLimit) {
                        $('.bid-plus-minus .bid-plus').addClass('disable');
                        $('.bid-plus-minus .bid-minus').removeClass('disable');
                    }
                    else {
                        $('.bid-plus-minus .bid-minus, .bid-plus-minus .bid-plus').removeClass('disable');
                    }

                    if (errormsg != '') {
                        setTimeout(function () {

                            if (that.editBidError == true) {
                                $('.bid-error-msg').html(errormsg);
                                $('.bid-error-msg span').html(spanVal);
                                $('.bid-error-msg').show();
                            }

                        });

                    }
                    else {
                        this.editBidError = false;
                        $('.bid-error-msg').hide();

                        if (bidval == that.bidMaxLimit) {
                            $('.bid-error-msg').html(content.biderrorreachedmax);
                            $('.bid-error-msg').show();
                        }
                    }


                    if (!isNaN(bidval)) {
                        var moneyFormat = '$' + bidval.formatMoney();
                        $('.bid-plus-minus input').val(moneyFormat);
                    } else {
                        $('.bid-plus-minus input').val('');
                    }

                    if (this.editBidError == false) {
                        if (this.prevBidVal != bidval) {
                            $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                            $('#buyerPremium').html(content.loading);
                            $('#techFee').html(content.loading);
                            $('#totalDue').html(content.loading);

                            this.buyersPremium(bidval);

                        } else {
                            $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled');
                        }

                        this.prevBidVal = bidval;

                    }
                    else {
                        $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                    }

                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:bidFormatMoney|" + e, null, 1);
                }

            },
            bidPlusMinusPress: function (bidval, obj) {
                try {
                    var that = this;


                    if (that.currentHighestBid >= that.bidMaxLimit) {
                        $('.bid-plus-minus .bid-plus,.bid-plus-minus .bid-minus').addClass('disable');
                        $('.bid-plus-minus input').attr('disabled', 'disabled');
                        return;
                    }
                    else if (bidval <= that.bidMaxLimit && bidval >= that.bidMinLimit) {
                        $('.bid-plus-minus .bid-plus,.bid-plus-minus .bid-minus').removeClass('disable');
                    }
                    else {
                        if (bidval < that.bidMinLimit) {
                            bidval = that.bidMinLimit;
                            $('.bid-plus-minus .bid-minus').addClass('disable');
                        }
                        else if (bidval > that.bidMaxLimit) {
                            bidval = Math.floor(that.bidMaxLimit / 1000) * 1000;;
                            $('.bid-plus-minus .bid-plus').addClass('disable');
                        }

                    }

                    $(obj).val('$' + bidval.formatMoney());

                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:bidPlusMinusPress|" + e, null, 1);
                }
            },
            pdpBidPlusMinus: function () {

                var that = this;
                that.bidInputFocus = false;

                /*  bidIncrementRange = parseInt(bidIncrementRange.replaceAll(',',''));
                    bidMinLimit = parseInt(bidMinLimit.replaceAll(',',''));
                    bidMaxLimit = parseInt(bidMaxLimit.replaceAll(',',''));*/

                $('.bid-plus-minus input').each(function (e) {

                    $(this).keydown(function (e) {
                        that.bidInputFocus = true;
                        if (that.bidCenterDetails.activeBidRefinementEnabled) {
                            $('.bid-plus-minus .bid-plus,.bid-plus-minus .bid-minus').removeClass('disable');
                        }
                        // Allow: backspace, delete, tab, escape, enter,  (comma), and $
                        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 188, 52]) !== -1 ||
                            // Allow: Ctrl+A
                            (e.keyCode == 65 && e.ctrlKey === true) ||
                            // Allow: home, end, left, right, down, up
                            (e.keyCode >= 35 && e.keyCode <= 40)) {
                            // let it happen, don't do anything
                            return;
                        }
                        // Ensure that it is a number and stop the keypress
                        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                            e.preventDefault();
                        }
                    });






                    $(this).off("blur").on("blur", function (e) {
                        this.event = e;
                        that.pdpFormatMoney(this);
                    });



                    that.pdpFormatMoney(this);
                    /*var bidval = parseInt($(this).val().replaceAll(',','').replaceAll('$',''));
                    if(!isNaN(bidval))
                     {
                         var moneyFormat = bidval.formatMoney();                 
                         $('.bid-plus-minus input').val('$' + moneyFormat);
                         $('.bidAmount').val(bidval);
                     } else {
                         $('.bid-plus-minus input').val('');
                         $('.bidAmount').val('');
                     }
                */



                });

                if (currentHighestBid >= bidMaxLimit && listingType == 'AUCN') {
                    $('.bid-plus-minus .bid-plus,.bid-plus-minus .bid-minus').addClass('disable');
                    $('.bid-plus-minus input').attr('disabled', 'disabled');
                }

                $('.bid-plus-minus .bid-plus, .lb-bid-plus-minus .bid-plus').off('click').click(function (e) {
                    var isDisable = $(this).hasClass('disable');
                    if (isDisable && that.bidCenterDetails.activeBidRefinementEnabled) {
                        return;
                    }
                    var obj = $(this).prev();
                    var customBidIncrementRange = bidIncrementRange;
                    var bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', ''));
                    if (that.bidCenterDetails.activeBidRefinementEnabled) {
                        if (that.bidCenterDetails.nextpossibleBidAmount >= that.bidCenterDetails.lowerThresholdAmount && bidval >= (that.bidCenterDetails.lowerThresholdAmount + (that.bidCenterDetails.nextpossibleBidAmount % that.bidCenterDetails.lowerThresholdIncrementMultiplier)) && bidval < that.bidCenterDetails.upperThresholdAmount) {
                            customBidIncrementRange = that.bidCenterDetails.lowerThresholdIncrementMultiplier;
                        } else if (that.bidCenterDetails.nextpossibleBidAmount >= that.bidCenterDetails.upperThresholdAmount && bidval >= that.bidCenterDetails.upperThresholdAmount) {
                            customBidIncrementRange = that.bidCenterDetails.upperThresholdIncrementMultiplier;
                        }
                    }

                    bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', '')) + customBidIncrementRange;

                    if (isNaN(bidval)) {
                        bidval = bidMinLimit;
                    }

                    if ((bidval - bidMinLimit) % customBidIncrementRange != 0) {
                        bidval = bidMinLimit + Math.floor((bidval - bidMinLimit) / customBidIncrementRange) * customBidIncrementRange;
                    }
                    that.pdpPlusMinusPress(bidval, obj);

                    GAUtils.eventTracking.trackevent("", "Button Click", "Increase | " + that.trackGALabel);
                });

                $('.bid-plus-minus .bid-minus, .lb-bid-plus-minus .bid-minus').off('click').click(function (e) {
                    var isDisable = $(this).hasClass('disable');
                    if (isDisable && that.bidCenterDetails.activeBidRefinementEnabled) {
                        return;
                    }
                    var obj = $(this).next();
                    var customBidIncrementRange = bidIncrementRange;

                    var isActiveBidRefinementEnabled = that.bidCenterDetails.activeBidRefinementEnabled,
                        lowerThresholdAmount = that.bidCenterDetails.lowerThresholdAmount,
                        upperThresholdAmount = that.bidCenterDetails.upperThresholdAmount,
                        lowTreshInc = parseInt(placeBidContent.bidConfig.lowTreshInc),
                        highTreshInc = parseInt(placeBidContent.bidConfig.highTreshInc),
                        nextpossibleBidAmount = that.bidCenterDetails.nextpossibleBidAmount,
                        lowTreshIncMultiplier = that.bidCenterDetails.lowerThresholdIncrementMultiplier,
                        highTreshIncMultiplier = that.bidCenterDetails.upperThresholdIncrementMultiplier;

                    var bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', ''));
                    if (isActiveBidRefinementEnabled) {
                        if (nextpossibleBidAmount >= lowerThresholdAmount && bidval >= (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier)) && bidval < upperThresholdAmount) {
                            customBidIncrementRange = lowTreshIncMultiplier;
                        } else if (nextpossibleBidAmount >= upperThresholdAmount && bidval >= upperThresholdAmount) {
                            customBidIncrementRange = highTreshIncMultiplier;
                        }
                    }

                    bidval = parseInt($(obj).val().replaceAll(',', '').replaceAll('$', '')) - customBidIncrementRange;

                    if (isNaN(bidval)) {
                        bidval = bidMinLimit;
                    } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount <= lowerThresholdAmount
                        && bidval >= (lowerThresholdAmount + (nextpossibleBidAmount % lowTreshIncMultiplier) + (lowTreshIncMultiplier * lowTreshInc))) {
                        bidval = nextpossibleBidAmount;
                    } else if (isActiveBidRefinementEnabled && nextpossibleBidAmount > lowerThresholdAmount
                        && bidval >= (currentHighestBid + (highTreshIncMultiplier * highTreshInc))) {
                        bidval = nextpossibleBidAmount;
                    }

                    if ((bidval - bidMinLimit) % customBidIncrementRange != 0) {
                        bidval = bidMinLimit + (Math.floor((bidval - bidMinLimit) / customBidIncrementRange) + 1) * customBidIncrementRange;
                    }


                    that.pdpPlusMinusPress(bidval, obj);

                    GAUtils.eventTracking.trackevent("", "Button Click", "Decrease | " + that.trackGALabel);

                });

            },


            pdpPlusMinusPress: function (bidval, obj) {
                var that = this;


                if (currentHighestBid >= bidMaxLimit && listingType == 'AUCN') {
                    $('.bid-plus-minus .bid-plus,.bid-plus-minus .bid-minus').addClass('disable');
                    $('.bid-plus-minus input').attr('disabled', 'disabled');
                    return;
                } else if (bidval <= bidMaxLimit && bidval >= bidMinLimit) {
                    $('.bid-plus-minus .bid-plus,.bid-plus-minus .bid-minus').removeClass('disable');
                } else {
                    if (bidval < bidMinLimit) {
                        bidval = bidMinLimit;
                        $('.bid-plus-minus .bid-minus').addClass('disable');
                    }
                    else if (bidval > bidMaxLimit) {
                        bidval = bidMaxLimit;
                        $('.bid-plus-minus .bid-plus').addClass('disable');
                    }
                }

                $(obj).val(bidval);

                that.pdpFormatMoney(obj);

                /*if(!isNaN(bidval))
                 {
                     var moneyFormat = bidval.formatMoney();                 
                     $('.bid-plus-minus input').val('$' + moneyFormat);
                     $('.bidAmount').val(bidval);
                 } else {
                     $('.bid-plus-minus input').val('');
                     $('.bidAmount').val('');
                 }
                */

            },

            initPropertyListener: function (auctionId) {
                try {
                    var databaseUrl = 'fcAuctions/' + auctionId + '/fcAuctionDetails',
                        listingNodeReference,
                        self = this,
                        status;

                    self.tenantId = $(".top-header-navigation").attr('data-app-tnt') || '';
                    status = $(".top-header-navigation").attr('data-status') || null;
                    if (self.tenantId && status === "encoded") {
                        self.tenantId = window.atob(self.tenantId);
                        databaseUrl = self.tenantId + "/" + databaseUrl;
                    }

                    listingNodeReference = globalAlertView.firebaseApp.database().ref(databaseUrl);
                    listingNodeReference.on('value', function (snapshot) {
                        // update Bid center
                        self.pdpBidCenterPoll({});
                    }, function (error) {
                        globalAlertView.notificationError({
                            error: error,
                            databaseUrl: databaseUrl,
                            event: 'value'
                        });
                    });
                } catch (error) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:initPropertyListener|" + error, null, 1);
                }
            },
            changePropertyStatus: function (auctionId, auctionStatus) {

                try {

                    auctionId = auctionId || null;
                    auctionStatus = auctionStatus || null;

                    var url = contextPath + "/portal/getBidCenterTemplateDetails/" + pdpDetails.businessListingId + '/' + propertyStatus,
                        that = this,
                        isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false;

                    if (isProxyBiddingEnabled) {
                        fcAuctionStatus = auctionStatus || fcAuctionStatus;
                        url = contextPath + "/portal/getFcAuctionBidCenterTemplateDetails/" + (auctionId || fcAuctionId) + '/' + fcAuctionStatus.replaceAll('TRANSACTION', 'TRNS');
                        if (auctionId && auctionId !== fcAuctionId) {
                            that.initPropertyListener(auctionId);
                            fcAuctionId = auctionId;
                            fcAuctionStatus = auctionStatus;
                        }

                        var isMobile = $(window).width() < 768 ? true : false;
                        if (isMobile) {
                            if (fcAuctionStatus == "TRANSACTION_COMPLETED") {
                                $('.bid-center-mobile').addClass('hide');
                            }
                            if (fcAuctionStatus == "CRY_STARTED" || fcAuctionStatus == "CRY_PAUSED" || fcAuctionStatus == "CLEAR_FOR_SALE_RESCHEDULED" || fcAuctionStatus == "TRANSACTION_PENDING" || fcAuctionStatus == "TRANSACTION_COMPLETED") {
                                $(".sticky-nav").removeClass('fcp-action').addClass("hide");
                            }
                        }
                    }
                    $.ajax({
                        url: url,
                        success: function (data) {

                            $('#bidPageModal').modal('hide');
                            setTimeout(function () {

                                that.updateStickyValues = true;
                                $('#pdpBidCenterInner').html(data);
                                $('#pdpBidCenterInner').find('.bidHistoryLoadRemove').remove();

                                that.pdpBidCenter();
                                that.showSaleDeposit();
                                //check for cif completion
                                    // if ($('.reg-button').length && window.cifCompleted === 'Y') {
                                    //     $('.reg-button').val('Registration Completed');
                                    //     // disable the button
                                    //     $('.reg-button').attr("disabled", "disabled");
                                    // } 

                                var statelist = (bidDepositContent.excludeStates.stateList).split(",");

                                if ($.inArray(stateCode, statelist) == -1) {
                                    that.showBidDeposit();
                                }


                                if (listingType && listingType == 'AUCN') {
                                    that.notifyTimeLeft(that.pdpBidtimer.bidLeftTime);
                                }


                                if (propertyStatus === 'SLD' || propertyStatus === 'WLST') {

                                    that.pdpBidCenterPollProcess = true;
                                    clearInterval(window.pdpBidCenterPollVar);

                                    // Hide the 5Arch section for Sold and WLST
                                    $(".pdp5Arch").hide();

                                } else if (typeof priceForArch != 'undefined' && priceForArch != '0') {
                                    var fiveArchPercentage = (Number(priceForArch.replaceAll(',', '').replaceAll('$', '')) * Number(fiveArchPercent)) / 100;
                                    $(".fiveArchPrice").text('$' + fiveArchPercentage.formatMoney());
                                    $('.adPrice').text('$' + fiveArchPercentage.formatMoney());
                                    that.pdpBidCenterPollProcess = false;
                                    commonutils.highlightSavedProperties();
                                }

                                if (typeof FCOnlineReserveMetText !== 'undefined' && isForeClosure == 'Y' && fcOnlineFlag == 'Y') {
                                    $(".pdp-bid-tooltip .tooltipPopdesc").html(FCOnlineReserveMetText);
                                    $(".reservemetnote.bid-padding").remove();
                                }
                                if (typeof fcOnlineTitleSubTxt != 'undefined' && fcOnlineTitleSubTxt != '') {
                                    $(".sheduled-sub-txt").html(fcOnlineTitleSubTxt).removeClass("hide");
                                }
                                if (isProxyBiddingEnabled) {
                                    if ($("#pdp-bid-form").length == 0) {
                                        $(".sticky-nav").addClass("hide");
                                    } else {
                                        $(".sticky-nav").removeClass("hide");
                                    }
                                }
                                if (isProxyBiddingEnabled && $(".proxy-bid-button").length) {
                                    var options = {};
                                    golbalPDPView.updateProxyBid(options);

                                    $('.proxy-bid-button').off('click').click(function () {
                                        that.pdpBidCenterSubmit();
                                    });
                                }


                            }, 500);
                        }
                    });
                } catch (error) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:changePropertyStatus|" + error, null, 1);
                }
            },

            showSaleDeposit: function () {
                try {
                    /**
                     * Check and enable FC details 
                     */
                    var fcElement = $(".fc-fee-details"),
                        saleDeposit,
                        baCommission,
                        appraisedValue,
                        auctionCounty,
                        auctionDuration,
                        isForeClosure = typeof window.isForeClosure !== "undefined" && window.isForeClosure === "Y" ? true : false;

                    // Test data 
                    // fcElement.attr('data-saledeposit', "10,000");
                    // fcElement.attr('data-commission', "12,00");
                    if (fcElement && fcElement.length && isForeClosure) {

                        auctionCounty = fcElement.attr('data-auctioncounty') || '';
                        auctionDuration = fcElement.attr('data-auctionduration') || '';
                        if ((auctionCounty || auctionDuration) && $(".fc-online-active-listing").length) {
                            $(".fc-online-active-listing").removeClass('hide');
                            if (auctionDuration) {
                                $(".fc-online-active-listing .fc-acution-details").removeClass("hide");
                                $(".fc-online-active-listing .fc-acution-details .fc-time").text(auctionDuration);
                            }
                            if (auctionCounty) {
                                $(".fc-online-active-listing .  ").removeClass("hide");
                                $(".fc-online-active-listing .fc-address-details .fc-row-value").text(auctionCounty);
                            }
                            if (auctionCounty && auctionDuration)
                                $(".fc-online-active-listing .fc-separator").removeClass("hide");
                        }

                        saleDeposit = fcElement.attr('data-saledeposit') || '';
                        saleDeposit = (saleDeposit === 0 || saleDeposit === "0") ? '' : saleDeposit;

                        baCommission = fcElement.attr('data-commission') || '';
                        baCommission = (baCommission === 0 || baCommission === "0") ? '' : baCommission;

                        appraisedValue = fcElement.attr('data-appraisedvalue') || '';
                        appraisedValue = (appraisedValue === 0 || appraisedValue === "0") ? '' : appraisedValue;

                        if (saleDeposit || baCommission || appraisedValue) {
                            $(".fc-online-fees-details").removeClass('hide');
                            if (saleDeposit) {
                                $(".fc-online-fees-details .sale-deposit").removeClass('hide');
                                $(".fc-online-fees-details .sale-deposit .sd-value").text('$' + saleDeposit)
                            }
                            if (baCommission) {
                                $(".fc-online-fees-details .ba-commission").removeClass('hide');
                                $(".fc-online-fees-details .ba-commission .sd-value").text(baCommission)
                            }
                            if (appraisedValue) {
                                $(".fc-online-fees-details .appraised-value").removeClass('hide');
                                $(".fc-online-fees-details .appraised-value .sd-value").text('$' + appraisedValue)
                            }
                        }

                        var ktooltips = document.querySelectorAll(".border-tooltip");
                        ktooltips.forEach(function (ktooltip, index) {
                            ktooltip.addEventListener("mouseover", commonutils.correctTooltipPosition);
                        });
                    }
                } catch (error) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:showSaleDeposit|" + error, null, 1);
                }
            },
            gaEventTracking: function (action, eventLabel) {
                var userId = (typeof window.userSession !== "undefined") && userSession.userId || 'NA';
                if (eventLabel != "") {
                    var eventLabel = 'UID : ' + userId + ' | Visitor Id : ' + $.cookie('visitorId') + ' | ' + eventLabel;
                } else {
                    var eventLabel = 'UID : ' + userId + ' | Visitor Id : ' + $.cookie('visitorId');
                }

                GAUtils.eventTracking.trackevent("", action, eventLabel);
            },
            placeProxyBid: function () {
                var self = this;
                self.proxyBidEnabled = true;
                self.mobileVerificationEnabled = true;
                mobileVerificationView.mobileVerificationCheck = false;
                self.pdpBidCenterSubmit();

                var eventLabel = "Property ID:" + pdpDetails.propertyId;
                self.gaEventTracking("Place Proxy Bid click", eventLabel);
            },
            pdpBidCenterSubmit: function (options) {
                try {
                    var self = this;
                    $.get(contextPath + "/portal/is-valid-session", function (response) {
                        self.loginCallback({
                            user: {
                                loggedInUserDetail: response
                            }
                        }, self)
                    });
                    
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpBidCenter", "file:pdpBidCenter.js|function:pdpBidCenterSubmit|" + e, null, 1);
                }
            },

            loginCallback: function (options, that) {
                try {
                    
                    var response = options.user.loggedInUserDetail || {},
                        stickyBidEvent = options && options.stickyBidEvent || '',
                        isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false,
                        action = $("#pdpBidCenterInner .proxy-bid-button") || "register-bid";

                    var bidTypeGACode = $('.bidType').val(),
                        offerTypeGACode = $('#offerType').val(),
                        eventAction = "Button Click",
                        bidOfferTypeGA = "";

                    if (bidTypeGACode != undefined && bidTypeGACode != '') {
                        if (bidTypeGACode == 'PLACE_BID') {
                            bidOfferTypeGA = 'Place Bid';
                        } else if (bidTypeGACode == 'PLACE_BACKUP_BID') {
                            bidOfferTypeGA = 'Place Backup Bid';
                            eventAction = "Place backup bid click";
                        } else if (bidTypeGACode == 'OWN_IT_NOW') {
                            bidOfferTypeGA = 'Own it now- AUCN';
                        } else if (bidTypeGACode == 'OWN_IT_NOW') {
                            bidOfferTypeGA = 'Own it now- AUCN';
                        }
                    } else if (offerTypeGACode != undefined && offerTypeGACode != '') {
                        if (offerTypeGACode == 'PLACE_OFFER') {
                            bidOfferTypeGA = 'Place Offer';
                        } else if (offerTypeGACode == 'PLACE_BACKUP_OFFER') {
                            bidOfferTypeGA = 'Place Backup Offer';
                        }
                    }

                  //  if (!response.userEmailId) { // not a valid session
                    if (response.isValidSession === 'N') {
                        if (isProxyBiddingEnabled) {
                            var eventAction = $("#pdpBidCenterInner .proxy-bid-button").attr("data-action");

                            GAUtils.eventTracking.trackevent("", eventAction, that.trackGALabel + " | Screen name: Sign In");
                        } else {
                            GAUtils.eventTracking.trackevent("", eventAction, that.trackGALabel + " | Register " + bidOfferTypeGA);
                        }

                        that.ispdpBidCenterSubmit = true;
                        $('#lightbox, .pdp-gallery-lightbox').modal('hide');

                        // If user already registered while subscribe, open register 
                        var callbackOptions = {
                            callback: that.loginCallback.bind(that),
                            statusMessage: (response.userEmailId) ? saveListDrawerContent.errorMessages.sessionRequiredMessageForPlaceBid : null,
                            viewObject: that
                        };
                        if (commonutils.getCookieDetails('subscribeEmail')) {
                            callbackOptions.openAnonymRegister = 'Y';
                        }
                       //loginRegisterView.openLoginPopup({statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessageForPlaceBid});

                        loginRegisterView.openLoginPopup(callbackOptions, that.ispdpBidCenterSubmit);
                       
                        $('#closeRegisterLogin').on('click', function () {
                            setTimeout(function () {
                                that.ispdpBidCenterSubmit = false;
                            }, 1000);
                        });

                    } else if (!response.phoneNumberVerified && mobileVerificationView.mobileVerificationCheckVal && mobileVerificationView.mobileVerificationCheckVal == 'Y') {
                        var callbackOptions = null;
                        callbackOptions = {
                            openPhoneVerification: true
                        }
                        $('#loginRegisterDialog .modal-content').removeClass('activeStage');
                        if (isProxyBiddingEnabled) {
                            var eventAction = $("#pdpBidCenterInner .proxy-bid-button").attr("data-action");
                            var userId = window.userSession && window.userSession.userId || 'NA';
                            var trackGALabel = "Property Id: " + pdpDetails.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId");
                            GAUtils.eventTracking.trackevent("", eventAction, trackGALabel + " | Screen name: PDP");
                        }
                        loginRegisterView.showPhoneVerification(callbackOptions, that.ispdpBidCenterSubmit);
                    } else if (response.phoneNumberVerified && typeof isProxyBiddingEnabled != 'undefined' && isProxyBiddingEnabled) {

                        var callbackOptions = {};
                        action = $("#pdpBidCenterInner .proxy-bid-button").attr("data-action");
                        if (action === "Place Proxy Bid" || action === "Update Proxy Bid")
                            callbackOptions.confirmProxyBidPin = true;
                        else
                            callbackOptions.openProxyBidRegister = true;

                        if (isProxyBiddingEnabled) {
                            golbalPDPView.initPropertyListner();
                            var eventAction = $("#pdpBidCenterInner .proxy-bid-button").attr("data-action");
                            var userId = window.userSession && window.userSession.userId || 'NA';
                            var trackGALabel = "Property Id: " + pdpDetails.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId");
                            GAUtils.eventTracking.trackevent("", eventAction, trackGALabel + " | Screen name: PDP");
                        }
                        loginRegisterView.openLoginPopup(callbackOptions, that.ispdpBidCenterSubmit);
                    } else {
                        if (bidTypeGACode != 'PLACE_BACKUP_BID') {

                            if (isProxyBiddingEnabled) {
                                var eventAction = $("#pdpBidCenterInner .proxy-bid-button").attr("data-action");
                                var userId = window.userSession && window.userSession.userId || 'NA';
                                var trackGALabel = "Property Id: " + pdpDetails.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId");

                                GAUtils.eventTracking.trackevent("", eventAction, trackGALabel + " | Screen name: PDP");
                            } else {
                                var listingId = pdpDetails.businessListingId || "NA";
                                var userId = window.userSession && window.userSession.userId || 'NA';
                                var trackGALabel = "Property Id: " + pdpDetails.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId");
                                GAUtils.eventTracking.trackevent("", bidOfferTypeGA, trackGALabel);
                                if (stickyBidEvent != '') {
                                    GAUtils.eventTracking.trackevent("", stickyBidEvent, trackGALabel);
                                }
                            }
                        }
                        if(propCategory === "COMMERCIAL" && ( propStatus === 'PACT' || propStatus === 'ACTV' || that.bidCenterDetails.propertyStatus === "PACT")) {
                            that.checkForCifCompletion(pdpDetails.businessListingId, propStatus);
                        } else {
                            that.decideTarget();
                        }
                    }

                } catch (error) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:loginCallback|" + e, null, 1);
                }
            },
            pdpShowHideStick: function (acc) {
                /*$('.time-left,.sticky-bid-amount,.sticky-bid-input,.sticky-place-bid').removeClass('inactive').removeClass('inactive');*/
                if (acc == 0) {
                    $('.time-left,.sticky-bid-amount,.sticky-bid-input,.sticky-place-bid').addClass('inactive');
                    $('.time-left,.sticky-bid-amount,.sticky-bid-input,.sticky-oin').addClass('inactive');
                }
                else {
                    $('.time-left,.sticky-bid-amount,.sticky-bid-input,.sticky-place-bid').addClass('active').removeClass('inactive');
                    $('.time-left,.sticky-bid-amount,.sticky-bid-input,.sticky-oin').addClass('active').removeClass('inactive');
                }

            },
            decideTarget: function () {
                var that = this;

                try {

                    var bidTypeCode = $('.bidType').val();
                    var offerTypeCode = $('#offerType').val();
                    var eventAction = "Button Click";
                    var userType = window.userSession.user.userType,
                        isSmallSeller = window.userSession.user.isSmallSeller || false;

                    if (userType == 'PRIM_AUTH_USER' ||
                        (userType == 'AUTH_USER' && !(isSmallSeller)) ||
                        userType == 'CUST_CARE' ||
                        $.inArray('ANALYST', window.userSession.user.roleIdList) != -1) {

                        $('#lightbox, .pdp-gallery-lightbox').modal('hide');
                        setTimeout(function () {

                            if (!$("#userTypeErrorTemplate").length) return;

                            var userTypeErroTemplate = $("#userTypeErrorTemplate").html();
                            userTypeErroTemplate = Handlebars.compile(userTypeErroTemplate);

                            var btn = $(".bid-center-bid-button .place-bid-button"),
                                btnValue = '',
                                listingType = window.listingType !== "undefined" ? window.listingType : 'AUCN',
                                listingTypeText = listingType === 'AUCN' ? 'a bid' : 'an offer',
                                desc = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.bidcentererrorunauthorized;

                            desc = desc.replace(/an offer/g, listingTypeText);

                            if (btn.length) btnValue = btn.val();

                            $("#bidCenterPopup .modal-body").html(userTypeErroTemplate({
                                head: btnValue,
                                desc: desc
                            }));

                            $('#bidCenterPopup').on("hidden.bs.modal", function () {
                                $("#bidCenterPopup").removeClass("user-type-error-modal");
                            });

                            $("#bidCenterPopup").addClass('user-type-error-modal').modal("show");

                        }, 400);

                    } else if (window.userSession.user.userStatus == 'PROFILE') {
                        var bidTypeCode = $('.bidType').val();
                        var offerTypeCode = $('#offerType').val();

                        if (offerTypeCode == "PLACE_OFFER" || offerTypeCode == 'PLACE_BACKUP_OFFER') {
                            document.forms['pdp-offer-form'].submit();
                            //document.getElementsByClassName('pdp-offer-form')[0].submit();
                        } else {
                            //document.getElementsByClassName('pdp-bid-form')[0].submit();
                            document.forms['pdp-bid-form'].submit();
                        }
                    } else {
                        var bidTypeGACode = $('.bidType').val();
                        var offerTypeGACode = $('#offerType').val();
                        if (bidTypeGACode != undefined && bidTypeGACode != '') {
                            if (bidTypeGACode == 'PLACE_BID') {
                                var bidOfferTypeGA = 'Place Bid';
                            } else if (bidTypeGACode == 'PLACE_BACKUP_BID') {
                                var bidOfferTypeGA = 'Place Backup Bid';
                                eventAction = "Place backup bid click";
                            } else if (bidTypeGACode == 'OWN_IT_NOW') {
                                var bidOfferTypeGA = 'Own it now- AUCN';
                            } else if (bidTypeGACode == 'OWN_IT_NOW') {
                                var bidOfferTypeGA = 'Own it now- AUCN';
                            }
                        } else if (offerTypeGACode != undefined && offerTypeGACode != '') {
                            if (offerTypeGACode == 'PLACE_OFFER') {
                                var bidOfferTypeGA = 'Place Offer';
                            } else if (offerTypeGACode == 'PLACE_BACKUP_OFFER') {
                                var bidOfferTypeGA = 'Place Backup Offer';
                            }
                        }

                        if (offerTypeCode == "PLACE_OFFER" && $.inArray('AGENT', window.userSession.user.roleIdList) == -1) {

                            $.get(contextPath + "/portal/checkOfferActionByListingId/" + pdpDetails.businessListingId, function (response) {
                                if (response) {

                                    $(".global-Mask").hide();
                                    /*clearTimeout(myVar);*/
                                    $('#counter-user-popup a').addClass('redirectUsertoOffer');
                                    $('#counter-user-popup-id').click();

                                    $('.redirectUsertoOffer').click(function (e) {
                                        e.preventDefault();
                                        window.location = contextPath + "/portal/my-shopper#myOffers";
                                    });

                                } else {
                                    //document.getElementsByClassName('pdp-offer-form')[0].submit();
                                    document.forms['pdp-offer-form'].submit();
                                }

                            });
                        } else if (bidTypeCode == "PLACE_BACKUP_BID") {
                            $(".global-Mask").show();
                            $.get(contextPath + "/portal/isBestBidAvailable?listingId=" + pdpDetails.businessListingId, function (response) {

                                if (response.validationStatus == 'SESSION_INVALID') {
                                    that.ispdpBidCenterSubmit = true;
                                    $(".global-Mask").hide();

                                    loginRegisterView.openLoginPopup();
                                    $('#closeRegisterLogin').on('click', function () {
                                        setTimeout(function () {
                                            that.ispdpBidCenterSubmit = false;
                                        }, 1000);
                                    });

                                }

                                else if (response.isBestBidAvailable == 'Y') {
                                    $("#loginRegisterDialog,#ajax-loader,#ajax-loader-mask").hide();
                                    $(".modal-backdrop").removeClass("in").hide();
                                    var bidOfferTypeGA = "Place Best Bid";
                                    GAUtils.eventTracking.trackevent("", "Place best bid click", this.trackGALabel);
                                    $('#pdp-gallery-lightbox').modal('hide');
                                    that.openPlaceBestBidDetails();

                                    //$('#placeBackupBidButton').val(placeBestBidLabelContent.title);place-backupbid-button
                                    $('.place-backupbid-button').val(placeBestBidLabelContent.title);
                                    $('#bidCenterDescription').val(placeBestBidLabelContent.underreviewBestBid);
                                } else if (response.isSellerFinalNegotiationAvailable == 'Y' && response.isSellerFinalNegotiationAcceptedAlready !== 'Y') {

                                    $('.place-backupbid-button').val(placeBestBidLabelContent.confirmOfferButton);
                                    $('#bidCenterDescription').html(placeBestBidLabelContent.underreviewBestBid);
                                    that.isOfferNegotiation = true;
                                    var bidOfferTypeGA = "Place Offer Negotiation";
                                    // GAUtils.eventTracking.trackevent("","Button Click", "listingId:"+pdpDetails.businessListingId+" | "+ bidOfferTypeGA);
                                    GAUtils.eventTracking.trackevent("", eventAction, this.trackGALabel + " | " + bidOfferTypeGA);
                                    $('#pdp-gallery-lightbox').modal('hide');
                                    that.openOfferNegotiationPopup();
                                }
                                else {

                                    var bidOfferTypeGA = "Place Backup Bid";
                                    GAUtils.eventTracking.trackevent("", eventAction, this.trackGALabel);
                                    if ($('#pdp-bid-form').length != 0) {
                                        var bidOfferTypeGA = "Place Best Bid";
                                        //document.getElementsByClassName('pdp-bid-form')[0].submit();
                                        //document.forms['pdp-bid-form'].submit();
                                        var userTypeOptions = {};
                                        userTypeOptions.listingId = listingId;
                                        userTypeOptions.thisObj = that;
                                        userTypeOptions.callBack = that.placeBidClick;
                                        userCheckView.userTypeCheck(userTypeOptions);
                                    }
                                    else {
                                        //document.getElementsByClassName('pdp-offer-form')[0].submit();
                                        document.forms['pdp-offer-form'].submit();
                                    }
                                }

                            });
                        } else {
                            var userTypeOptions = {};
                            userTypeOptions.listingId = listingId;
                            userTypeOptions.thisObj = that;
                            userTypeOptions.callBack = that.placeBidClick;
                            userCheckView.userTypeCheck(userTypeOptions);
                        }


                    }
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:decideTarget|" + e, null, 1);
                }

            },
            placeBidClick: function (options) {
                var that = options.thisObj;
                if ($('#pdp-bid-form').length != 0) {
                    if ($.trim($('.bidType').val()) == 'PLACE_BID') {
                        that.checkforFradulentBidder();
                    } else {
                        document.forms['pdp-bid-form'].submit();

                    }
                } else {
                    document.forms['pdp-offer-form'].submit();
                }
            },
            checkforFradulentBidder: function () {
                var url = contextPath + '/portal/checkFraudulentBid/';
                if ($('#bid-amount-custom').length) {
                    var bidval = parseInt($('#bid-amount-custom').val().replace(/[^0-9.]/g, ''));
                } else {
                   var bidval = parseInt($('.bid-price-val').html().replace(/[^0-9.]/g, ''));
                }
                var userSession = window.userSession || {},
                    userId = userSession.userId || null;
                var fraudData = { 'userId': userId, 'listingId': listingId, 'bidAmount': bidval, 'bidType': $.trim($('.bidType').val()) };
                $.ajax({

                    url: url,
                    contentType: "application/json; charset=utf-8",
                    dataType: 'json',
                    type: "POST",
                    data: JSON.stringify(fraudData),
                    success: function (response) {
                        if (typeof response != 'undefined' && typeof response.data != 'undefined' && response.data != null && response.data.fraudulentBid == true && response.data.paymentRequired == true) {
                            var options = {};
                            options.formSelector = '';
                            options.amount = response.data.bidDepostAmount;
                            options.source = 'Place Bid Pdp';
                            options.documentFormSubmit = document.forms['pdp-bid-form'];
                            globalAlertView.showFradulentErrorModal(options);
                            return true;
                        } else {
                            //document.getElementsByClassName('pdp-bid-form')[0].submit();
                            document.forms['pdp-bid-form'].submit();
                        }

                    }
                });

            },
            getBuyerPremium: function () {


                var url = contextPath + '/portal/getBuyerPremiumDetails?propertyId=' + propertyId;
                var self = this;
                if (listingId != null && listingId != '') {
                    url = url + '&listingId=' + listingId;
                }

                $.ajax({
                    url: url,
                    success: function (buyerPremiumArry) {

                        if (buyerPremiumArry != null && buyerPremiumArry != "" && buyerPremiumArry.length > 0) {

                            var buyerPremiumMinAmountArry = new Array();
                            var buyerPremiumMaxAmountArry = new Array();
                            var buyerPremiumAmountArry = new Array();
                            var buyerPremiumPercntArry = new Array();
                            var buyerPremiumListType = new Array();
                            var j = 0;
                            for (var i = 0; i < buyerPremiumArry.length;) {
                                buyerPremiumMinAmountArry[j] = $.trim(buyerPremiumArry[i]);
                                buyerPremiumMaxAmountArry[j] = $.trim(buyerPremiumArry[++i]);
                                buyerPremiumAmountArry[j] = $.trim(buyerPremiumArry[++i]);
                                buyerPremiumPercntArry[j] = $.trim(buyerPremiumArry[++i]);
                                buyerPremiumListType[j] = $.trim(buyerPremiumArry[++i]);
                                i = i + 2;
                                j++;
                            }

                            var percentage = buyerPremiumPercntArry[0];
                            /*$('#buyer-premium .propertyfee-title .buyerPremiumPercentage').html(buyerPremiumAmountPercent);
                            $('#buyer-premium .propertyfee-title .BPReductionAmount,#disclaimerContentPdp .BPReductionAmount').html(buyerPremiumAmount);*/

                            $('#buyer-premium').show();
                            self.showDisclaimerPremium = true;
                            /* $('#disclaimerContentPdp .buyrPriumPrcntge').html(percentage);*/
                            $('#disclaimerContentPdp .byrPriumDisclaimer').show();
                        }

                    }

                });

            },
            animateBidError: function () {

                var isMobile = false;
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Opera Mobile|Kindle|Windows Phone|PSP|AvantGo|Atomic Web Browser|Blazer|Chrome Mobile|Dolphin|Dolfin|Doris|GO Browser|Jasmine|MicroB|Mobile Firefox|Mobile Safari|Mobile Silk|Motorola Internet Browser|NetFront|NineSky|Nokia Web Browser|Obigo|Openwave Mobile Browser|Palm Pre web browser|Polaris|PS Vita browser|Puffin|QQbrowser|SEMC Browser|Skyfire|Tear|TeaShark|UC Browser|uZard Web|wOSBrowser|Yandex.Browser mobile/i.test(navigator.userAgent)) isMobile = true;

                if (isMobile && !$('#bidErrorToast').is(':visible')) {

                    $('#bidErrorToast').fadeIn('300').delay(2000).fadeOut('slow');
                }

                $('.bid-error-msg').show();
                $('.bid-error-msg').css('margin-left', "+=10px");
                setTimeout(function () {
                    $('.bid-error-msg').css('margin-left', "-=10px");
                    setTimeout(function () {
                        $('.bid-error-msg').css('margin-left', "+=10px");
                        setTimeout(function () {
                            $('.bid-error-msg').css('margin-left', "-=10px");
                        }, 10);
                    }, 10);
                }, 10);

            },
            pdpBidCenter: function () {
                try {

                    var that = this,
                        isMobile = $(window).width() < 768 ? true : false,
                        isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false;

                    if (isProxyBiddingEnabled && $(window).width() < 767 && $(".sticky-nav .proxy-bid-button").length) {
                        $('.sticky-nav').addClass('fcp-action');
                    }

                    that.ispdpBidCenterSubmit = false;

                    $('.reg-button').off('click').click(function () {
                       // $(".heartListBtn").click();
                        that.pdpBidCenterSubmit();
                    });

                    //to hide reserve met tooltip and note for ABS 
                    if (propertyCatagory == 'ABS' || (typeof bulkProperty !== 'undefined' && bulkProperty === 'Y')) {
                        $(".bid-tool-left").hide();
                        $(".reservemetnote").hide();
                        $(".icon-reservemet").hide();
                    }

                    if (isInvestorAprvlReqd) {
                        $(".bid-reserve-met").hide();
                    }

                    //for timer
                    that.initTimer(bidTimeleftSec);


                    //make money format price
                    if ($('.bidcenter-js .bid-price-val').length > 0) {
                        var highestprice = $('.bidcenter-js .bid-price-val').html();
                        highestprice = parseInt(highestprice.replaceAll(',', '').replaceAll('$', '')).formatMoney();
                        if (isForeClosure == 'Y' && highestprice <= 0) {

                        } else {
                            $('.bid-price-val').html('$' + highestprice);
                            $('.bidIncrementMulti').html(bidIncrementMultiplier.formatMoney());
                        }

                        $('.bid-price-label').html($('.bidcenter-js .bid-price-label').html());
                        this.isMobile = $(window).width() < 768 ? true : false;

                        if (that.isMobile) {
                            $('.bid-center-mobile').removeClass('hide');
                            if (isMobile && propertyStatus && propertyStatus != 'WFA') {
                                if (!isProxyBiddingEnabled) {
                                    $('.sticky-nav').addClass('has-data');
                                }

                                if (listingType && (listingType != 'AUCN' || (listingType == 'AUCN' && fcOnlineFlag != 'Y' && propStatus != 'ACTV'))) {
                                    $('.bid-center-mobile .bid-timer').addClass('hide');
                                }
                            }
                            $('#openBidHistoryGraph').appendTo(('.bid-view-history'));
                            $('.bid-center-mobile').removeClass('hide');
                            $(".open-bid-history-btn").on('click', function (event) {
                                that.getBidHistory();
                            });
                        }

                        if (currentHighestBid)
                            currentHighestBid = parseInt(currentHighestBid.replaceAll(',', '').replaceAll('$', ''));
                        else
                            currentHighestBid = currentHighestBid;

                        if (bidMinLimit > bidMaxLimit) {
                            bidMinLimit = bidMaxLimit;
                        }

                    }

                    if ($('.foreclosurePropertyStatus .bid-price-val').length > 0) {
                        var highestprice = $('.foreclosurePropertyStatus .bid-price-val').html();
                        highestprice = highestprice.replaceAll('$', '')
                        if (parseInt(highestprice) <= 0 || parseInt(highestprice) == 0 || highestprice == 'TBD') {

                            $('.foreclosurePropertyStatus .bid-price-val').html(highestprice);

                        } else {
                            highestprice = parseInt(highestprice.replaceAll(',', '').replaceAll('$', '')).formatMoney();
                            $('.foreclosurePropertyStatus .bid-price-val').html('$' + highestprice);
                        }

                        $('.bid-price-label').html($('.foreclosurePropertyStatus .bid-price-label').html());

                        if (currentHighestBid)
                            currentHighestBid = parseInt(currentHighestBid.replaceAll(',', '').replaceAll('$', ''));
                        else
                            currentHighestBid = currentHighestBid;

                        if (bidMinLimit > bidMaxLimit) {
                            bidMinLimit = bidMaxLimit;
                        }

                    }
                    //for plus minus
                    if ($('.bidcenter-js .bid-plus-minus .bid-plus').length > 0 && listingType == 'AUCN') {
                        $('.bid-plus-minus input').val(bidMinLimit);
                        this.pdpBidPlusMinus();

                        $('.place-bid-button').off('click').on('click', function (e) {

                            if (that.pdpBidError == true) {
                                that.animateBidError();
                                // that.bidFormatMoney($(this));
                            }
                            else {

                                var stickyBidEvent = (e.currentTarget).id == 'stickyBidAmount' ? 'Sticky Place Bid' : '';
                                var options = { 'stickyBidEvent': stickyBidEvent };
                                var bidval = parseInt($('#bid-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));
                                $('.bidAmount').val(bidval);
                                $('.bidType').val('PLACE_BID');
                                that.pdpBidCenterSubmit(options);
                            }

                        });
                    }
                    if (propertyStatus === 'SPND' || propertyStatus === 'REVIEW') {
                        $('.time-left,.increments-txt ,.sticky-bid-amount,.sticky-bid-input,.sticky-place-bid').addClass('inactive-property');
                        $('.sticky-place-bkup-bid').removeClass('inactive-bkup');
                        $('.right-section').addClass('bkup-bid-button-actv');
                        if (listingType && listingType == 'AUCN' && isBestBidAvailable) {
                            $('.place-backupbid-button').val(placeBestBidLabelContent.title);
                        } else if (listingType && listingType == 'TRNL' && isConfirmOfferAvailable) {
                            $('.place-backupbid-button').val(placeBestBidLabelContent.confirmOfferButton);
                        }
                        if (isMobile) {
                            $('.sticky-nav').removeClass('has-data').addClass('has-data');
                        }
                    }
                    //HUBZ-11346 - showing confirm button with amount and update CTA 
                    if (propertyStatus == 'REVIEW' && isConfirmOfferAvailable) {
                        var self = this,
                            url = contextPath + '/portal/getSellerFinalNegotiationDetails?listingId=' + pdpDetails.businessListingId;

                        if (self.populateBestBidCall != undefined) self.populateBestBidCall.abort();
                        self.populateBestBidCall = $.ajax({
                            url: url,
                            type: "GET",
                            dataType: "json",
                            contentType: "application/json; charset=utf-8",
                            success: function (response) {
                                response = response || {};
                                if (response.sellerFinalNegotiationAvailable != null && response.sellerFinalNegotiationAvailable) {
                                    var redefinedLable = placeBestBidLabelContent.confirmOfferButton + " " + "for $" + response.solicitationAmount.formatMoney();
                                    $('.place-backupbid-button').val(redefinedLable);
                                    return;
                                }
                            },
                            error: function () {
                                console.log("getNegotiationAmount - error");
                            }
                        });

                        // $('.place-backupbid-button').val(placeBestBidLabelContent.confirmOfferButton);
                    }

                    if (propertyStatus !== 'SLD' && propertyStatus !== 'WLST') {
                        var url = window.location.href;
                        var targetPath = contextPath + '/portal/bid';

                        if (url.indexOf('usecdn=Y') > -1) {
                            targetPath += '?usecdn=Y';
                        }

                        $('.pdp-bid-form').attr('action', targetPath);


                        /* if(Number(bidPollTime) > 0) {
                           if(listingType == 'AUCN')
                           {
                               window.pdpBidCenterPollVar = setInterval(function(){that.pdpBidCenterPoll()},bidPollTime);
                           }
                         }
                         */
                    }

                    //make money format own it now
                    if ($('.own-it-now-price').length > 0) {
                        var ownitnow = $('.own-it-now-price').html();
                        ownitnow = parseInt(ownitnow.replaceAll(',', '').replaceAll('$', ''));
                        $('.own-it-now-price').html('$' + ownitnow.formatMoney());

                        // for sticky header 
                        $('.sticky-place-bid-section').append('<div class="sticky-own-it-button"><span style="margin:10px;">Or</span><input type="button" data-parent="lightbox" class="btn btn-primary own-it-now-button" id="stickyOin" value="OIN" tabindex="2"></div>');
                        var oinVal = $('.own-it-now-price').html(); // price
                        var ctaVal = 'End the Auction Now ' + oinVal;
                        $('#stickyOin').val(ctaVal);

                        // for lightBox
                        $('.lb-bid-cta').append('<div class="bid-or-divider own-it-now-enabled"><div class="bid-divider"></div><div class="bid-or">Or</div></div><div class="bid-own-it clearfix own-it-now-enabled"><div class=""><input type="button" value="End the Auction Now" id="own-it-now-button" class="own-it-now-button"><span class="oinPrice"></span></div>');
                        var oinPrice = 'For ' + oinVal;
                        $('.oinPrice').html(oinPrice);

                        if ($('.own-it-now-price').length > 0 && parseInt(currentHighestBid) >= ownitnow) {
                            $(".own-it-now-enabled").hide();
                            $(".own-it-now-button, .sticky-own-it-button").hide(); // sticky header and gallery 
                        }

                        $('.own-it-now-button').off('click').click(function () {

                            $('.bidType').val('OWN_IT_NOW');

                            $('.bidAmount').val(ownitnow);
                            that.pdpBidCenterSubmit();

                        });
                    }

                    $('.place-backupbid-button').off('click').click(function () {
                        that.pdpBidCenterSubmit();
                    });

                    commonutils.enableTooltip();

                    /* $('.tooltipPop').off('mouseout').mouseout(function(e){
                         if(! $(e.target).hasClass('tooltipClick')){ 
                             $('.tooltipPopdesc').fadeOut('fast');
                         }
                     }); */
                    $(window).on('click', function (e) {
                        if ($(e.currentTarget).hasClass('tooltipPop')) {
                            return false;
                        } else {
                            $('.tooltipPopdesc').fadeOut('fast');
                        }
                    });

                    $('.bidcenter-js').removeClass('bid-var-1 bid-var-2 bid-var-3 bid-var-4 bid-var-5 bid-var-6 bid-var-7 bid-var-8');

                    $('.bidcenter-js').addClass('bid-var-' + bidVariation);


                    if (pdpDetails.isAllowedPreSale || isForeClosure == 'Y') {
                        that.pdpShowHideStick(1);
                    } else {
                        if (bidVariation == 1 && bidTimeleftSec > 0) {
                            that.pdpShowHideStick(1);
                        }
                        else if (offerVariation == 1) {
                            that.pdpShowHideStick(1);
                        }
                        else if (bidVariation != 1) {
                            that.pdpShowHideStick(0);
                        }
                    }

                    if (bidVariation && bidVariation == 8 && offerVariation && (offerVariation == 5 || offerVariation == 2)) {
                        if (isMobile) {
                            $('.bid-center-mobile').removeClass('hide');
                            $('.bid-center-mobile .amt-section').html($('.trnl-prop-info').html());
                            $('.trnl-prop-info').addClass('hide')
                            $('.bid-center-mobile .bid-timer').addClass('hide');
                            $('.sticky-nav').removeClass('has-data').addClass('has-data');
                            $('.sticky-place-bkup-bid').removeClass('inactive-bkup');
                        } else {
                            $('.sticky-place-bid').addClass('active').removeClass('inactive');
                            $('.sticky-oin').addClass('active').removeClass('inactive'); //OIN
                        }
                    }

                    if (listingType == 'AUCN') {
                        that.getBuyerPremium();
                        setTimeout(function () {
                            $('.bid-error-msg').hide();
                        });
                    }
                    else {
                        var url = window.location.href;
                        var targetPath = contextPath + '/portal/offer';

                        if (url.indexOf('usecdn=Y') > -1) {
                            targetPath += '?usecdn=Y';
                        }

                        $('.pdp-offer-form').attr('action', targetPath);

                        if ($('.current-high-offer').length > 0) {
                            var choffer = $('.current-high-offer').html();
                            choffer = parseInt(choffer.replaceAll(',', '').replaceAll('$', ''));
                            $('.current-high-offer').html('$' + choffer.formatMoney());
                        }

                        if ($('#offer-amount-custom').length > 0) {
                            $('.bid-plus-minus input').val('');
                            that.pdpBidPlusMinus();

                            setTimeout(function () {
                                $('.bid-error-msg').hide();
                                $('#bidErrorToast').hide('fast');
                                $('#offer-amount-custom, #offer-amount-custom-nav').val('');
                            });
                        }

                        $(document.body).on('click', '.pdpbid-pop-ok', function () {

                            that.pdpBidCenterSubmit();

                            $('#pdp-popup').modal('hide');
                        });

                        $('.place-offer-button').off('click').on("click", function () {

                            if (that.pdpBidError == true) {
                                that.animateBidError();
                                //that.bidFormatMoney($(this));
                            }
                            else {
                                if ($('.offer-amount-custom').length > 0) {
                                    var bidval = parseInt($('.offer-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));
                                    $('#offerAmount').val(bidval);
                                }

                                that.pdpBidCenterSubmit();
                            }

                        });

                        $('.watch-prop-button').off('click').click(function (e) {
                            if (window.userSession.user != undefined) {
                                $('#watchProp').click();
                            } else {
                                //$('#loginModal').modal('show');
                                //pdpPopupView.showLoginPopup();
                                $("#watchSignIn").click();
                            }
                        });
                    }




                    if (isDynamicBidIncrement) {
                        $('.sticky-place-bid-section .dynamicFaqPopUp').removeClass('hide');
                    }
                }
                catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:pdpBidCenter|" + e, null, 1);
                }
            },
            pdpBidExpire: function (obj) {
                try {
                    var that = this, options = { 'callback': that.pdpExpireCallback, 'self': this };
                    $('.pdp-bid-placebid').removeClass('currentlyprocessing');
                    that.pdpBidCenterPollProcess = false;
                    that.pdpBidCenterPoll(options);
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:pdpBidExpire|" + e, null, 1);
                }
            },
            pdpExpireCallback: function (options) {
                try {
                    options = options || {};
                    var self = options.self || this,
                        isMobile = $(window).width() < 768 ? true : false;

                    $('.pdp-bid-placebid').html(currentlyprocessing);
                    $('#auct-ends-head').addClass('hide');
                    if (pdpDetails.isAllowedPreSale || (listingType == 'TRNL' && isMobile) || (listingType == 'AUCN' && !self.pdpBidtimer.expired)) {
                        self.pdpShowHideStick(1);
                    } else {
                        self.pdpShowHideStick(0);
                    }

                    $('.pdp-bid-placebid').addClass('currentlyprocessing');
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:pdpExpireCallback|" + e, null, 1);
                }
            },

            /**
             * Best bid and offer negotiation changes Start
             */
            openOfferNegotiationPopup: function () {
                var self = this;

                var content = { 'content': placeBestBidLabelContent, 'response': {} };
                $('#pdpPopups .modal-body .popUpInformation').html(placeBestBidTemp(content)).fadeIn('slow');
                $('#buyerDefaultScreen').hide();
                $('#pdpPopups').modal("show");
                $('#pdpPopups .modal-header .headerText').text(placeBestBidLabelContent.confirmOfferButton);
                $('#pdpPopups').addClass('confirmOfferModalOpen');
                self.populateConfirmNegotiationDetails();
            },

            openPlaceBestBidDetails: function () {
                var url = contextPath + "/portal/getBuyerBidsCountered?listingId=" + pdpDetails.businessListingId;
                var that = this;
                $(".global-Mask").show();
                var that = this;
                var inputData = {};
                dUtil.jqueryGet(url, inputData, this.openPlaceBestBidDetailsCallBack, this);
                $('#pdpPopups').addClass('bestBidModalOpen');
                $("#pdpPopups .modal-header .headerText").text(placeBestBidLabelContent.title);

            },

            populateConfirmNegotiationDetails: function () {

                var self = this,
                    url = contextPath + '/portal/getSellerFinalNegotiationDetails?listingId=' + pdpDetails.businessListingId;

                $('#placeBestBidDetails').html('');
                $('#buyerDefaultScreen').hide();

                $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                $(".iframePopupPreLoaderImg").show();


                if (self.populateBestBidCall != undefined) self.populateBestBidCall.abort();
                self.populateBestBidCall = $.ajax({
                    url: url,
                    type: "GET",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (response) {

                        response = response || {};
                        // response = {"sellerFinalNegotiationAvailable":true,"sellerFinalNegotiationAccepted":false,"sellerFinalNegotiationBuyerDetail":{"id":null,"value":null,"firstName":"fname","lastName":"lname","emailId":"ayeeshabuyer@gmail.com","bidId":"B_3558064534018","bidPlacedByAgent":"N"},"solicitationAmount":75001,"bidId":"B_3558064534018","statusMessage":null};

                        $(".global-Mask").hide();
                        if (response.sellerFinalNegotiationAvailable != null && response.sellerFinalNegotiationAvailable) {

                            var obj = { "content": placeBestBidLabelContent, "response": response, "nobuyerPremium": noBuerPremiumProperty };
                            $('#buyerDefaultScreen').hide();
                            $('#placeBestBidDetails').html(confirmNegotiation(obj)).fadeIn('slow');
                            if (response.sellerFinalNegotiationBuyerDetail != null) {
                                $("#placeBestBidDetailsModal .buyerEmailId").html(response.sellerFinalNegotiationBuyerDetail.firstName + " " + response.sellerFinalNegotiationBuyerDetail.lastName);
                            }
                            $('.bid-plus-minus input').val(response.solicitationAmount).attr("disabled", "disabled");
                            self.buyersPremium(response.solicitationAmount);

                            $(".iframePopupPreLoaderImg").hide();
                            $('.bestbidhint').hide();

                            $('#placeBestBidDetails .cross, #placeBestBidDetails .cancelButton').unbind('click').bind('click', function (e) {
                                var trackText = globalParam.config.userId + '|' + self.listingId + '|' + self.bid;
                                GAUtils.eventTracking.trackevent("My Bids", "Confirm Negotiation Cancel Click", trackText);
                                $('#pdpPopups').modal("hide");
                                $('#pdpPopups').removeClass("bestBidModalOpen confirmOfferModalOpen");
                            });

                            $('#thankyouOk').unbind('click').bind('click', function (e) {
                                $('#pdpPopups').modal("hide");
                                $('#pdpPopups').removeClass("bestBidModalOpen confirmOfferModalOpen");
                            });
                            $('#submitPlaceBestBid').off('click').click(function (e) {
                                if (!$(this).hasClass('disabled')) {
                                    self.submitConfirmNegotiation();
                                }
                            });


                        } else if (response.statusMessage == 'SESSION_REQUIRED') {


                            loginRegisterView.openLoginPopup();

                            $('#pdpPopups').modal("hide");

                            $('#closeRegisterLogin').on('click', function () {
                                self.openOfferNegotiationPopup();
                            });
                            return;
                        }

                    },
                    error: function () {
                        console.log("Data Loaded: " + data);
                    }
                });
            },


            openPlaceBestBidDetailsCallBack: function (outData, inputData, self) {
                $(".global-Mask").hide();
                if (outData.status == 'SUCCESS') {
                    if (outData.counteredBuyerBids.length > 1) {
                        outData.isAgentRole = true;
                    } else {
                        outData.isAgentRole = false;
                    }
                    self.isAgentRole = outData.isAgentRole;
                    var content = { 'content': placeBestBidLabelContent, 'response': outData };

                    $('#pdpPopups .modal-body .popUpInformation').html(placeBestBidTemp(content)).fadeIn('slow');
                    $('#buyerDefaultScreen').hide();
                    $('#pdpPopups').modal("show");

                    self.populateBestBidDetails();
                } else if (outData.status == 'SESSION_INVALID') {

                    loginRegisterView.openLoginPopup();
                    $('#closeRegisterLogin').on('click', function () {
                        self.openPlaceBestBidDetails();
                    });

                    return;
                }

            },
            populateBestBidDetails: function () {
                var self = this;
                if (this.isAgentRole) {
                    $('#bestBuyerDetails').val($('#buyerListCountered').val());
                    $('#bestBuyerDetails').attr('bidPlacedBy', $("#buyerListCountered").attr("bidPlacedBy"));
                }
                $('#placeBestBidDetails').html('');
                $('#buyerDefaultScreen').hide();
                if ($('#buyerListCountered').val() == '') {
                    $(".iframePopupPreLoaderImg").hide();
                    $('#buyerDefaultScreen').show();
                    $('#closeButtonPopup').unbind('click').bind('click', function (e) {
                        $('#pdpPopups').modal("hide");
                    });
                    $('#buyerListCountered').change(function () {
                        self.populateBestBidDetails()
                    });
                    return;
                }

                $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                var buyerBidId = $('#bestBuyerDetails').val();
                this.prevBidVal = 0;
                var bidPlacedByAgent = $("#bestBuyerDetails").attr("bidPlacedBy");
                this.buyerBidId = buyerBidId;
                this.bidPlacedByAgent = bidPlacedByAgent;
                var inputData = { "bidId": buyerBidId, "listingId": pdpDetails.businessListingId, "sellerAccountId": sellerId, "bidPlacedByAgent": bidPlacedByAgent };
                var url = contextPath + '/portal/getPlaceBestBidDetails';
                $(".iframePopupPreLoaderImg").show();
                var data = JSON.stringify(inputData);
                var self = this;
                if (this.populateBestBidCall != undefined) this.populateBestBidCall.abort();
                this.populateBestBidCall = $.ajax({
                    url: url,
                    type: "POST",
                    data: data,
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (response) {
                        if (response.statusMessage == 'SUCCESS') {
                            self.bidIncrementRange = parseInt(response.minBidIncrement);
                            self.bidMaxLimit = response.allowedHighestBuyerResponse;
                            self.bidMinLimit = response.bidAmount = response.nextBidResponse;
                            self.isBackupBidRevampApplicable = response.isBackupBidRevampApplicable;

                            response.minimumBidAmount = '$' + response.bidAmount.formatMoney();

                            response.alreadyPlacedBestBid = "";
                            if (response.existingBuyerResponseAmount !== 0) {
                                response.alreadyPlacedBestBid = response.existingBuyerResponseAmount;
                            }
                            var obj = { "content": placeBestBidLabelContent, "response": response, "nobuyerPremium": noBuerPremiumProperty };
                            $('#buyerDefaultScreen').hide();
                            $('#placeBestBidDetails').html(placeBestBidDetails(obj)).fadeIn('slow');


                            if (self.isBackupBidRevampApplicable == "N") {
                                $('.bid-plus-minus input').val(self.bidMinLimit);
                                // self.pdpBidPlusMinus();
                                self.bidPlusMinus();
                            } else {
                                $('#editBidDetailsModal .saveAutoBid, #placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                                $('#techFee,#buyerPremium,#totalDue').html("-");
                                $('.buyerPremiumPercentage').html("");

                                $('#techFee,#buyerPremium,#totalDue').html("-");

                                var url = contextPathPortal + '/v2.0/getAvailableBackUpBidAmount/' + response.listingId + '/0';
                                self.backupOnload = true;
                                dUtil.jqueryGet(url, 0, self.getBackupBidDetailsCallback, self);
                                $("#bestBid-amount-custom").blur(function (e) {
                                    self.buyerPremiumCalculations(e);
                                });
                            }

                            self.isBidDepositRequired = response.isBidDepositRequired ? response.isBidDepositRequired : 'N';
                            self.auctionEndTime = response.bidPlacingTime ? response.bidPlacingTime : '';
                            self.timeLeft = response.auctionTimeLeft ? response.auctionTimeLeft : '';

                            var bdayOption = '<option value="">Select duration</option>';
                            _.each(response.backupBidExpiryDaysList, function (days) {
                                bdayOption += '<option value="' + days + '">' + days + '</option>';
                            });

                            $('#backupExpireSelect').html(bdayOption);
                            $(".iframePopupPreLoaderImg").hide();
                            if (typeof propStatus != 'undefined' && propStatus == 'SPND') {
                                $('input[name=backupBidAvailable ][value="yes"]').attr('checked', 'checked');
                                $('input[name=backupBidAvailable ]').attr('disabled', 'disabled').addClass('disableOption');
                            } else {
                                $('.backUpBids .backupExpireSelect').attr("disabled", "disabled");
                                $('.backUpBids .backupExpireSelect').addClass('disabled');
                            }


                            $('.radio-wrapper input[name="backupBidAvailable"]').unbind('change').bind('change', function () {
                                // var backupBidAvailable = $('#backupBidAvailable:radio:checked').length > 0;
                                var backupBidAvailable = $("input[name='backupBidAvailable']:checked").val();
                                $('.bid-expiry-error').addClass('hide');
                                if (backupBidAvailable && backupBidAvailable == 'yes') {
                                    $('.backUpBids .backupExpireSelect').prop("disabled", false);
                                    $('.backUpBids .backupExpireSelect').removeClass('disabled');
                                    $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                                } else {
                                    $('.backUpBids .backupExpireSelect').val("");
                                    $('.backUpBids .backupExpireSelect').attr("disabled", "disabled");
                                    $('.backUpBids .backupExpireSelect').addClass('disabled');
                                    $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled');
                                    $('.conditions-txt').addClass('hide');
                                }
                            });
                            $('.backUpBids .backupExpireSelect').unbind('change').bind('change', function () {
                                var durationSelected = $(".backUpBids .backupExpireSelect option:selected").val();
                                $('.bid-expiry-error').addClass('hide');
                                self.calculateDuration();
                                if (durationSelected) {
                                    $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled');
                                } else {
                                    $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                                }
                            });
                            $('#placeBestBidDetails .cross, #placeBestBidDetails .cancelButton').unbind('click').bind('click', function (e) {
                                var trackText = globalParam.config.userId + '|' + self.listingId + '|' + self.bid;
                                GAUtils.eventTracking.trackevent("My Bids", "Place Best-Bid Cancel Click", trackText);
                                $('#pdpPopups').modal("hide");
                            });

                            $('#thankyouOk').unbind('click').bind('click', function (e) {
                                $('#pdpPopups').modal("hide");
                            });
                            $('#submitPlaceBestBid').off('click').click(function (e) {
                                if (!$(this).hasClass('disabled')) {
                                    if (self.isBackupBidRevampApplicable == "N") {
                                        self.submitBestBidDetail();
                                    } else {
                                        self.buyerPremiumCalculations(e);
                                    }
                                }
                            });
                        } else if (response.statusMessage == 'SESSION_REQUIRED') {
                            //$('#loginModal').modal('show');
                            // $('#loginModal').on('hidden.bs.modal', function () {

                            //     self.openPlaceBestBidDetails();
                            // });

                            loginRegisterView.openLoginPopup();

                            $('#pdpPopups').modal("hide");

                            $('#closeRegisterLogin').on('click', function () {
                                self.openPlaceBestBidDetails();
                            });
                            return;
                        }

                    },
                    error: function () {
                        console.log("Data Loaded: " + data);
                    }
                });

            },

            calculateDuration: function () {

                try {
                    // var durationTxt = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.durationText;
                    // $('.conditions-txt').html('');
                    // $('.conditions-txt').html(durationTxt);
                    var self = this;
                    var auctionEndTime = self.auctionEndTime || null;
                    var timeLeft = self.timeLeft || null;
                    var isBidDepositRequired = self.isBidDepositRequired || null;
                    var perDay = 86400000;
                    // if(timeLeft == 0){
                    // 	auctionEndTime = bidDetails.bidConfigAttributes.currentDate;
                    // }
                    var durationSelected = $("#backupExpireSelect option:selected").val();
                    if (durationSelected && auctionEndTime != null && isBidDepositRequired == "Y") {
                        $(".conditions-txt").removeClass("hide");
                        var totalDays = parseInt(auctionEndTime) + (parseInt(durationSelected) * perDay);
                        var d = new Date(totalDays);
                        var months = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
                        var message = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.durationText;
                        var date = months[d.getMonth()] + " " + self.addZero(d.getDate()) + ", " + d.getFullYear();
                        message = message.replace("###date###", date)
                        $(".conditions-txt").html(message);
                    } else {
                        $(".conditions-txt").addClass("hide");
                    }
                } catch (error) {
                    GAUtils.eventTracking.trackevent("Error", "Bid Center", "file:pdpBidCenter.js|function:calculateDuration|" + error, null, 1);
                }
            },

            addZero: function (value) {
                if (value < 10) {
                    return "0" + value;
                } else {
                    return value;
                }

            },

            buyerPremiumCalculations: function (e) {
                try {
                    var that = this;
                    var bidval = parseInt($('#bestBid-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));

                    if (isNaN(bidval)) {
                        $('#techFee,#buyerPremium,#totalDue').html("-");
                        $('.bid-error-msg').html(saveListDrawerContent.backupBidContent.error_code['empty_mess']);
                        return;
                    }
                    $('.bid-error-msg').html("");

                    $('#bestBid-amount-custom').val(dUtil.formatCurrencyLabel(bidval));
                    var listingId = $('#bestBid-amount-custom').attr("data-listingId");


                    if (!$(e.currentTarget).hasClass("saveAutoBid")) {
                        $('#editBidDetailsModal .saveAutoBid, #placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                        $('#buyerPremium').html("-");
                        $('#techFee').html("-");
                        $('#totalDue').html("-");
                    }


                    //dUtil.showLoader();
                    var url = contextPathPortal + '/v2.0/getAvailableBackUpBidAmount/' + listingId + '/' + bidval;
                    dUtil.jqueryGet(url, bidval, that.getBackupBidDetailsCallback, that);
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:buyerPremiumCalculations|" + e, null, 1);
                }

            },
            getBackupBidDetailsCallback: function (options, inputData, callBackParam) {
                try {
                    options = options || {};
                    console.log(options);
                    var self = callBackParam,
                        response = options || {},
                        inputData = {},
                        apiStatus = options.status || 'ERROR',
                        status = response.status || "FAILURE",
                        data = response.data || {},
                        userMessage;
                    if (response.status == "SUCCESS") {
                        //var parentId = $('#bestBid-amount-custom').parents('.required')
                        $(".bid-error-msg").removeClass('error');
                        if (response.data.hideDisplayMessage) {
                            userMessage = '';
                        } else {
                            userMessage = (saveListDrawerContent.backupBidContent.error_code['user_message']).replace('###MIN_AMOUNT###', response.data.formattedMinBackUpBidLimit).replace('###MAX_AMOUNT###', response.data.formattedMaxBackUpBidLimit).replace('###INC_AMOUNT###', response.data.formattedIncrementMultplier).replace('###NEXT_AMOUNT###', response.data.formattedMinBackUpBidLimit);
                        }
                        if ($('.b_bid_amount_msg').html() == '') {
                            $('.b_bid_amount_msg').html(userMessage).show();
                        }
                        if (response.data.hideDisplayMessage == true) {
                            $('.b_bid_amount_msg').hide();
                        }
                        if (!self.backupOnload) {
                            if (response.data.errorCode) {
                                userMessage = (saveListDrawerContent.backupBidContent.error_code[response.data.errorCode]).replace('###MIN_AMOUNT###', response.data.formattedMinBackUpBidLimit).replace('###MAX_AMOUNT###', response.data.formattedMaxBackUpBidLimit).replace('###INC_AMOUNT###', response.data.formattedIncrementMultplier).replace('###NEXT_AMOUNT###', response.data.formattedMinBackUpBidLimit);
                                $('.bid-error-msg').removeClass('h-error-message');
                                $('.bid-error-msg').html(userMessage).show();
                                self.backupOnload = false;
                            } else {

                                self.backupOnload = false;
                                if ($("#submitPlaceBestBid").hasClass('disabled')) {
                                    var bidval = parseInt($('#bestBid-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));
                                    var listingId = $('#bestBid-amount-custom').attr("data-listingId");
                                    self.buyersPremium(bidval);
                                } else {
                                    console.log("submit Place Bid");
                                    self.submitBestBidDetail();
                                }
                            }
                        }
                        self.backupOnload = false;

                    }
                } catch (e) {
                    GAUtils.eventTracking.trackevent("Error", "pdpView", "file:pdpBidCenter.js|function:getBackupBidDetailsCallback|" + e, null, 1);

                }
            },

            buyersPremium: function (bidval) {
                var url = contextPath + '/portal/buyerPremium/' + pdpDetails.businessListingId + '/' + bidval;
                var that = this;
                $('#placeBestBidDetailsModal .saveAutoBid').addClass('disabled');
                if (that.buyersPremiumCall != undefined) that.buyersPremiumCall.abort();
                that.buyersPremiumCall = $.ajax({
                    url: url,
                    success: function (data) {
                        if (data != '') {
                            var bidval = parseInt($('#bestBid-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));
                            var buyerPremium = parseFloat(data.buyerPremiumFormatted.replaceAll(',', '').replaceAll('$', ''));
                            var techFee = parseInt(data.technologyFeeFormatted.replaceAll(',', '').replaceAll('$', ''));
                            // var totalValue;
                            // if (data.premiumPaidBy == 'SELLER') {
                            //     totalValue = bidval;
                            // } else {
                            //     totalValue = bidval + buyerPremium + techFee;
                            // }
                            // var total = '$'+totalValue;

                            var totalValue;
                            if (data.premiumPaidBy == 'SELLER') {
                                totalValue = data.bidAmountFormatted;
                            } else {
                                totalValue = data.totalDueFormatted;
                            }

                            $('#buyerPremium').html(data.buyerPremiumFormatted);
                            $('.buyerPremiumPercentage').html(" (" + data.percentageFormatted + ")");
                            if (data.technologyFeeFormatted != "$0") {
                                $('#techFee').html(data.technologyFeeFormatted);
                                $("#techFeePlaceBestBidSection").removeClass("hidden");
                            }
                            $('#totalDue').html(totalValue);
                            $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled');
                        }

                    }

                });
            },

            submitBestBidDetail: function () {
                var bidval = parseInt($('#bestBid-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));
                var backupBidExpiryDays = $('#backupExpireSelect').val();
                // var backupBidAvailable = ($('#backupBidAvailable:checkbox:checked').length > 0)? true : false;
                var backupBidAvailableValue = $("input[name='backupBidAvailable']:checked").val();
                var backupBidAvailable = (backupBidAvailableValue && backupBidAvailableValue == 'yes') ? true : false;
                if (backupBidAvailable && !backupBidExpiryDays) {
                    $('.bid-expiry-error').removeClass('hide');
                    $('.backUpBids .backupExpireSelect').prop("disabled", false);
                    $('.backUpBids .backupExpireSelect').removeClass('disabled');
                    $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled').addClass('disabled');
                    return;

                }
                $('#somethingWrong').hide();
                var userId = typeof window.userSession !== "undefined" && window.userSession.userId || '-';
                var inputData = { "bidId": this.buyerBidId, "listingId": pdpDetails.businessListingId, "sellerAccountId": sellerId, "bidPlacedByAgent": this.bidPlacedByAgent, "buyerResponseAmount": bidval, "backupBidAvailable": backupBidAvailable, "backupBidExpiryDays": backupBidExpiryDays };
                var trackText = "|userID:" + userId + "listingId:" + pdpDetails.businessListingId + "|bidAmount:" + bidval + "|backupBidAvailable" + backupBidAvailable;
                GAUtils.eventTracking.trackevent("Best Bid Wizard", "Submit Best-Bid Click", trackText);
                var url = contextPath + '/portal/submitBestBidResponse';
                var data = JSON.stringify(inputData);
                var self = this;
                $(".global-Mask").show();
                $.ajax({
                    url: url,
                    type: "POST",
                    data: data,
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (response) {
                        $(".global-Mask").hide();
                        if (response === null) {
                            $('#somethingWrong').html(placeBestBidLabelContent.errorMsg.somethingWentWrong);
                            $('#somethingWrong').show();
                            var trackText = pdpDetails.propertyId + "|userID:" + userId + "|visitorId:" + visitorID + '| Place Best Bid Call Failed';
                            GAUtils.eventTracking.trackevent("Best Bid Wizard", "Submit Best-Bid Click-Fail", trackText);
                        } else {
                            if (response.statusMessage == 'SUCCESS') {
                                if (typeof response.errorMessageCode !== 'undefined' && response.errorMessageCode == 'PLACE_BEST_BID_BKP_NUM_OF_DAYS_NULL') {
                                    $('.bid-expiry-error').html(placeBestBidLabelContent.errorMsg.expiryDate);
                                    $('.bid-expiry-error').removeClass('hide');
                                    $('.backUpBids .backupExpireSelect').prop("disabled", false);
                                    $('.backUpBids .backupExpireSelect').removeClass('disabled');
                                    $('#placeBestBidDetailsModal .saveAutoBid').removeClass('disabled').addClass('disabled');
                                } else {
                                    $('.bestBidDiv').addClass('hide');
                                    $('.thankyou').removeClass('hide');
                                    var trackText = pdpDetails.propertyId + "|userID:" + userId + "|visitorId:" + visitorID + '| Bid Type: Counter bid' + '| Place Best Bid Call Success';
                                    // GAUtils.eventTracking.trackevent("Best Bid Wizard","Submit Best-Bid Click Success", trackText);

                                }
                                GAUtils.eventTracking.trackevent("NEW-PDP", "Bid Placed", trackText);

                            } else if (response.statusMessage == 'SESSION_REQUIRED') {
                                $('#pdpPopups').modal("hide");
                                //$('#loginModal').modal('show');
                                // $('#loginModal').on('hidden.bs.modal', function () {

                                //     self.openPlaceBestBidDetails();
                                // });                            

                                loginRegisterView.openLoginPopup();
                                $('#closeRegisterLogin').on('click', function () {
                                    self.openPlaceBestBidDetails();
                                });

                                return;
                            } else {
                                $('.bid-error-msg').html(placeBestBidLabelContent.errorMsg.somethingWentWrong).show();
                                var trackText = pdpDetails.propertyId + "|userID:" + userId + "|visitorId:" + visitorID + '| Place Best Bid Call Error';
                                GAUtils.eventTracking.trackevent("Best Bid Wizard", "Submit Best-Bid Click-Fail", trackText);
                            }
                        }
                    }
                });
                var trackText = globalParam.config.userId + '|' + pdpDetails.businessListingId + '|' + this.buyerBidId + '|' + bidval;
                if (inputData.backupBidAvailable) {
                    trackText = trackText + '|' + inputData.backupBidExpiryDays;
                }

                $('#somethingWrong').hide();
            },

            submitConfirmNegotiation: function () {
                var bidval = parseInt($('#bestBid-amount-custom').val().replaceAll(',', '').replaceAll('$', ''));
                var placeBestBidLabelContent = cmsJSONContent.sections.pdpBodySection.pdpBidCenter.placeBestBidLabel;
                var bidId = _.escape($('#bid-id').val());
                $('#somethingWrong').hide();
                if (window.userSession.user != undefined) {
                    var userId = window.userSession.user.realbidUserId;
                } else {
                    var userId = 'NA';
                }
                var trackText = "|userID:" + userId + "listingId:" + pdpDetails.businessListingId + "|bidAmount:" + bidval;
                GAUtils.eventTracking.trackevent("Confirm Offer Negotiation Wizard", "Submit Best-Bid Click", trackText);
                var url = contextPath + '/portal/confirmFinalNegotiation/' + pdpDetails.businessListingId + '/' + bidId + '/' + userId;
                var self = this;
                $(".global-Mask").show();
                $.ajax({
                    url: url,
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (response) {

                        // response = response || {};
                        // response = {"status":"SUCCESS","errorMessage":null,"errorTrace":null,"successMessage":"Negotiation Acceptance processed Successfully","newBidId":"B_29260830854046"};

                        $(".global-Mask").hide();
                        if (response === null) {
                            $('#somethingWrong').html(_.escape(placeBestBidLabelContent.errorMsg.somethingWentWrong));
                            $('#somethingWrong').show();
                            var trackText = pdpDetails.propertyId + "|userID:" + userId + "|visitorId:" + visitorID + '| Place Best Bid Call Failed';
                            GAUtils.eventTracking.trackevent("Confirm Offer Negotiation Wizard", "Submit Best-Bid Click-Fail", trackText);
                        } else {
                            if (response.status == 'SUCCESS') {
                                $('.bestBidDiv').addClass('hide');
                                $('.thankyou').removeClass('hide');
                                var trackText = pdpDetails.propertyId + "|userID:" + userId + "|visitorId:" + visitorID + '| Place Offer Negotiation Call Success';
                                GAUtils.eventTracking.trackevent("Confirm Offer Negotiation Wizard", "Submit Offer Negotiation Click Success", trackText);
                                $('.place-backupbid-button').val(placeBestBidLabelContent.pdpBackupBidButton);
                                $('#bidCenterDescription').html(_.escape(placeBestBidLabelContent.underreviewdesc));
                            } else if (response.status == 'SESSION_REQUIRED') {
                                $('#pdpPopups').modal("hide");
                                loginRegisterView.openLoginPopup();
                                $('#closeRegisterLogin').on('click', function () {
                                    self.openOfferNegotiationPopup();
                                });

                                return;
                            } else {
                                // $('.bid-error-msg').html(_.escape(placeBestBidLabelContent.errorMsg.somethingWentWrong)).show();
                                $('#somethingWrong').html(_.escape(placeBestBidLabelContent.errorMsg.somethingWentWrong));
                                $('#somethingWrong').show();
                                var trackText = pdpDetails.propertyId + "|userID:" + userId + "|visitorId:" + visitorID + '| Offer Negotiation Call Error';
                                GAUtils.eventTracking.trackevent("Confirm Offer Negotiation Wizard", "Submit Best-Bid Click-Fail", trackText);
                            }
                        }
                    }
                });


                $('#somethingWrong').hide();
            },

            checkForCifCompletion : function(listingId, status){
                try{
                    var self = this;
                    $.ajax ({
                        url: contextPath+'/portal/v2.0/getPreBidCifDetails',
                        type: "POST",
                        data: JSON.stringify({
                            listingId: listingId,
                            buyerEmailId: "",
                            propertyId: pdpDetails.propertyId
                        }),
                        dataType: "json",
                        contentType: "application/json; charset=utf-8",
                        success: function(response){
                            if(status === "PACT") {
                                if(!response.data.cifCompleted) { // cif not completed
                                    // redirect to Bid Wizard
                                    self.decideTarget();
                                } else {
                                    //CIF completed change the CTA to registration completed
                                    if ($('.reg-button').length) {
                                        $('.reg-button').val('Registration Completed');
                                        // disable the button
                                        $('.reg-button').attr("disabled", "disabled");
                                    } 
    
                                    $('#ajax-loader').hide();
                                    $("#ajax-loader-mask").hide();
                                    $('#loginRegisterDialog').modal('hide');
                                    $(".modal-backdrop").remove();
                                    
                                    return;
                                    
                                }
                            } else { // for ACTV status
                                if(response.data.ndaSignedIn) {
                                    self.decideTarget();
                                } else {
                                    // show NDA popup
                                    $('#ajax-loader').hide();
                                    $("#ajax-loader-mask").hide();
                                    $('#loginRegisterDialog').modal('hide');
                                    $(".modal-backdrop").remove();
                                    documentsView.openAgreementPopup({
                                        callback: function() {
                                            // After the popup completes, this callback is executed
                                            self.checkForCifCompletion(listingId, status);
                                        }
                                    });
                                }
                            }
                            
                           
                        },
                        error: function(error){
                            console.log(error);
                        }
                    });
                }catch(e){
                    GAUtils.eventTracking.trackevent("Error", "New Bid", "file:util.js|function:postCall|url:"+url+':'+e, null, 1);
                }
            }



            /**
             * Best bid and offer negotiation changes End 
             */
        });
        return pdpBidCenter;
    });