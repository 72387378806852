
define('documentsView',['require','jquery','backbone','GoogleAnalyticsUtils','commonutils','handlebars','loginRegisterView','subscribeView'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        CommonUtils = require("commonutils"),
        Handlebars = require("handlebars"),
        // template = require("hbs!tpl/temp"),
        loginRegisterView = require("loginRegisterView"),
        subscribeView = require("subscribeView"),
        DocumentsView;

    DocumentsView = Backbone.View.extend({
        events: {
            "click .h-download": "verifyDownloadRestrictions",
            "click .documentsRegBtn": "loginRegisterFromDocuments",
            "click .locked": "openAgreementPopup",
        },
        initialize: function (params) {
            this.contextPath = typeof window.contextPath !== "undefined" ? window.contextPath : "";
        },
        verifyDownloadRestrictions: function (event) {
            try {
                var targetElement = $(event.currentTarget),
                    link = targetElement.attr("link") || "",
                    self = this;

                if (self.isDownloadRestricted) {
                    self.verifyUserAcceptance(null, {
                        target: targetElement,
                        link: link
                    });
                } else if (self.isCommercial && window.userSession.userId) {

                    self.downloadDocument(link);
                    GAUtils.eventTracking.trackevent("", "commercial Document Download", targetElement.attr("name") || "NA");
                } else if (self.isCommercial && !window.userSession.userId) {
                    self.loginRegisterFromDocuments();
                    // self.downloadDocument(link);
                    // GAUtils.eventTracking.trackevent("","commercial Document Download",targetElement.attr("name") || "NA");
                } else {
                    GAUtils.eventTracking.trackevent("", "Document Download", targetElement.attr("name") || "NA");
                    self.downloadDocument(link);
                }
            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:downloadDocument|" + error,
                    null,
                    1
                );
            }
        },
        verifyUserAcceptance: function (user, options) {
            try {
                options = options || {};
                var userSession = window.userSession || {},
                    userId = userSession.userId || null,
                    self = this;

                if (!userId) {
                    loginRegisterView.openLoginPopup({
                        callback: self.verifyUserAcceptance.bind(self),
                        viewObject: options,
                        statusMessage: "Please 'Sign In' to continue."
                    });
                    return;
                }

                // TODO: set this flag after login 
                if (self.isUserAccepted) {
                    self.downloadDocument(options.link);
                } else {
                    self.showConfirmationPopup(options.link);
                }

            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:verifyUserAcceptance|" + error,
                    null,
                    1
                );
            }
        },
        loginRegisterFromDocuments: function (user, options) { // sign in or register for viewing commercial documents 
            try {
                options = options || {};
                var userSession = window.userSession || {},
                    userId = userSession.userId || null,
                    propertyId = typeof pdpDetails !== 'undefined' && pdpDetails.propertyId || '',
                    self = this;

                if (!userId) {
                    loginRegisterView.openLoginPopup({
                        callback: self.loginRegisterFromDocuments.bind(self),
                        viewObject: options,
                        statusMessage: "Please 'Sign In' to continue."
                    });
                    return;
                } else {
                    loginRegisterView.hideLoginRegisterPopup(); // hide if any popup 
                    $("#ajax-loader").hide(); //hide loader
                    $.ajax({
                        url: this.contextPath + '/property/getNewPdpDetails/' + propertyId,
                        type: "GET",
                        dataType: 'json',
                        success: function (response) {
                            if (response.propertyDocumentsInformation) {
                                self.render({
                                    documentsInfo: response.propertyDocumentsInformation,
                                    address: response.propertyDetails && response.propertyDetails.address || {},
                                    isDownloadRestricted: response.restrictDocumentDownloadFlag || 'N',
                                    isCommercial: (response.propertyDetails.commonAttributes.propCategory === "COMMERCIAL") ? true : false,
                                    isNdaAccepted: (response.ndaCommercialAccepted === "Y") ? true : false
                                });
                            }
                        },
                        error: function (error) {
                            console.log(error);
                        }
                    });
                }


            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:documentsView.js|function:loginRegisterFromDocuments|" + error,
                    null,
                    1
                );
            }
        },
        showConfirmationPopup: function (link) {
            try {

                var template = $("#documentConfirmationModal").html(),
                    self = this;

                template = Handlebars.compile(template);
                template = template({});

                $("#bidCenterPopup").modal("show");
                $("#bidCenterPopup .modal-body").html(template);

                $(".doc-confirm-btn").off('click').on('click', function (event) {
                    self.saveUserPreference(link);
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:showConfirmationPopup|" + error,
                    null,
                    1
                );
            }
        },
        getUserPreference: function () {
            try {

                var self = this,
                    URL = typeof contextPath !== "undefined" ? contextPath : '',
                    propertyId = typeof pdpDetails !== 'undefined' && pdpDetails.propertyId || '';

                URL += "/portal/hasUserAcceptedForeClosureDocument/" + propertyId;

                $.ajax({
                    url: URL,
                    success: function (response) {
                        if (response.status === "SUCCESS" && response.data === "Y") {
                            self.isUserAccepted = true;
                            return;
                        }
                        self.isUserAccepted = false
                    },
                    error: function (xhr, status, error) {
                        self.isUserAccepted = false
                        GAUtils.eventTracking.trackevent(
                            "Error",
                            "Documents Section",
                            "file:CalculatorView.js|function:getUserPreference|" + error,
                            null,
                            1
                        );
                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:getUserPreference|" + error,
                    null,
                    1
                );
            }
        },
        saveUserPreference: function (link) {
            try {
                var self = this,
                    URL = typeof contextPath !== "undefined" ? contextPath : '',
                    userSession = window.userSession || {},
                    userId = userSession.userId || 'NA',
                    propertyId = typeof pdpDetails !== 'undefined' && pdpDetails.propertyId || '';

                URL += "/portal/saveUserAcceptanceForeClosureDocument";
                self.validateMandatoryFields("#docProfileAddress, #docProfileState, #docProfileCity, #docProfileZip, #docProfileCompanyName");
                $(".doc-confirm-btn").addClass('disabled');

                $.ajax({
                    url: URL,
                    dataType: "json",
                    type: 'POST',
                    data: {
                        propertyId: propertyId
                    },
                    success: function (response) {
                        if (response.status == 'SUCCESS') {
                            GAUtils.eventTracking.trackevent("", "Document Download Acceptance", propertyId + "|" + userId);
                            self.isUserAccepted = true;

                            self.downloadDocument(link);
                            $("#bidCenterPopup").modal("hide");

                        } else if (response.status == 'FAILURE') {
                            $("#bidCenterPopup .confirm-error").removeClass('hide');
                        }
                    },
                    error: function (xhr, status, error) {
                        $("#bidCenterPopup .confirm-error").removeClass('hide');
                        GAUtils.eventTracking.trackevent(
                            "Error",
                            "Documents Section",
                            "file:CalculatorView.js|function:saveUserPreference|" + error,
                            null,
                            1
                        );
                    },
                    complete: function () {
                        $(".doc-confirm-btn").removeClass('disabled');
                    }
                });
            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:saveUserPreference|" + error,
                    null,
                    1
                );
            }
        },
        saveUserAcceptance: function (callback) {
            try {
                $("#ajax-loader").show();
                var self = this,
                    URL = typeof contextPath !== "undefined" ? contextPath : '',
                    userSession = window.userSession || {},
                    userId = userSession.userId || 'NA',
                    propertyId = typeof pdpDetails !== 'undefined' && pdpDetails.propertyId || '';

                URL += "/portal/save-nda-acceptance?propertyId=" + propertyId + '&userId=' + userId;

                $(".doc-confirm-btn").addClass('disabled');
                $.ajax({
                    url: URL,
                    dataType: "json",
                    type: 'POST',
                    success: function (response) {
                        $("#ajax-loader").hide();
                        if (response.status == 'SUCCESS') {
                            GAUtils.eventTracking.trackevent("", "commercial Document Viewing Acceptance", propertyId + "|" + userId);
                            $("#bidCenterPopup").modal("hide"); // close popup
                            $(".link-container").removeClass("locked"); // remove lock
                            // fav the property 
                            self.autoFavPropertyOnNDACompletion(propertyId);

                            // Once the API call completes successfully, invoke the callback passed from the openAgreementPopup function
                            if (typeof callback === "function") {
                                callback();
                            }
                        } else if (response.status == 'FAILURE') {
                            $("#bidCenterPopup .confirm-error").removeClass('hide');
                        }
                    },
                    error: function (xhr, status, error) {
                        $("#ajax-loader").hide();
                        $("#bidCenterPopup .confirm-error").removeClass('hide');
                        GAUtils.eventTracking.trackevent(
                            "Error",
                            "Documents Section",
                            "file:CalculatorView.js|function:saveUserPreference|" + error,
                            null,
                            1
                        );
                    },
                    complete: function () {
                        $("#ajax-loader").hide();
                        $(".doc-confirm-btn").removeClass('disabled');
                    }
                });
            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:saveUserPreference|" + error,
                    null,
                    1
                );
            }
        },

        downloadDocument: function (docUrl) {
            try {
                var self = this, link, opened;

                if (typeof isMobileApp !== "undefined" && isMobileApp == "Y") {
                    window.location.href = docUrl;
                } else {
                    if (
                        navigator &&
                        navigator.userAgent &&
                        (/Firefox/.test(navigator.userAgent) ||
                            /Chrome/.test(navigator.userAgent))
                    ) {
                        link = document.createElement("a");
                        link.href = docUrl;
                        link.id = Math.random();
                        link.target = "_blank";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    } else {
                        opened = window.open(docUrl, "_blank");
                        if (!opened) {
                            // Apple does not allow window.open, see https://developer.apple.com/library/safari/documentation/Tools/Conceptual/SafariExtensionGuide/WorkingwithWindowsandTabs/WorkingwithWindowsandTabs.html
                            window.location.href = docUrl;
                        }
                    }
                }
            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:downloadDocument|" + error,
                    null,
                    1
                );
            }
        },
        render: function (params) {
            try {
                params = params || {},
                    self = this;
                var documentsInfo = params.documentsInfo || {},
                    docsMap = documentsInfo.propertyDocumentMap || {},
                    otherDocs = documentsInfo.otherDocumentList || [],
                    documents = [],
                    address = params.address || {};

                self.isDownloadRestricted =
                    params.isDownloadRestricted === "Y" ? true : false;
                self.isCommercial = params.isCommercial;
                self.isNdaAccepted = params.isNdaAccepted;
                _.each(docsMap, function (propDocObj) {
                    if (self.isCommercial) {
                        if (propDocObj.documentName && propDocObj.fileListId) {
                            documents.push({
                                name: propDocObj.documentName,
                                url: "/property/getDocument/" + propDocObj.fileListId +"?fileName="+propDocObj.documentName,
                            });
                        }
                    } else {
                        if (propDocObj.documentName && propDocObj.documentUrl) {
                            documents.push({
                                name: propDocObj.documentName,
                                url: propDocObj.documentUrl,
                            });
                        }
                    }

                });

                _.each(otherDocs, function (propOtherDocObj, key) {
                    if (self.isCommercial) {
                        if (propOtherDocObj.documentName && propOtherDocObj.fileListId) {
                            documents.push({
                                name: propOtherDocObj.documentName,
                                url: "/property/getDocument/" + propOtherDocObj.fileListId,
                            });
                        }
                    } else {
                        if (propOtherDocObj.documentName && propOtherDocObj.documentUrl) {
                            documents.push({
                                name: propOtherDocObj.documentName,
                                url: propOtherDocObj.documentUrl,
                            });
                        }
                    }

                });

                if (documents.length) {

                    var renderedTemplate = $("#documentsTemplate").html();
                    var lockedTemplate = $("#lockedTemplate").html();
                    renderedTemplate = Handlebars.compile(renderedTemplate);
                    renderedTemplate = renderedTemplate({
                        address1: address.streetNumber,
                        address2: address.streetName,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                        documents: documents,
                    });
                    lockedTemplate = Handlebars.compile(lockedTemplate);
                    lockedTemplate = lockedTemplate({
                        address1: address.streetNumber,
                        address2: address.streetName,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                        documents: documents,
                    });


                    if (this.isCommercial) {
                        if (!window.userSession.userId) { // if user is not logged in 
                            $(".documents-container")
                                .html(lockedTemplate)
                                .removeClass("hide");
                        } else { // logged In then check for NDA
                            $(".documents-container")
                                .html(renderedTemplate)
                                .removeClass("hide");
                            if (!this.isNdaAccepted) {
                                $(".link-container").addClass("locked"); // add lock
                            } else {
                                $(".link-container").removeClass("locked"); // remove lock
                            }
                        }

                    } else {
                        $(".documents-container")
                            .html(renderedTemplate)
                            .removeClass("hide");
                    }
                    $(".documentsRegBtn").off('click').on('click', function (event) {
                        self.loginRegisterFromDocuments();
                    });
                }

                return this;
            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:render|" + error,
                    null,
                    1
                );
            }
        },
        openAgreementPopup: function (options) {
            try {

                var template = $("#profileUpdateConfirmationModal").html(),
                    self = this;
                template = Handlebars.compile(template);
                template = template({});
                if (!window.userSession.userId) {
                    self.loginRegisterFromDocuments();
                    return false;
                }
                $("#doc-continue-btn").prop("disabled", false); // by default
                $("#bidCenterPopup").modal("show");
                $("#bidCenterPopup .modal-body").html(template);
                //fetch states
                $.ajax({
                    url: contextPath + "/portal/stateLists",
                    type: 'GET',
                    contentType: 'application/json',
                    success: function (response) {
                        if (response && Array.isArray(response)) {
                            var $select = $("#docProfileState");
                            // Clear any existing options except the placeholder
                            //$select.find("option:not([value='Select State'])").remove();
                            // Append each state as an option and set selected if it matches 'selectedState'
                            response.forEach(function (state) {
                                $select.append(new Option(state, state));
                            });
                            //prepopulate user data
                            if (window.userSession.user.userEmailId) {
                                $("#docuserName").text(window.userSession.user.userFirstName + " " + window.userSession.user.userLastName);
                                $("#docuserEmail").text(window.userSession.user.userEmailId);
                                $("#docuserPh").text(self.formatPhoneNumber(window.userSession.user.primaryPhone));
                                $("#docProfileState").val(window.userSession.user.state);
                                $("#docProfileCity").val(window.userSession.user.city);
                                $("#docProfileAddress").val(window.userSession.user.address1);
                                $("#docProfileZip").val(window.userSession.user.zip);
                            }

                        }

                    },
                    error: function (xhr, status, error) {
                        console.log(error);
                    }
                });

                // mandatory checks
                self.validateMandatoryFields("#docProfileAddress, #docProfileState, #docProfileCity, #docProfileZip, #docProfileCompanyName");

                $(".doc-continue-btn").off('click').on('click', function (event) {
                    self.validateMandatoryFields("#docProfileAddress, #docProfileState, #docProfileCity, #docProfileZip, #docProfileCompanyName");
                    //update the profile information
                    self.validateFields(["#docProfileAddress", "#docProfileState", "#docProfileCity", "#docProfileZip", "#docProfileCompanyName"]);
                    URL = typeof contextPath !== "undefined" ? contextPath : '',
                        propertyId = typeof pdpDetails !== 'undefined' && pdpDetails.propertyId || '';
                    $("#ajax-loader").show(); //hide loader
                    //URL += (window.userSession.user.userType !== 'BUYR') ? "/portal/lean-regn/saveUserDetails" : "/portal/lean-regn/saveBuyerProfile";
                    URL += "/portal/lean-regn/saveBuyerProfileForNDA"; // new end point for both BUYER and AGENT
                    // URL +="?"+new Date().getTime();
                    var requestDetails = {
                        "firstName": window.userSession.user.userFirstName,
                        "lastName": window.userSession.user.userLastName,
                        "emailId": window.userSession.user.userEmailId,
                        "phoneNumber": window.userSession.user.primaryPhone,
                        "address": {
                            "address1": ($("#docProfileAddress").val()) ? $("#docProfileAddress").val() : "",
                            "address2": "",
                            "state": ($("#docProfileState").val()) ? $("#docProfileState").val() : "",
                            "city": ($("#docProfileCity").val()) ? $("#docProfileCity").val() : "",
                            "cityFromDropDown": false,
                            "zip": ($("#docProfileZip").val()) ? $("#docProfileZip").val() : "",
                        },
                        "companyName": ($("#docProfileCompanyName").val()) ? $("#docProfileCompanyName").val() : "",
                        "userId": window.userSession.user.userId
                        // "userRoles":[window.userSession.user.userType]
                    };
                    var payloadData = { userDetails: requestDetails };
                    // var payloadData = (window.userSession.user.userType === 'BUYR') ? {userDetails : requestDetails, agentDetails: null} : requestDetails;
                    $.ajax({
                        url: URL,
                        type: 'POST',
                        contentType: 'application/json',  // Set the content type to JSON
                        data: JSON.stringify(payloadData),

                        success: function (response) {
                            $("#ajax-loader").hide(); //hide loader
                            if (response.status === 'SUCCESS') {
                                var template = $("#agreementConfirmationModal").html(),
                                    template = Handlebars.compile(template);
                                template = template({});

                                $("#bidCenterPopup").modal("show");
                                $("#bidCenterPopup .modal-body").html(template);

                                $("#agreeButton").off('click').on('click', function (event) {
                                    // check for the check box is checked if checked proceed else show error message
                                    if (!$("#agreementCheck").prop('checked')) {
                                        $(".check-confirm-error").removeClass("hide");
                                        return;
                                    }

                                    self.saveUserAcceptance(function () {
                                        // Once the saveUserAcceptance is successful, the actual callback is executed
                                        if (typeof options.callback === 'function') {
                                            options.callback();
                                        }
                                    });
                                    //self.saveUserAcceptance();
                                });

                            } else if (response.status === 'FAILURE' && (response.errorMessage === "VALIDATION_STATE_ZIP_FAILURE" || response.errorMessage === "REGISTRATION_ZIP_FAILURE")) {
                                // show eror on zip and disable the continue
                                //provided zip code is not valid for selected state
                                $("#docProfileZip").addClass("error")
                                $(".confirm-error").text("Provided zip code is not valid for selected state").removeClass("hide");

                            } else {
                                $(".confirm-error").removeClass("hide");
                            }

                        },
                        error: function (xhr, status, error) {
                            $("#ajax-loader").hide(); //hide loader
                            GAUtils.eventTracking.trackevent(
                                "Error",
                                "Documents Section",
                                "file:CalculatorView.js|function:getUserPreference|" + error,
                                null,
                                1
                            );
                        }
                    });
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent(
                    "Error",
                    "Documents Section",
                    "file:CalculatorView.js|function:showConfirmationPopup|" + error,
                    null,
                    1
                );
            }
        },

        // validateMandatoryFields: function validateMandatoryFields(fieldSelectors) {
        //     $(fieldSelectors).on('blur', function() {
        //         var $field = $(this);
        //         var value = $field.val().trim();
        //         var fieldName = $field.attr('id').replace('docProfile', '');

        //         if (!value) {
        //             $field
        //                 .addClass("error")
        //                 .next(".has-input-error")
        //                 .text("Please enter this field")
        //                 .removeClass("hide");
        //         } else {
        //             $field
        //                 .removeClass("error")
        //                 .next(".has-input-error")
        //                 .addClass("hide");
        //         }
        //     });
        // },
        validateMandatoryFields: function validateMandatoryFields(fieldSelectors) {
            $(fieldSelectors).on('blur', function () {
                var $field = $(this);
                var value = $field.val().trim();
                var fieldName = $field.attr('id').replace('docProfile', '');

                // Regular expression patterns
                var patterns = {
                    'Address': /^[a-zA-Z0-9|&_.:;()'"\/-\s]+$/,
                    'City': /^[a-zA-Z0-9|&_.:;()'"\/-\s]+$/,
                    'CompanyName': /^[a-zA-Z0-9\/_#;,@'\-\s]+$/
                };

                // Check if field is company name
                var isCompanyName = fieldName === 'CompanyName';

                // For mandatory fields (not company name)
                if (!value && !isCompanyName) {
                    $field
                        .addClass("error")
                        .next(".has-input-error")
                        .text("Please enter this field")
                        .removeClass("hide");
                    return;
                }

                // If field has a value (including optional company name), check regex
                if (value && patterns[fieldName]) {
                    if (!patterns[fieldName].test(value)) {
                        var errorMessage = "Invalid characters in " + fieldName.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
                        $field
                            .addClass("error")
                            .next(".has-input-error")
                            .text(errorMessage)
                            .removeClass("hide");
                        return;
                    }
                }

                // Clear error state
                $field
                    .removeClass("error")
                    .next(".has-input-error")
                    .addClass("hide");
            });
        },

        validateFields: function validateFields(fieldSelectors) {
            for (var i = 0; i < fieldSelectors.length; i++) {
                var selector = fieldSelectors[i];
                var $field = $(selector); // Select field by ID

                if (!$field.val().trim()) { // Check if value is empty
                    $field.addClass("error")
                        .next(".has-input-error")
                        .text("Please enter this field")
                        .removeClass("hide");

                } else {
                    $field.removeClass("error")
                        .next(".has-input-error")
                        .addClass("hide"); // Hide error message if valid
                }
            }
        },


        autoFavPropertyOnNDACompletion: function autoFavPropertyOnNDACompletion(propertyId) {
            // ajax post call with query params
            var url = contextPath + '/portal/watchProperty/' + propertyId + '?notifyType=DAILY&buyerInterestedInCom=Y';
            var self = this;
            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                success: function (response) {
                    if (response.status === 'SUCCESS') {
                        console.log("Property added to my upcoming auctions");
                    } else {
                        console.log("Error adding to my upcoming auctions");
                    }
                },
                error: function (xhr, status, error) {
                    console.log("Error adding to my upcoming auctions");
                }
            });

        },
        formatPhoneNumber: function formatPhoneNumber(phoneNumber) {
            // Convert to string and remove any non-digit characters
            const cleaned = String(phoneNumber).replace(/\D/g, '');

            // Check if the number has 10 digits
            if (cleaned.length === 10) {
                return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            }
            return phoneNumber;
        }
    });

    return DocumentsView;
});